import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import _ from "underscore";

import { useMediaQuery, useTheme, Avatar, Box, Card, Paper, Stack, Typography, TextField, Button, Container, Tooltip, Grid, CardMedia, CardContent, ImageList, ImageListItem, ImageListItemBar, ListSubheader, Fade, CardActionArea } from "@mui/material";
import Header from "../layout/Header";
import Modal from "../layout/Modal";

import { alpha } from "@mui/material/styles";
//Redux


import { imageHero } from "../services/constants/dummyData";


const alphaWh70 = alpha('#ffffff', .7);
const alphaWh40 = alpha('#ffffff', .3);

const alphaBackgroundColor80 = alpha('#121212', .8);


export default function PolicyPage() {
  const { is_verified } = useSelector((state) => state.user);
  const [loginState, setLoginState] = useState(false);
  const [settingsState, setSettingsState] = useState(false);
  const handleSettingsOpen = () => setSettingsState(true);
  const handleSettingsClose = () => setSettingsState(false);
  const handleLoginOpen =() => setLoginState(true);
  const handleLoginClose = () => setLoginState(false);
  return (
    <Box sx={{ backgroundImage: `url(${imageHero})`, backgroundSize: "cover", backgroundRepeat: "no-repeat", backgroundPosition: "center", width: "100%", height: "100vh", position: "fixed", overflowY: "scroll" }}>
      {is_verified ? <Header type="videos" handleOpen={handleSettingsOpen} /> : <Header type="landing" handleOpen={handleLoginOpen} />}
      {settingsState && <Modal type="settings" handleClose={handleSettingsClose} open={settingsState} />}
      {loginState && <Modal type="login" handleClose={handleLoginClose} open={loginState} />}
      <Container maxWidth="xl"  sx={{alignItems: 'flex-start', justifyContent:'center', display:'flex', mt:8, flexDirection: "column" }} >
        <Policies />
      </Container>
    </Box>
  );
}

const Policies = () => {
  const navigate = useNavigate();
  const theme = useTheme();
  const bp = useMediaQuery(theme.breakpoints.up("md"));
  return(
    <Stack spacing={2}>
      <ToS />
      <PrivacyPolicy />
      <RefundPolicy />
    </Stack>
    // <Grid container>
    //   <Grid item xs={12}>
    //     <Typography
    //         variant="h4"
    //         noWrap
    //         alignSelf='center'
    //         sx={{
    //           fontWeight: 700,
    //           letterSpacing: ".3rem",
    //           textDecoration: "none",
    //           textTransform: "uppercase",
    //           flexGrow: 1,
    //           fontSize: '4rem',
    //           mb: 2
    //         }}>
    //         The Film
    //       </Typography>
    //   </Grid>
    //   <Box sx={{ py: "25px", px: 2, background: 'linear-gradient(273deg, rgba(48,44,45,.6) 53%, rgba(150,153,161,0.52) 91%);', width: "100%"}}>
    //     <Grid item xs={12} md={12}>
    //       <MissionStatement />
    //     </Grid>
    //     <Grid item xs={12} md={12} sx={{alignSelf: 'center'}}>
    //       {/* <Box display="flex" flexDirection={bp ? "row" : "column"} justifyContent="space-evenly"> */}
    //       <Stack direction={bp ? "row" : "column"} justifyContent="space-evenly" spacing={4}>
    //         <Box display="flex" flexDirection="column" alignItems="center">
    //           <Card sx={{ width: '100%', height: '100%', maxWidth: '450px', maxHeight: '650px', alignSelf: 'center', mb: 2 }} >
    //             <CardMedia component="img" src={ITL_POSTER} sx={{ width: '100%', height: '100%', objectFit: "cover" }}/>
    //           </Card>
    //           <Button variant="contained" size="large" fullWidth sx={{ maxWidth: "450px" }} onClick={() => navigate('/purchase')}>Digital Purchase!</Button>
    //         </Box>
    //         <Box display="flex" flexDirection="column" alignItems="center">
    //           <Card sx={{ width: '100%', height: '100%', maxWidth: '450px', maxHeight: '650px', alignSelf: 'center', mb: 2 }} >
    //             <CardMedia component="img" src={dvd_cover} sx={{ width: '100%', height: '100%', objectFit: "cover" }}/>
    //           </Card>
    //           <Button variant="contained" size="large" fullWidth sx={{ maxWidth: "450px" }} href={SHOP_URL} target="_blank">Get a DVD!</Button>
    //         </Box>
    //       </Stack>
    //       {/* </Box> */}
    //     </Grid>
    //   </Box>
    // </Grid>
  )
}

const ToS = () => {
  return(
    <Paper sx={{p:2}}>
      <Stack spacing={2}>
          <Typography
            variant="h4"
            noWrap
            sx={{
              fontWeight: 700,
              letterSpacing: ".3rem",
              textDecoration: "none",
              textTransform: "uppercase",
              flexGrow: 1,
              fontSize: '4rem',
              mb: 1
            }}>
            Terms of Service
          </Typography>
          <Typography variant="p">Last updated 8/3/2023</Typography>
          <Typography variant="p">These Terms of Service constitute a legally binding agreement made between you, whether personally or on behalf of an entity ("you") and ITL Productions ("we," "us" or "our"), concerning your access to and use of the Site and App as well as any other media form, media channel, mobile website or mobile application related, linked, or otherwise connected thereto (collectively, the "Service").</Typography>
          <Typography variant="p">You agree that by accessing the Service, you have read, understood, and agree to be bound by all of these Terms of Service. If you do not agree with all of these Terms of Service, then you are expressly prohibited from using the Service and you must discontinue use immediately.</Typography>
          <Typography variant="p">Supplemental Terms of Service or documents that may be posted on the Site or the App from time to time are hereby expressly incorporated herein by reference. We reserve the right, in our sole discretion, to make changes or modifications to these Terms of Service at any time and for any reason.</Typography>
          <Typography variant="p">We will alert you about any changes by updating the "Last updated" date of these Terms of Service, and you waive any right to receive specific notice of each such change except herein or on the Site or App.</Typography>
          <Typography variant="p">It is your responsibility to periodically review these Terms of Service to stay informed of updates. You will be subject to and will be deemed to have been made aware of and to have accepted, the changes in any revised Terms of Service by your continued use of the Site and the App after the date such revised Terms of Service are posted.</Typography>
          <Typography variant="p">The information provided on the Site and the App is not intended for distribution to or use by any person or entity in any jurisdiction or country where such distribution or use would be contrary to law or regulation or which would subject us to any registration requirement within such jurisdiction or country.</Typography>
          <Typography variant="p">Accordingly, those persons who choose to access the Service from other locations do so on their own initiative and are solely responsible for compliance with local laws, if and to the extent local laws are applicable.</Typography>
          <Typography variant="p">The Service is intended for users who are at least 18 years of age. All users who are minors in the jurisdiction in which they reside (generally under the age of 18) must have the permission of, and be directly supervised by, their parent or guardian to use the Service. If you are a minor, you must have your parent or guardian read and agree to these Terms of Service prior to you using the Service.</Typography>
          <Typography variant="p">Unless otherwise indicated, the functionality, website designs, images, audio, video, text, photographs, and graphics on the Site and the App (collectively, the "Content") and the trademarks, service marks, and logos contained therein (the "Marks") are owned or controlled by us or licensed to us, and are protected by copyright and trademark laws and various other intellectual property rights and unfair competition laws of the United States, foreign jurisdictions, and international conventions.</Typography>
          <Typography variant="p">You may be required to register for the Service, by providing your name, and email address as well as to create a password to set up your Profile and your account. You agree to keep your password confidential, if applicable, and will be responsible for all use of your account and password.</Typography>
          <Typography variant="p">From time to time we may send you communications to the email address associated with your account or used to register for the Service. These communications may include, but are not limited to, information, new products and recommendations, special offers, and other account-related or transactional messages. These communications will also include consent request, opt-in and opt-out instructions.</Typography>
          <Typography variant="p">We will maintain certain data that you transmit to the Service for the purpose of managing the Service, as well as data relating to your use of the Service. Although we perform regular routine backups of data, you are solely responsible for all data that you transmit or that relates to any activity you have undertaken using the Service.</Typography>
          <Typography variant="p">You agree that we shall have no liability to you for any loss or corruption of any such data, and you hereby waive any right of action against us arising from any such loss or corruption of such data.</Typography>
          <Typography variant="p">We reserve the right to terminate any account if found to be engaged in harmfull behavior or behaviors that violate our Terms of Service</Typography>
          {/* <Typography variant="p"></Typography>
          <Typography variant="p"></Typography>
          <Typography variant="p"></Typography>
          <Typography variant="p"></Typography>
          <Typography variant="p"></Typography>
          <Typography variant="p"></Typography> */}
          <Typography variant="h3">Prohibited Activities</Typography>
          <Typography variant="p">You may not access or use the Service for any purpose other than that for which we make the Service available. As a user of the Service, you agree not to:</Typography>
          <Typography variant="p">systematically retrieve data or other content from the Service to create or compile, directly or indirectly, a collection, compilation, database, or directory without written permission from us.</Typography>
          <Typography variant="p">make any unauthorized use of the Service, including collecting usernames and/or email addresses of users by electronic or other means for the purpose of sending unsolicited email, or creating user accounts by automated means or under false pretenses.</Typography>
          <Typography variant="p">use a buying agent, purchasing agent or other agent to make purchases or to upload content on the Site or the App.</Typography>
          <Typography variant="p">infringe upon any copyright materials owned or controlled by others.</Typography>
          <Typography variant="p">circumvent, disable, or otherwise interfere with security-related features of the Service, including features that prevent or restrict the use or copying of any Content or enforce limitations on the use of the Service and/or the Content contained therein.</Typography>
          <Typography variant="p">engage in unauthorized framing of or linking to the Service</Typography>
          <Typography variant="p">trick, defraud, or mislead us and other users, especially in any attempt to learn sensitive account information such as user passwords.</Typography>
          <Typography variant="p">make improper use of our support services or submit false reports of abuse or misconduct.</Typography>
          <Typography variant="p">engage in any automated use of the system, such as using scripts to send comments or messages, or using any data mining, robots, or similar data gathering and extraction tools.</Typography>
          <Typography variant="p">interfere with, disrupt, or create an undue burden on the Service or the networks or other services connected to the Site or the App.</Typography>
          <Typography variant="p">attempt to impersonate another user or person or use the username of another user of the Service.</Typography>
          <Typography variant="p">sell or otherwise transfer your profile or account.</Typography>
          <Typography variant="p">use any information obtained from the Service in order to harass, abuse, or harm another person.</Typography>
          <Typography variant="p">use the Service as part of any effort to compete with us or otherwise use the Service and/or the Content for any revenue-generating endeavor or commercial enterprise.</Typography>
          <Typography variant="p">decipher, decompile, disassemble, or reverse engineer any of the software comprising or in any way making up a part of the Service.</Typography>
          <Typography variant="p">attempt to bypass any measures of the Service designed to prevent or restrict access to the Service, or any portion of the Service.</Typography>
          <Typography variant="p">harass, , intimidate, or threaten any of our employees or agents engaged in providing any portion of the Service to you.</Typography>
          <Typography variant="p">copy or adapt the Service's software, including but not limited to HTML, JavaScript, or other code.</Typography>
          <Typography variant="p">upload or transmit (or attempt to upload or to transmit) viruses, Trojan horses, harmfull inputs or other material, that interferes with any party's uninterrupted use and enjoyment of the Service or modifies, impairs, disrupts, alters, or interferes with the use, features, functions, operation, or maintenance of the Service.</Typography>
          <Typography variant="p">upload or transmit (or attempt to upload or to transmit) any material that acts as a passive or active information collection or transmission mechanism, including without limitation, clear graphics interchange formats ("gifs"), 1×1 pixels, web bugs, cookies, or other similar devices (sometimes referred to as "spyware" or "passive collection mechanisms" or "pcms").</Typography>
          <Typography variant="p">except as may be the result of standard search engine or Internet browser usage, use, launch, develop, or distribute any automated system, including without limitation, any spider, robot, cheat utility, scraper, or offline reader that accesses the Site, or using or launching any unauthorized script or other software.</Typography>
          <Typography variant="p">use the Service in a manner inconsistent with any applicable laws or regulations.</Typography>
          <Typography variant="p">attempts to sell, buy, or solicit other forms of payment in exchange for usernames on the Site or App.</Typography>
          <Typography variant="h3">Dispute Resolution</Typography>
          <Typography variant="p">ANY DISPUTE, CONTROVERSY OR CLAIM YOU HAVE ARISING OUT OF OR RELATED TO THESE TERMS OF SERVICE, A BREACH THEREOF OR YOUR USE OF THE WEBSITE OR THE APP SHALL BE EXCLUSIVELY SUBMITTED AND DECIDED BY BINDING ARBITRATION ADMINISTERED BY THE AMERICAN ARBITRATION ASSOCIATION ("AAA"). ANY LEGAL ACTION OF WHATEVER NATURE BROUGHT BY US AGAINST YOU MAY BE COMMENCED OR PROSECUTED IN THE STATE AND FEDERAL COURTS LOCATED IN BROWARD COUNTY, FLORIDA, AND YOU HEREBY CONSENT TO SAME, AND WAIVE ALL DEFENSES OF LACK OF PERSONAL JURISDICTION AND FORUM NON CONVENIENS WITH RESPECT TO VENUE AND JURISDICTION IN SUCH STATE AND FEDERAL COURTS.</Typography>
          <Typography variant="h3">Waiver of Class Action</Typography>
          <Typography variant="p">NOTWITHSTANDING ANYTHING CONTAINED HEREIN, YOU AGREE TO ABSOLUTELY AND UNCONDITIONALLY WAIVE ANY AND ALL RIGHTS TO PARTICIPATE IN OR TO BE INCLUDED IN ANY CLASS ACTION LAWSUITS OR INCLUSION IN ANY MULTI-PARTY ACTIONS OR SUITS AGAINST US, ANY OF OUR AFFILIATES, SPONSORS, SUBSIDIARIES, VENDORS, EMPLOYEES, AGENTS OR ANY OTHER PERSON OR ENTITY ASSOCIATED THEREWITH.</Typography>
          <Typography variant="p">THE SERVICE IS PROVIDED ON AN AS-IS AND AS-AVAILABLE BASIS. YOU AGREE THAT YOUR USE OF THE SERVICE AND ITS CONTENT WILL BE AT YOUR SOLE RISK. TO THE FULLEST EXTENT PERMITTED BY LAW, WE DISCLAIM ALL WARRANTIES, EXPRESS OR IMPLIED, IN CONNECTION WITH THE SERVICE AND YOUR USE THEREOF, INCLUDING, WITHOUT LIMITATION, THE IMPLIED WARRANTIES OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, AND NON-INFRINGEMENT. WE MAKE NO WARRANTIES OR REPRESENTATIONS ABOUT THE ACCURACY OR COMPLETENESS OF THE SERVICE'S CONTENT OR THE CONTENT OF ANY WEBSITES LINKED TO THE SERVICE AND WE WILL ASSUME NO LIABILITY OR RESPONSIBILITY FOR ANY (1) ERRORS, MISTAKES, OR INACCURACIES OF CONTENT AND MATERIALS, (2) PERSONAL INJURY OR PROPERTY DAMAGE, OF ANY NATURE WHATSOEVER, RESULTING FROM YOUR ACCESS TO AND USE OF THE SERVICE, (3) ANY UNAUTHORIZED ACCESS TO OR USE OF OUR SECURE SERVERS AND/OR ANY AND ALL PERSONAL INFORMATION AND/OR FINANCIAL INFORMATION STORED THEREIN, (4) ANY INTERRUPTION OR CESSATION OF TRANSMISSION TO OR FROM THE SERVICE, (5) ANY BUGS, VIRUSES, TROJAN HORSES, OR THE LIKE WHICH MAY BE TRANSMITTED TO OR THROUGH THE SERVICE BY ANY THIRD PARTY, AND/OR (6) ANY ERRORS OR OMISSIONS IN ANY CONTENT AND MATERIALS OR FOR ANY LOSS OR DAMAGE OF ANY KIND INCURRED AS A RESULT OF THE USE OF ANY CONTENT POSTED, TRANSMITTED, OR OTHERWISE MADE AVAILABLE VIA THE SERVICE. WE DO NOT WARRANT, ENDORSE, GUARANTEE, OR ASSUME RESPONSIBILITY FOR ANY PRODUCT OR SERVICE ADVERTISED OR OFFERED BY A THIRD PARTY THROUGH THE SERVICE, ANY HYPERLINKED WEBSITE, OR ANY WEBSITE OR MOBILE APPLICATION FEATURED IN ANY BANNER OR OTHER ADVERTISING, AND WE WILL NOT BE A PARTY TO OR IN ANY WAY BE RESPONSIBLE FOR MONITORING ANY TRANSACTION BETWEEN YOU AND ANY THIRD-PARTY PROVIDERS OF PRODUCTS OR SERVICES.</Typography>
          <Typography variant="h3">Contact us</Typography>
          <Typography variant="p">In order to resolve a complaint regarding the Service or to receive further information regarding use of the Service, please contact us at info.itlproductions@proton.me.</Typography>
          <Typography variant="p">ITL Productions, LLC</Typography>
          <Typography variant="p">6339 Charlotte Pike #932</Typography>
          <Typography variant="p">Nashville, TN 37209</Typography>
          {/* <Typography variant="p">(615)-437-7774</Typography> */}
      </Stack>
    </Paper>
  )
}
const RefundPolicy = () => {
  return(
    <Paper sx={{p:2}}>
      <Stack spacing={2}>
          <Typography
            variant="h4"
            noWrap
            sx={{
              fontWeight: 700,
              letterSpacing: ".3rem",
              textDecoration: "none",
              textTransform: "uppercase",
              flexGrow: 1,
              fontSize: '4rem',
              mb: 1
            }}>
            Refund Policy
          </Typography>
          <Typography variant="p">Accounts making video content purchases are eligible for a refund for up to 7-days if they have not played the content.</Typography>
          <Typography variant="p">Accounts making video rental purchases are eligible for a refund for up to 3-days if they have not played the content.</Typography>
          <Typography variant="p">Accounts making gift code purchases are eligible for a refund for up to 7-days if the gift code has not been redeemed.</Typography>
          <Typography variant="p">Pay it forward transactions not refundable.</Typography>
          <Box><Typography variant="p">All refund requests can be emailed to <Typography variant="body1">info.itlproductions@proton.me</Typography> with your Order Number and email address tied to the account making that purchase. Order number can be found on your purchase receipt or your order history when logged in</Typography></Box>
      </Stack>
    </Paper>
  )
}
// Last updated 12/2/2022

// TRUTH SOCIAL operates a website, a mobile application and related platform features available through its website (“Website”) or its mobile application (“App”). We may refer to Truth Social as “we,” “us,” or “our.” We may refer to you as “you” or “your.” The purpose of this policy is to inform you about the personal information we collect, how we use and share that information, and the ways in which you can control how we use and share that information. By using our Website or our App, you agree to the terms of this policy and you expressly consent to the collection, use, and disclosure of your personal information in accordance with this policy.
const PrivacyPolicy = () => {
  return(
    <Paper sx={{p:2}}>
      <Stack spacing={2}>
          <Typography
            variant="h4"
            noWrap
            sx={{
              fontWeight: 700,
              letterSpacing: ".3rem",
              textDecoration: "none",
              textTransform: "uppercase",
              flexGrow: 1,
              fontSize: '4rem',
              mb: 1
            }}>
            Privacy Policy
          </Typography>
          <Typography variant="p">Last updated 8/3/2023</Typography>
          <Typography variant="p">ITL Productions operates a website, a mobile application and related platform features available through its website (“Website”) or its mobile application (“App”). We may refer to ITL Productions as “we,” “us,” or “our.” We may refer to you as “you” or “your.” The purpose of this policy is to inform you about the personal information we collect, how we use and share that information, and the ways in which you can control how we use and share that information. By using our Website or our App, you agree to the terms of this policy and you expressly consent to the collection, use, and disclosure of your personal information in accordance with this policy.</Typography>
          <Typography variant="p">We collect and maintain Account Information. When you register or sign up on the Website or App for certain features, newsletters and/or special offers, you may provide us, and we may collect, your User Registration Data.</Typography>
          <Typography variant="p">When you access and use the Website or App from a computer, mobile phone, tablet, or other Device, we may user information about those Devices, including your browser type and Internet Protocol (IP) address. If you access the Website from a mobile phone, tablet, automobile, or other Device, we may collect and store information such as Device type, operating system version and type, wireless carrier, performance metrics of our Website or App, and other related information.</Typography>
          <Typography variant="p">We may collect data on the pages of our Website or App you visit, the time and date of your visit, and time, frequency, and duration spent on those pages, as well as the particular features you use.</Typography>
          <Typography variant="p">We may collect certain information through the use of log files and servers. These servers create log files as part of the process of setup and configuration. Information in a log file may include IP address (and associated geographic information), browser type, Internet service provider, date/time stamps, and other usage or statistical information.</Typography>
          <Typography variant="p">Our Privacy Policy will be periodically reviewed and evaluated in connection with new products, new services, new technologies, changes in law, and your needs. We may make changes or modifications to our Privacy Policy as a result thereof. Our Privacy Policy will be updated from time to time. If we make any material changes to this Privacy Policy, we will post the updated terms of the Privacy Policy on the Website and the App. Notice of such changes may include notice by email through a message sent to the email address you use to access the Website and App or to register as a user on the Website and App or posting a message on the Website or App.</Typography>
          <Typography variant="p">We do not share your data with any 3rd party. When making a purchase, your payment information will be provided to our processing vendor. We only store the transaction number of the purchase.</Typography>
          <Typography variant="p">If you have any questions about this Privacy Policy, our privacy practices or how we collect or process your personal data, please contact us - Attn: User Support at info.itlproductions@proton.me</Typography>
          {/* <Typography variant="p"></Typography>
          <Typography variant="p"></Typography>
          <Typography variant="p"></Typography> */}
      </Stack>
    </Paper>
  )
}
