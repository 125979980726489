export const imageLogo = require("../../assets/images/logo.png");
export const imageBackgroundVideo = require("../../assets/images/background.jpg");
export const imageNewsletter = require("../../assets/images/silhouette.jpg");
export const imageHero = require("../../assets/images/intothelight.jpg");
export const imagePoster = require("../../assets/images/oosposter.jpg");
export const j_poster = require("../../assets/images/j_water.webp");
// export const videoTrailer = require("../../assets/videos/trailer.mp4");

export const itemData = [
  {
    id: 1,
    title: "Into The Light",
    vid_length: "1:17:25",
    thumbnail_url: imageHero,
    imgSm: imageHero,
    vid_type: "film",
    is_free: true,
    desc: "An exposé on how Hollywood and the mainstream media manipulate the multitudes by spreading propaganda throughout their content.",
  },
  {
    id: 2,
    vid_length: "1:17:25",
    thumbnail_url: "https://source.unsplash.com/random/?Space&/?Space&1",
    imgSm: "https://source.unsplash.com/random/?Space&/?Space&1/300×300",
    vid_type: "film",
    is_free: true,
    title: "Point of Steel",
    desc: "Eu nisi voluptate est nostrud in nisi veniam minim ex proident. Ad sit aliqua consectetur nostrud ea pariatur pariatur elit aute. Sint mollit anim anim quis est mollit do dolor exercitation adipisicing veniam. Id in adipisicing veniam adipisicing officia voluptate.",
  },
  {
    id: 3,
    vid_length: "1:17:25",
    thumbnail_url: "https://source.unsplash.com/random/?Space&2",
    imgSm: "https://source.unsplash.com/random/?Space&2/300×300",
    vid_type: "documentary",
    is_free: false,
    title: "Three's a Crowd",
    desc: "Dolor aute commodo elit enim ex fugiat. Cupidatat duis exercitation elit laboris voluptate officia cillum veniam. Occaecat occaecat et tempor labore laborum esse id pariatur laborum ut adipisicing tempor ipsum. Est nulla ipsum aute pariatur fugiat reprehenderit aliqua excepteur Lorem cillum est. Duis do ea ad velit proident.",
  },
  {
    id: 4,
    vid_length: "1:17:25",
    thumbnail_url: "https://source.unsplash.com/random/?Space&3",
    imgSm: "https://source.unsplash.com/random/?Space&3/300×300",
    vid_type: "film",
    is_free: false,
    title: "The Ghost in the Painting",
    desc: "Duis aliquip minim ullamco eiusmod et nisi sint sit ad laborum. Amet culpa laboris id quis voluptate exercitation esse anim aliqua irure. Eu non officia deserunt in commodo ullamco non sit mollit consequat esse exercitation tempor. Tempor aliqua id aute officia dolor. Non minim eiusmod nostrud exercitation laboris exercitation deserunt adipisicing sit id sunt Lorem ut culpa. Voluptate aliquip officia dolor commodo deserunt nisi reprehenderit elit occaecat est id Lorem.",
  },
  {
    id: 5,
    vid_length: "1:17:25",
    thumbnail_url: "https://source.unsplash.com/random/?Space&4",
    imgSm: "https://source.unsplash.com/random/?Space&4/300×300",
    vid_type: "documentary",
    is_free: true,
    title: "The Woman in the Dark City",
    desc: "Sint labore Lorem mollit aliqua laborum amet veniam nisi aliquip sunt incididunt. Ut adipisicing eiusmod eiusmod aliqua fugiat irure. Et eiusmod ea reprehenderit dolore. Sit voluptate aliqua reprehenderit excepteur nisi occaecat exercitation cupidatat ea tempor.",
  },
  {
    id: 6,
    vid_length: "1:17:25",
    thumbnail_url: "https://source.unsplash.com/random/?Space&5",
    imgSm: "https://source.unsplash.com/random/?Space&5/300×300",
    vid_type: "film",
    is_free: false,
    title: "Trapped in Silk",
    desc: "Aliquip quis ad et minim voluptate adipisicing nostrud Lorem pariatur quis anim ad Lorem ad. Enim ipsum quis ad sit sit commodo consectetur. Et cillum quis dolor do do dolor consectetur proident. Ipsum labore reprehenderit velit tempor mollit sint ea Lorem pariatur ipsum. Voluptate irure ut magna cillum sunt. Proident laboris culpa deserunt duis cillum. Est cillum aute non aute minim ea.",
  },
  {
    id: 7,
    vid_length: "1:17:25",
    thumbnail_url: "https://source.unsplash.com/random/?Space&6",
    imgSm: "https://source.unsplash.com/random/?Space&6/300×300",
    vid_type: "film",
    is_free: true,
    title: "The Children of the Titan",
    desc: "Amet velit velit est cupidatat fugiat eu reprehenderit non amet ea aliquip eiusmod ea commodo. Labore voluptate sit deserunt minim in enim nostrud. Dolor proident ad reprehenderit id.",
  },
  {
    id: 8,
    vid_length: "1:17:25",
    thumbnail_url: "https://source.unsplash.com/random/?Space&7",
    imgSm: "https://source.unsplash.com/random/?Space&7/300×300",
    vid_type: "film",
    is_free: false,
    title: "Mister Nice",
    desc: "Culpa velit sit incididunt aliqua dolore minim ad. Aliquip quis deserunt commodo esse eu aliquip amet irure adipisicing. Duis dolore ex est ad laborum elit exercitation exercitation. Do ex consectetur incididunt consequat minim aute eu commodo voluptate ut est labore exercitation. Aute est ad anim commodo dolore sint commodo aliqua deserunt cupidatat. Lorem magna do enim sit in fugiat veniam cupidatat mollit labore minim do. Laboris ad occaecat nulla esse nostrud.",
  },
  {
    id: 9,
    vid_length: "1:17:25",
    thumbnail_url: "https://source.unsplash.com/random/?Space&8",
    imgSm: "https://source.unsplash.com/random/?Space&8/300×300",
    vid_type: "documentary",
    is_free: true,
    title: "Leather in Heaven",
    desc: "Occaecat dolor tempor elit fugiat veniam eu ut. Dolor velit ullamco labore fugiat non sunt occaecat non mollit aliqua veniam tempor magna minim. Dolor amet mollit ea enim incididunt dolor quis cillum mollit.",
  },
];

export const shopCards = [
  {
    img: "https://images.unsplash.com/photo-1522770179533-24471fcdba45",
    title: "Camera",
    author: "$125.00",
  },
  {
    img: "https://images.unsplash.com/photo-1533827432537-70133748f5c8",
    title: "Hats",
    author: "$12.00",
  },
  {
    img: "https://images.unsplash.com/photo-1558642452-9d2a7deb7f62",
    title: "Honey",
    author: "$7.00",
  },
  {
    img: "https://images.unsplash.com/photo-1597262975002-c5c3b14bbd62",
    title: "Candle",
    author: "$8.99",
  },
];

export const imageCards_1 = [
  {
    img: "https://images.unsplash.com/photo-1551963831-b3b1ca40c98e",
    title: "Breakfast",
    author: "@bkristastucchio",
    rows: 2,
    cols: 2,
    featured: true,
  },
  {
    img: "https://images.unsplash.com/photo-1551782450-a2132b4ba21d",
    title: "Burger",
    author: "@rollelflex_graphy726",
  },
  {
    img: "https://images.unsplash.com/photo-1522770179533-24471fcdba45",
    title: "Camera",
    author: "@helloimnik",
  },
  {
    img: "https://images.unsplash.com/photo-1444418776041-9c7e33cc5a9c",
    title: "Coffee",
    author: "@nolanissac",
    cols: 2,
  },
  {
    img: "https://images.unsplash.com/photo-1533827432537-70133748f5c8",
    title: "Hats",
    author: "@hjrc33",
    cols: 2,
  },
  {
    img: "https://images.unsplash.com/photo-1558642452-9d2a7deb7f62",
    title: "Honey",
    author: "@arwinneil",
    rows: 2,
    cols: 2,
    featured: true,
  },
  {
    img: "https://images.unsplash.com/photo-1516802273409-68526ee1bdd6",
    title: "Basketball",
    author: "@tjdragotta",
  },
  {
    img: "https://images.unsplash.com/photo-1518756131217-31eb79b20e8f",
    title: "Fern",
    author: "@katie_wasserman",
  },
  {
    img: "https://images.unsplash.com/photo-1597645587822-e99fa5d45d25",
    title: "Mushrooms",
    author: "@silverdalex",
    rows: 2,
    cols: 2,
  },
  {
    img: "https://images.unsplash.com/photo-1567306301408-9b74779a11af",
    title: "Tomato basil",
    author: "@shelleypauls",
  },
  {
    img: "https://images.unsplash.com/photo-1471357674240-e1a485acb3e1",
    title: "Sea star",
    author: "@peterlaster",
  },
  {
    img: "https://images.unsplash.com/photo-1589118949245-7d38baf380d6",
    title: "Bike",
    author: "@southside_customs",
    cols: 2,
  },
];
