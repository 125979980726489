import React from "react";
import { useNavigate } from "react-router-dom";

// Components
import { AppBar, Badge, Box, Button, Container, IconButton, Stack, Toolbar, Tooltip, Typography, useMediaQuery, useTheme } from "@mui/material";

// Icons
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import LogoutIcon from "@mui/icons-material/Logout";
import NavigateBeforeIcon from '@mui/icons-material/NavigateBefore';
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';

// Layouts
import Sidebar from "../Sidebar";
import UserSpeedDial, { SettingsMenu } from "./Settings/UserSettings";

// React Hooks
import { useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { NavLink } from "react-router-dom";

// Services
import { getUser, logOut } from "../../redux/slices/user-slice";

// Plugins
import gsap from "gsap";
import ScrollTrigger from "gsap/ScrollTrigger";
gsap.registerPlugin(ScrollTrigger);

// Single Video Page Header(include breadcrumbs)
export default function VideoHeader({ video, handleSettingsOpen }) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  
  const { first_name } = useSelector(getUser);
  const { last_name } = useSelector(getUser);
  const user_cart = useSelector((state) => state.shop.user_cart);
  let cart_count = 0
  user_cart.forEach(item => cart_count += parseFloat(item.quantity));
  cart_count = cart_count > 9 ? '9+' : cart_count;

  const theme = useTheme();
  const bp = useMediaQuery(theme.breakpoints.down("md"));

  useEffect(() => {
    ScrollTrigger.create({
      trigger: "#root",
      start: "top top",
      end: "99999",
      toggleClass: { className: "scrolled", targets: "header" },
    });
  });

  return (
    <>
      <AppBar
        component="header"
        className="header stage-header"
        position="fixed"
        sx={{
          width: "100%",
          background: "transparent",
          boxShadow: "none",
        }}>
        <Container
          maxWidth="false"
          sx={{ background: "linear-gradient(90deg, #121212 0%, #121212 40%, rgba(12, 12, 12, 0) 60%, rgba(12, 12, 12, 0) 75%, rgba(12, 12, 12, 1) 85%, rgba(12, 12, 12, 1) 100%)" }}
          disableGutters>
          <Toolbar disableGutters sx={{ gap: "15px" }}>
            {/* <img src={logo} width="30" alt="Crux Logo" /> */}
            {bp ? <Sidebar /> : null}

            {!bp && (
              <React.Fragment>
                <Breadcrumbs video={video} />

                <Box sx={{ display: "flex", gap: "10px" }} alignItems="center">
                  <Typography variant="h6" sx={{ fontFamily: "Lora", fontSize: ".85rem", textTransform: "none" }}>
                    Greetings, {first_name}!
                  </Typography>
                  {/* {user_cart.length > 0 ? <Tooltip  title="Shopping Cart" placement="bottom" >
                    <IconButton className="cart" variant="text" onClick={() => navigate("/checkout")} sx={{ fontFamily: "Lora", letterSpacing: "2px", cursor: "pointer" }}>
                      <Badge overlap="circular" anchorOrigin={{ vertical: 'top', horizontal: 'right' }} badgeContent={<Typography fontSize='.75rem'>{cart_count}</Typography>} color='error'>
                        <ShoppingCartIcon />
                      </Badge>
                    </IconButton>
                  </Tooltip> : null} */}
                  <Tooltip title="User Menu" placement="bottom">
                    <SettingsMenu resetPw={handleSettingsOpen}/>
                  </Tooltip>
                  <Tooltip title="Logout" placement="bottom">
                    <IconButton aria-label="delete" onClick={() => dispatch(logOut())}>
                      <LogoutIcon color="inherit" />
                    </IconButton>
                  </Tooltip>
                </Box>
              </React.Fragment>
            )}
            {bp && (
              <React.Fragment>
                <UserSpeedDial first={first_name} last={last_name} logOut={() =>dispatch(logOut())} openSettings={handleSettingsOpen}/>
              </React.Fragment>
            )}
          </Toolbar>
        </Container>
      </AppBar>
    </>
  );
}

export function Breadcrumbs({ video }) {
  return (
    <Box sx={{ display: "flex", flexGrow: "1" }} className="primary-menu">
      <Box display="flex" alignItems="center">
        <Box alignItems="center" sx={{ display: { xs: 'none', sm: 'none', md: 'flex', lg: 'flex', xl: 'flex' } }}>
          <Button component={NavLink} variant="h3" to="/" sx={{ fontSize: "1rem" }}>
            <NavigateBeforeIcon />
            Films
          </Button>
          <KeyboardArrowRightIcon color="primary" />
        </Box>
        {video ? <Stack direction="row">
          <Typography variant="h6" color="primary" ml={2} sx={{ fontSize: "1rem", fontWeight: "700" }}>
            {video.title}
          </Typography>
          <Typography variant="h6" ml={2} sx={{ fontSize: "1rem" }}>
            {video.vid_year ? video.vid_year : null}
          </Typography>
          <Typography variant="h6" color="secondary" ml={2} sx={{ fontSize: "1rem" }}>
            {video.vid_length}
          </Typography>
        </Stack> : null}
      </Box>
    </Box>
  );
}
