import React, { useState, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";

import { styled } from "@mui/material/styles";

import { Container, IconButton, InputAdornment, TextField, Stack, Typography, Fade, Box, Backdrop } from "@mui/material";

import SpeedDial from '@mui/material/SpeedDial';
import SpeedDialIcon from '@mui/material/SpeedDialIcon';
import SpeedDialAction from '@mui/material/SpeedDialAction';

import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';

import LocalOfferOutlinedIcon from "@mui/icons-material/LocalOfferOutlined";
import MovieFilterOutlinedIcon from "@mui/icons-material/MovieFilterOutlined";
import MenuIcon from '@mui/icons-material/Menu';

import { min8Characters, hasUpperCase, hasLowerCase, hasNumber, hasSpecialCharacter } from "../services/utilities";

export const CustomContainer = styled((props) => <Container {...props} />)(({ theme }) => ({
  "&.MuiContainer-maxWidthXl": {
    maxWidth: "2000px",
  },
}));

export const TextFieldStyled = styled((props) => <TextField InputProps={{ sx: { fontSize: "1rem" } }} InputLabelProps={{ sx: { fontSize: "1rem" } }} {...props} />)(({ theme }) => ({
  "& label": {
    color: "white",
  },
  "& label.Mui-focused": {
    color: "white",
  },
  "& .MuiInput-underline:after": {
    borderBottomColor: "none",
  },
  "& .MuiOutlinedInput-root": {
    color: "white",
    "& fieldset": {
      borderColor: "white",
    },
    "&:hover fieldset": {
      borderColor: "white",
    },
    "&.Mui-focused fieldset": {
      borderColor: "white",
      borderWidth: "1px",
    },
  },
}));

export const PasswordField = (props) => {
  const [showPassword, setShowPassword] = useState(false);
  return (
    <TextField
      type={showPassword ? "text" : "password"}
      fullWidth
      sx={{ mb: "1rem" }}
      InputProps={{
        endAdornment: (
          <InputAdornment position="end">
            <IconButton aria-label="toggle password visibility" edge="end" onClick={() => setShowPassword(!showPassword)}>
              {showPassword ? <VisibilityOff /> : <Visibility />}
            </IconButton>
          </InputAdornment>
        ),
      }}
      {...props}
    />
  );
};

export const ValidationIcon = ({isValid}) => (
  isValid ? <CheckCircleOutlineIcon sx={{color: "lightgreen"}} fontSize="small" /> :  <ErrorOutlineIcon color="error" fontSize="small" />
);

export const FeedBack = ({passed, message, ...props}) => {
  return (
    <Fade in><Box>
      <Stack spacing={.5} direction="row" alignItems="center" {...props}>
        <ValidationIcon isValid={passed} /><Typography variant="subtitle2">{message}</Typography>
      </Stack>
    </Box></Fade>
  );
}

export const PasswordRequirements = ({value}) => {
  return(
    <Stack spacing={.5} sx={{my: 1, color: "white", fontFamily: "Roboto, sans-serif"}}>
      <Stack spacing={.5} direction="row" alignItems="center"><ValidationIcon isValid={min8Characters(value)} /><Typography variant="subtitle2">Atleast 8 characters</Typography></Stack>
      <Stack spacing={.5} direction="row" alignItems="center"><ValidationIcon isValid={hasLowerCase(value)} /><Typography variant="subtitle2">Contains lower case</Typography></Stack>
      <Stack spacing={.5} direction="row" alignItems="center"><ValidationIcon isValid={hasUpperCase(value)} /><Typography variant="subtitle2">Contains upper case</Typography></Stack>
      <Stack spacing={.5} direction="row" alignItems="center"><ValidationIcon isValid={hasNumber(value)} /><Typography variant="subtitle2">Contains a digit</Typography></Stack>
      {/* <Stack spacing={.5} direction="row" alignItems="center"><ValidationIcon isValid={hasSpecialCharacter(value)} /><Typography variant="subtitle2">Contains a @$!%*?&#^)( </Typography></Stack> */}
    </Stack>
  );
}

export const GoToTop = () => {
  const routePath = useLocation();
  const onTop = () => {
    window.scrollTo(0, 0);
  }
  useEffect(() => {
    onTop()
  }, [routePath]);
  
  return null;
}

export const SpeedDialNav = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);


  const actions = [
    { icon: <IconButton aria-label="delete" size="large" color={location.pathname === "/" ? "primary" : "white"} onClick={() => navigate("/")}><MovieFilterOutlinedIcon fontSize="large" color="inherit" /></IconButton>, name: 'Films' },
    { icon: <IconButton aria-label="delete" size="large" color={location.pathname === "/shop" ? "primary" : "white"} onClick={() => navigate("/shop")}><LocalOfferOutlinedIcon fontSize="large" color="inherit" /></IconButton>, name: 'Shop' },
  ];

  return (
    <>
      <Backdrop open={open} onClick={handleClose} />
      <SpeedDial
        ariaLabel="SpeedDial playground example"
        sx={{ position: 'absolute', top: 10, left: 10, '.MuiSpeedDial-fab' : {width: '40px', height: '40px'}}}
        icon={<SpeedDialIcon sx={{ bgcolor: "white" }} />}
        direction="down"
        onClose={handleClose}
        onOpen={handleOpen}
        open={open}
      >
        {actions.map((action) => (
          <SpeedDialAction
            key={action.name}
            icon={action.icon}
            tooltipTitle={action.name}
            onClick={handleClose}
          />
        ))}
      </SpeedDial>
    </>
  );
}
