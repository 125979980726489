import * as React from "react";

// Components
import { Box, Slide } from '@mui/material';

// Icons

// Header Layouts
import LandingHeader from "./Header/Landing";
import VideoHeader from "./Header/Video";
import VideosHeader from "./Header/Videos";

// React Hooks
import useScrollTrigger from '@mui/material/useScrollTrigger';

// Services

// Plugins
import gsap from "gsap";
import ScrollTrigger from "gsap/ScrollTrigger";
gsap.registerPlugin(ScrollTrigger);

function HideOnScroll(props) {
  const { children } = props;
  const trigger = useScrollTrigger();

  return (
    <Slide appear={false} direction="down" in={!trigger}>
      <Box>
        {children}
      </Box>
    </Slide>
  );
}

// Default function to check which modal was called
export default function HeaderCheck({ type, video, handleOpen }) {
  return (
    <React.Fragment>
      {(() => {
        switch (type) {
          case "videos":
            return <HideOnScroll><VideosHeader handleSettingsOpen={handleOpen} /></HideOnScroll>;
          case "video":
            return <HideOnScroll><VideoHeader video={video} handleSettingsOpen={handleOpen}/></HideOnScroll>;
          case "landing":
            return <HideOnScroll><LandingHeader handleLoginOpen={handleOpen} /></HideOnScroll>;
            // return <LandingHeader handleLoginOpen={handleOpen} />;
          default:
            return null;
        }
      })()}
    </React.Fragment>
  );
}
