import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import _ from "underscore";

import { useMediaQuery, useTheme, Avatar, Box, Card, Paper, Stack, Typography, TextField, Button, Container, Tooltip, Grid, CardMedia, CardContent, ImageList, ImageListItem, ImageListItemBar, ListSubheader, Fade, CardActionArea } from "@mui/material";
import Header from "../layout/Header";
import Modal from "../layout/Modal";
import { PasswordField, PasswordRequirements, FeedBack } from "../components/CustomStyledComponents";

import { alpha, createTheme, styled, ThemeProvider } from "@mui/material/styles";
//Redux
import { forgotPasswordReset } from "../redux/slices/user-slice";

import { isEmail, checkPassword } from "../services/utilities";

import { imageHero } from "../services/constants/dummyData";
import { cast_members } from "./Bio";
import { SHOP_URL } from "../services/constants/keys";

import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css";

const alphaWh70 = alpha('#ffffff', .7);
const alphaWh40 = alpha('#ffffff', .3);

const alphaBackgroundColor80 = alpha('#121212', .8);


const mike_hs = "https://itl-assets.b-cdn.net/thumbnails/ms_bio.jpg";
// const boone_hs = "https://itl-assets.b-cdn.net/thumbnails/boone_bio.jpeg";
const boone_hs = "https://itl-assets.b-cdn.net/thumbnails/boon_hs_2.jpeg";
const flynn_hs = "https://itl-assets.b-cdn.net/thumbnails/flynn_bio.jpg";
const rose_hs = "https://itl-assets.b-cdn.net/thumbnails/k_rose.jpg";
const lara_hs = "https://itl-assets.b-cdn.net/thumbnails/lara_bio.jpeg";
const bg_hs = "https://itl-assets.b-cdn.net/thumbnails/bg_bio.jpeg";
const liz_hs = "https://itl-assets.b-cdn.net/thumbnails/liz_crokin.jpeg";
const dvd_cover = "https://itl-assets.b-cdn.net/store_items/itf_dvd_doublefeature.webp";

const ITL_LOOP = "https://itl-assets.b-cdn.net/thumbnails/ITL_loop.webp";
const ITL_POSTER = "https://itl-assets.b-cdn.net/thumbnails/itl_poster_grey.jpg";
// const bg_bio = "";



export default function About() {
  const { is_verified } = useSelector((state) => state.user);
  const [loginState, setLoginState] = useState(false);
  const [settingsState, setSettingsState] = useState(false);
  const handleSettingsOpen = () => setSettingsState(true);
  const handleSettingsClose = () => setSettingsState(false);
  const handleLoginOpen =() => setLoginState(true);
  const handleLoginClose = () => setLoginState(false);
  return (
    <Box sx={{ backgroundImage: `url(${imageHero})`, backgroundSize: "cover", backgroundRepeat: "no-repeat", backgroundPosition: "center", width: "100%", height: "100vh", position: "fixed", overflowY: "scroll" }}>
      {is_verified ? <Header type="videos" handleOpen={handleSettingsOpen} /> : <Header type="landing" handleOpen={handleLoginOpen} />}
      {settingsState && <Modal type="settings" handleClose={handleSettingsClose} open={settingsState} />}
      {loginState && <Modal type="login" handleClose={handleLoginClose} open={loginState} />}
      <Container maxWidth="xl"  sx={{alignItems: 'flex-start', justifyContent:'center', display:'flex', mt:8, flexDirection: "column" }} >
        {/* <Paper> */}
          <AboutUs />
          <MasonryImageList />
          {/* <Typography variant="h2" mb={4} >Featuring</Typography>
          <TeamBios /> */}
        {/* </Paper> */}
      </Container>
    </Box>
  );
}


const Endorsements = () => {
  return(
    <Box>
      <Grid container> 
        <Grid item xs={12} sx={{ p: '0 !important' }}>
          <Carousel autoPlay infiniteLoop interval={3000} showThumbs={false} showStatus={false} animationHandler="fade" showIndicators={true} showArrows={false}>
            {biographies.map(item => {
              // sx={{ width: "100%", height: "100%" }}
              return (
                <Box>
                  <CardMedia component="img" image={item.img} sx={{ maxHeight: "auto", maxWidth: "600px", minWidth: "600px" }} />
                </Box>
              )
            })}
          </Carousel>
        </Grid>
      </Grid>
    </Box>
  );
}

const biographies = [
  { img: mike_hs, bio: ['Mike Smith is the creator, producer and director of the viral film sensation Out of Shadows. That documentary – viewed by well over 100 million people worldwide – shed light on media manipulation and child trafficking. Since the 2020 release of Out of Shadows, Mike has become a Champion in the fight against media censorship and exposing the dark side of Hollywood.'] },
  { img: flynn_hs, bio: ['Lt. General Michael T. Flynn, USA (Ret.) dedicated his life to service to the nation with the U.S. Army for more than 33 years. His distinguished military career culminated as the Director of the Defense Intelligence Agency and as the nation’s highest-serving military intelligence officer.  Upon his retirement from the Army in 2014, he went on to serve in leadership positions in a variety of business, educational, and nonprofit organizations. He currently serves as America’s Future, Inc. Chair of the Board. '] },
  { img: bg_hs, bio: ['Brian Gamble is an accomplished data sciences professional and tech visionary with over three decades of experience in the industry. Throughout his distinguished career, Brian has left indelible marks across diverse sectors, holding pivotal positions including the role of Chief Information Officer for a prominent Fortune 500 enterprise.'] },
  { img: boone_hs, bio: [`Boone Cutler's journey from a combat veteran serving in Iraq to an influential advocate for the Warfighter community and fellow Americans is deeply intertwined with his literary works. In the heart of Sadr City Boone served as a Psychological Operations team sergeant. This frontline experience became the cornerstone for his first book, "CallSign Voodoo". Written in the throes of battle and completed during his recovery at Walter Reed Army Medical Center amid the 2007 neglect scandal, the book offers a raw, firsthand perspective of war and recovery.`] },
  { img: rose_hs, bio: [`Dr. Keith Rose maintains an active plastic surgery practice in Corpus Christi, Texas, The Rose Cosmetic Surgery Center.   He is also the CEO of Rose Medical Management LLC which provide medical management services, case management, and onsite clinics to over 100 companies across the United States and is CEO of the Doctors Center Urgent Care.   The Doctors Center Urgent Care (TDCUC) has 4 clinics in South Texas and has been in business for 23 years.`] },
  { img: lara_hs, bio: [`Lara Logan's candid  reporting, often from the most dangerous places in the world, has earned her a prominent place among the world's best foreign correspondents. In her role at 60 Minutes, Logan helped us understand the political and human conflicts around the world, including Pakistan, Iraq, Afghanistan, Israel and Palestine, and Egypt.`] },
];

// <ImageListItem>
    //   <img
    //     src={img}
    //     loading="lazy"
    //   />
    //   <ImageListItemBar
    //     title={bio}
    //   >
    //     {bio}
    //   </ImageListItemBar>
    // </ImageListItem>

    // <Box>
    //   <Card>
    //     <CardMedia component="img" image={img} height="600px" / >
    //     <CardContent>{bio}</CardContent>
    //   </Card>
    // </Box>

// export const CustomContainer = styled((props) => <Container {...props} />)(({ theme }) => ({

const MissionStatement = () => {
  return(
    <Stack spacing={2} my={2}>
      {/* <Box mb={2} display='flex' flexDirection='row' alignItems='center'><Typography variant="h3">Our Mission</Typography></Box> */}
      <Typography paragraph variant="body1" maxWidth="md" sx={{ mb: 4 }}>
        From the creators of Out of Shadows, we bring you Into the Light. Into the Light is a movie made to bring to surface that psychological operations are present and active in today's society. Into the Light will feature experts in psychological operations and mainstream media manipulation such as, General Michael Flynn, Lara Logan, Brian Gamble, Dr. Keith Rose, Boone Cutler and Mike Smith. This project brings an unbiased viewpoint to the control being done through psychological operations.
      </Typography>
      <Typography variant="body1" paragraph maxWidth="md" sx={{ mb: 4 }}>
        We hope that this movie brings to light the problems that need to be addressed. Our goal is to activate a community of people who are not afraid to stand up for the truth and make a change. Enjoy!
      </Typography>
      {/* <Button variant="contained" sx={{ width: "200px", }}>Purchase!</Button> */}
    </Stack>
  )
}


const light = {
  palette: {
    mode: "light",
  },
};


const AboutUs = () => {
  const navigate = useNavigate();
  const theme = useTheme();
  const bp = useMediaQuery(theme.breakpoints.up("md"));
  return(
    <Grid container>
      <Grid item xs={12}>
        <Typography
            variant="h4"
            noWrap
            alignSelf='center'
            sx={{
              fontWeight: 700,
              letterSpacing: ".3rem",
              textDecoration: "none",
              textTransform: "uppercase",
              flexGrow: 1,
              fontSize: '4rem',
              mb: 2
            }}>
            The Film
          </Typography>
      </Grid>
      <Box sx={{ py: "25px", px: 2, background: 'linear-gradient(273deg, rgba(48,44,45,.6) 53%, rgba(150,153,161,0.52) 91%);', width: "100%"}}>
        <Grid item xs={12} md={12}>
          <MissionStatement />
        </Grid>
        <Grid item xs={12} md={12} sx={{alignSelf: 'center'}}>
          {/* <Box display="flex" flexDirection={bp ? "row" : "column"} justifyContent="space-evenly"> */}
          <Stack direction={bp ? "row" : "column"} justifyContent="space-evenly" spacing={4}>
            <Box display="flex" flexDirection="column" alignItems="center">
              <Card elevation={24} raised sx={{ width: '100%', height: '100%', maxWidth: '450px', maxHeight: '650px', alignSelf: 'center', mb: 2 }} >
                <CardMedia component="img" src={ITL_POSTER} sx={{ width: '100%', height: '100%', objectFit: "cover" }}/>
              </Card>
              <Button variant="contained" size="large" fullWidth sx={{ maxWidth: "450px" }} onClick={() => navigate('/purchase')}>Digital Purchase!</Button>
            </Box>
            <Box display="flex" flexDirection="column" alignItems="center">
              <Card elevation={24} raised sx={{ width: '100%', height: '100%', maxWidth: '450px', maxHeight: '650px', alignSelf: 'center', mb: 2 }} >
                <CardMedia component="img" src={dvd_cover} sx={{ width: '100%', height: '100%', objectFit: "cover" }}/>
              </Card>
              <Button variant="contained" size="large" fullWidth sx={{ maxWidth: "450px" }} href={SHOP_URL} target="_blank">Get a DVD!</Button>
            </Box>
          </Stack>
          {/* </Box> */}
        </Grid>
      </Box>
        {/* <ToS />
        <PrivacyPolicy />
        <RefundPolicy /> */}
    </Grid>
  )
}

const MasonryImageList = () => {
  const navigate = useNavigate();
  const itemData = [...Array(8).keys()];
  let colCount = 4;
  const betweenMdLg = useMediaQuery((theme) => theme.breakpoints.between('md', 'lg'));
  const betweenSmMd = useMediaQuery((theme) => theme.breakpoints.between('sm', 'md'));
  const lessSM = useMediaQuery((theme) => theme.breakpoints.down('sm'));
  if (betweenMdLg) {
    colCount = 3;
  } else if (betweenSmMd) {
    colCount = 2;
  } else if (lessSM) {
    colCount = 1
  }
  // variant="woven"
  // display="flex" flexDirection="row" flexWrap="wrap" justifyContent="space-evenly"
  return (
    <Fade in timeout={800}>
      <Box mt={2}>
      <Typography
            variant="h4"
            noWrap
            alignSelf='center'
            sx={{
              fontWeight: 700,
              letterSpacing: ".3rem",
              textDecoration: "none",
              textTransform: "uppercase",
              flexGrow: 1,
              fontSize: '4rem',
              mb: 2
            }}>
            Featuring
          </Typography>
        <ImageList cols={colCount} gap={8}>
          {_.values(_.mapObject(cast_members, (item, idx) => {
            console.log('item', item);
            console.log('idx', idx);
            return (
              <ImageListItem >
                <CardActionArea sx={{ width: '100%', height: '100%',}} onClick={() => navigate(`/bio/${idx}`)}>
                  <Card sx={{ width: '100%', height: '100%',}} >
                    <CardMedia component="img" src={item.img} sx={{ width: '100%', height: '100%', objectFit: "cover" }}/>
                  </Card>
                  <ImageListItemBar
                    title={item.name}
                    position="bottom"
                    actionIcon={<Button>Bio</Button>}
                  >
                  </ImageListItemBar>
                </CardActionArea>
              </ImageListItem>
            );
          }))}
        </ImageList>
      </Box>
    </Fade>
  );
}