import { React, useRef } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import _ from "underscore";

// Container
import { Box, Card, CardActionArea, CardContent, CardMedia, Container, Grid, Typography } from "@mui/material";

// Icons
import LockIcon from "@mui/icons-material/Lock";

import { ITF_URL } from "../services/constants/keys";

const CardTile = ({ item, isSubscribed, setSelectedVid, handleUnlockOpen }) => {
  const navigate = useNavigate();
  const { is_free } = item;

  const isAvaiable = is_free || isSubscribed ? true : false;
  const unlockAction = () => {
    setSelectedVid(item.id);
    navigate("/purchase")
    // handleUnlockOpen();
  };
  const onClick = () => {
    isAvaiable ? navigate(`/watch/${item.id}`) : unlockAction();
  };

  return (
    <Card sx={{ width: "100%" }} className="videoGridItem">
      <CardActionArea onClick={onClick} sx={{ height: "100%", display: "flex", flexFlow: "column nowrap" }}>
        <CardMedia component="img" height="300px" width={1} image={item.thumbnail_url} />
        <CardContent className="film-tile-title" sx={{ marginTop: "auto" }}>
          <Typography gutterBottom variant="h6" component="div" color="inherit" display="flex" justifyContent="space-between" alignItems="center">
            {item.title}
            {!isAvaiable && <LockIcon color="error" />}
          </Typography>
        </CardContent>
      </CardActionArea>
    </Card>
  );
};

const ITF_Card = () => {
  // const card_url = "https://itl-assets.b-cdn.net/thumbnails/itf_ep_2.jpeg";
  const card_url = "https://itl-assets.b-cdn.net/thumbnails/itf_poster.jpeg";
  const item = {
    thumbnail_url: card_url,
    title: "Into The Fight"
  }
  return (
    <Grid item xs={12} sm={12} md={6} lg={6} xl={3} key="itf_card" className="film-tile" display="flex" sx={{ justifyContent: "stretch" }}>
      <Card sx={{ width: "100%" }} className="videoGridItem">
        <CardActionArea href={ITF_URL} target='_blank' sx={{ height: "100%", display: "flex", flexFlow: "column nowrap" }}>
          <CardMedia component="img" height="300px" width={1} image={item.thumbnail_url} />
          <CardContent className="film-tile-title" sx={{ marginTop: "auto" }}>
            <Typography gutterBottom variant="h6" component="div" color="inherit" display="flex" justifyContent="space-between" alignItems="center">
              {item.title}
            </Typography>
          </CardContent>
        </CardActionArea>
      </Card>
    </Grid>
  );
}

export default function Tiles({ videos, setSelectedVid, handleUnlockOpen }) {
  const sectionRef = useRef();
  const user_subs = useSelector((state) => state.user.subscriptions);
  const user_rentals = useSelector((state) => state.user.rentals);


  return (
    <Box sx={{ width: "inherit", background: "#000000", pb: 4 }} id="videoGrid" ref={sectionRef}>
      <Container maxWidth="false" sx={{ display: "flex" }}>
        <Grid container maxWidth="false" spacing={3} sx={{ padding: { xs: "0", md: "0 50px" } }}>
          {videos.map((item, index) => (
            <Grid item xs={12} sm={12} md={6} lg={6} xl={3} key={index} className="film-tile" display="flex" sx={{ justifyContent: "stretch" }}>
              <CardTile item={item} isSubscribed={_.contains(user_subs, item.id) || _.contains(user_rentals, item.id) } setSelectedVid={setSelectedVid} handleUnlockOpen={handleUnlockOpen} />
            </Grid>
          ))}
          <ITF_Card />
        </Grid>
      </Container>
    </Box>
  );
}
