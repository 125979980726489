import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";

// Components
import { Avatar, Badge, IconButton, Menu, MenuItem, SpeedDial, SpeedDialAction, Tooltip, Typography } from "@mui/material";
import { alpha } from '@mui/material/styles';

// Icons
import LogoutIcon from "@mui/icons-material/Logout";
import MenuIcon from '@mui/icons-material/Menu';
import HistoryIcon from '@mui/icons-material/History';
import KeyIcon from '@mui/icons-material/Key';
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import InfoIcon from '@mui/icons-material/Info';
import HistoryEduIcon from '@mui/icons-material/HistoryEdu';
import ContactMailIcon from '@mui/icons-material/ContactMail';
import TvIcon from '@mui/icons-material/Tv';
import PolicyIcon from '@mui/icons-material/Policy';

const alphaBackgroundColor70 = alpha('#121212', .7);

const ShoppingCart = () => {
  let cart_count = 0
  const user_cart = useSelector((state) => state.shop.user_cart);
  user_cart.forEach(item => cart_count += parseFloat(item.quantity));
  cart_count = cart_count > 9 ? '9+' : cart_count;

  return (
    cart_count !== 0 ?
    <Badge overlap="circular" anchorOrigin={{ vertical: 'top', horizontal: 'right' }} badgeContent={<Typography fontSize='.75rem'>{cart_count}</Typography>} color='error'>
      <ShoppingCartIcon />
    </Badge> :
    <ShoppingCartIcon />
  )
}

export default function UserSpeedDial({ first, last, openSettings, logOut }) {
  const navigate = useNavigate();
  let cart_count = 0
  const user_cart = useSelector((state) => state.shop.user_cart);
  user_cart.forEach(item => cart_count += parseFloat(item.quantity));
  cart_count = cart_count > 9 ? '9+' : cart_count;

  const actions = [
    // {
    //   icon: <Tooltip title="Settings" placement="left"><IconButton className="settingsButton" variant="text" sx={{ fontFamily: "Lora", letterSpacing: "2px", cursor: "pointer" }} onClick={() => navigate("/checkout")}><ShoppingCart /></IconButton></Tooltip>,
    //   name: "Shopping Cart",
    //   action: () => navigate("/checkout"),
    // },
    {
      icon: <Tooltip title="Settings" placement="left"><IconButton className="settingsButton" variant="text" sx={{ fontFamily: "Lora", letterSpacing: "2px", cursor: "pointer" }} onClick={() => navigate("/user/orders")}><HistoryIcon /></IconButton></Tooltip>,
      name: "Orders",
      action: () => navigate("/user/orders"),
    },
    {
      icon: <Tooltip title="About" placement="left"><IconButton className="settingsButton" variant="text" sx={{ fontFamily: "Lora", letterSpacing: "2px", cursor: "pointer" }} onClick={() => navigate("/about")}><InfoIcon /></IconButton></Tooltip>,
      name: "About",
      action: () => navigate("/about"),
    },
    // {
    //   icon: <Tooltip title="Settings" placement="left"><IconButton className="settingsButton" variant="text" sx={{ fontFamily: "Lora", letterSpacing: "2px", cursor: "pointer" }} onClick={openSettings}><KeyIcon /></IconButton></Tooltip>,
    //   name: "Reset Password",
    //   action: () => openSettings(),
    // },
    {
      icon: <Tooltip title="Host Screening" placement="left"><IconButton className="settingsButton" variant="text" sx={{ fontFamily: "Lora", letterSpacing: "2px", cursor: "pointer" }} onClick={() => navigate("/book-screening")}><TvIcon /></IconButton></Tooltip>,
      name: "Host Screening",
      action: () => navigate("/book-screening"),
    },
    {
      icon: <Tooltip title="Media Request" placement="left"><IconButton className="settingsButton" variant="text" sx={{ fontFamily: "Lora", letterSpacing: "2px", cursor: "pointer" }} onClick={() => navigate("/media-request")}><HistoryEduIcon /></IconButton></Tooltip>,
      name: "Media Requests",
      action: () => navigate("/media-request"),
    },
    {
      icon: <Tooltip title="Policies" placement="left"><IconButton className="settingsButton" variant="text" sx={{ fontFamily: "Lora", letterSpacing: "2px", cursor: "pointer" }} onClick={() => navigate("/policies")}><PolicyIcon /></IconButton></Tooltip>,
      name: "Policies",
      action: () => navigate("/policies"),
    },
    {
      icon: <Tooltip title="Logout" placement="left"><IconButton aria-label="delete" onClick={logOut}><LogoutIcon color="inherit" /></IconButton></Tooltip>,
      name: "Logout",
      action: () => logOut(),
    },
  ];

  const SpeedDialIcon = () => {
    return (
      cart_count !== 0 ?
        <Badge overlap="circular" anchorOrigin={{ vertical: 'top', horizontal: 'right' }} badgeContent={<Typography fontSize='.75rem'>{cart_count}</Typography>} color='error'>
          <Avatar sx={{ bgcolor: "transparent", color: "#121212", border: "1px solid" }}>
            {first.charAt(0)}
            {last.charAt(0)}
          </Avatar>
        </Badge> :
        <Avatar sx={{ bgcolor: "transparent", color: "#121212", border: "1px solid" }}>
          {first.charAt(0)}
          {last.charAt(0)}
        </Avatar>
    );
  }

  return (
    <>
      <SpeedDial
        ariaLabel="User Settings Speed Dial"
        direction="down"
        sx={{ position: "absolute", top: "10px", right: "10px", transform: "scale(.75)", transformOrigin: "top right" }}
        icon={<SpeedDialIcon />}
      >
        {actions.map((action) => (
          <SpeedDialAction tooltipOpen key={action.name} icon={action.icon} tooltipTitle={ <Typography onClick={action.action}>{action.name}</Typography>} />
        ))}
      </SpeedDial>
    </>
  );
}

export const SettingsMenu = ({ resetPw }) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const navigate = useNavigate();
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <IconButton className="settingsButton" variant="text" sx={{ fontFamily: "Lora", letterSpacing: "2px", cursor: "pointer" }} onClick={handleClick}>
        <MenuIcon />
      </IconButton>
      <Menu
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        sx={{p: 0}}
      >
        <MenuItem onClick={()=>{navigate("/user/orders"); } }><HistoryIcon sx={{ mr: 1}}/><Typography variant="subtitle1">Orders</Typography></MenuItem>
        <MenuItem onClick={()=>{navigate("/about"); } }><InfoIcon sx={{ mr: 1}}/><Typography variant="subtitle1">About</Typography></MenuItem>
        <MenuItem onClick={()=>{navigate("/book-screening"); } }><TvIcon sx={{ mr: 1}}/><Typography variant="subtitle1">Host Screening</Typography></MenuItem>
        <MenuItem onClick={()=>{navigate("/media-request"); } }><HistoryEduIcon sx={{ mr: 1}}/><Typography variant="subtitle1">Media Requests</Typography></MenuItem>
        <MenuItem onClick={()=>{navigate("/policies"); } }><PolicyIcon sx={{ mr: 1}}/><Typography variant="subtitle1">Policies</Typography></MenuItem>
        {/* <MenuItem onClick={()=>{handleClose(); resetPw(); } }><KeyIcon sx={{ mr: 1}}/><Typography variant="subtitle1">Password Reset</Typography></MenuItem> */}
        {/* <MenuItem onClick={()=>{navigate("/about"); } }><InfoIcon sx={{ mr: 1}}/><Typography variant="subtitle1">The Film</Typography></MenuItem> */}
        
      </Menu>
    </>
  );
}