import React from "react";

// Components
import { Box } from "@mui/material";

// Layouts

export default function FeatureFilmLayout(props) {
  return (
    <Box>
      <Box sx={{ display: "flex", minHeight: "100vh", left: "60px", right: "0", width: { xs: "100vw", md: "calc(100vw - 60px)" }, ml: { xs: "0", md: "60px" } }}>{props.children}</Box>
    </Box>
  );
}

FeatureFilmLayout.defaultProps = {};
