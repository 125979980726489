import * as React from "react";

// react
import { useNavigate } from "react-router-dom";

// Components
import { useMediaQuery, useTheme, AppBar, Box, Button, Container, Toolbar, Typography, Stack, IconButton } from "@mui/material";

// Icons
import LoginIcon from '@mui/icons-material/Login';

// Services

// Plugins
import gsap from "gsap";
import ScrollTrigger from "gsap/ScrollTrigger";
gsap.registerPlugin(ScrollTrigger);

// Landing Page Header(dynamic menu linking)
export default function LandingHeader({ handleLoginOpen }) {
  const pages = [{name:"home", ref:"/#home"}, {name:"About", ref:"/about"}, {name:"Gift", ref:"/purchase"}, {name:"Contact Us", ref:"/media-request"}, {name:"shop", ref:"/#shop"}];  // {name:"join", ref:"/#subscribe"},
  const theme = useTheme();
  const navigate = useNavigate();
  const bp = useMediaQuery(theme.breakpoints.down("sm"));

  return (
    <>
      <AppBar
        component="header"
        className="header stage-header"
        position="fixed"
        sx={{
          width: "100%",
          background: "#121212",
          boxShadow: "none",
        }}>
        <Container maxWidth="false" disableGutters>
          <Toolbar disableGutters sx={{justifyContent: bp ? "flex-end" : "space-between"}}>
            {!bp && <Box>
              <Typography
                variant="h6"
                noWrap
                onClick={() => navigate("/")}
                sx={{
                  ml: 2,
                  mr: 2,
                  fontWeight: 700,
                  letterSpacing: ".3rem",
                  textDecoration: "none",
                  textTransform: "uppercase",
                  "&:hover": {
                    cursor: "pointer"
                  }
                }}>
                Into the Light
              </Typography>
            </Box>}
            <Box>
              <Stack direction="row" alignItems="center">
                <Box className="primary-menu">
                  <Box display="flex">
                    {pages.map((page) => (
                      <Button color="white" className="sectionAnchor" onClick={() => navigate(page.ref)} key={page.name} sx={{ my: 2, mx:0 }}>
                        <Box className="wrap" component="span" textAlign="center">
                          {page.name}
                          <span className="underline"></span>
                          <span className="animated-underline"></span>
                        </Box>
                      </Button>
                    ))}
                  </Box>
                </Box>
                {!bp && <Box sx={{ flexGrow: 0, ml: 1 }}>
                  <Button id="loginButton" color="white" variant="outlined" size="small" onClick={handleLoginOpen}>
                    Sign-In/Register
                  </Button>
                </Box>}
                {bp && <IconButton aria-label="login" onClick={handleLoginOpen}>
                  <LoginIcon/>
                </IconButton>}
              </Stack>
            </Box>
          </Toolbar>
        </Container>
      </AppBar>
    </>
  );
}
