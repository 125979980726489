import React from "react";
import { useNavigate } from "react-router-dom";

// Components
import { AppBar, Box, Button, Container, IconButton, Toolbar, Tooltip, Typography, Badge } from "@mui/material";

import { SpeedDialNav } from "../../components/CustomStyledComponents";

// Icons
import LogoutIcon from "@mui/icons-material/Logout";
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';

// Layouts
import Sidebar from "../Sidebar";
import UserSpeedDial, { SettingsMenu } from "./Settings/UserSettings";

// React Hooks
import { useMediaQuery, useTheme } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { NavLink } from "react-router-dom";

// Services
import { getUser, logOut } from "../../redux/slices/user-slice";

// Plugins
import gsap from "gsap";
import ScrollTrigger from "gsap/ScrollTrigger";
gsap.registerPlugin(ScrollTrigger);

// Videos Page Header
export default function VideosHeader({ handleSettingsOpen }) {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { first_name } = useSelector(getUser);
  const { last_name } = useSelector(getUser);
  const user_cart = useSelector((state) => state.shop.user_cart);
  let cart_count = 0
  user_cart.forEach(item => cart_count += parseFloat(item.quantity));
  cart_count = cart_count > 9 ? '9+' : cart_count;

  const theme = useTheme();
  const bp = useMediaQuery(theme.breakpoints.down("md"));
  ScrollTrigger.create({
    trigger: ".pageWrapper",
    start: "top top",
    end: "99999",
    toggleClass: { className: "scrolled", targets: "header" },
    // onToggle: (self) => console.log("scrolling", self.isActive),
  });

  return (
    <>
      <AppBar
        component="header"
        className="header stage-header"
        position="fixed"
        sx={{
          width: "100%",
          background: "transparent",
          boxShadow: "none",
        }}>
        <Container
          maxWidth="false"
          sx={{ background: { md:"linear-gradient(90deg, #121212 0%, #121212 40%, rgba(12, 12, 12, 0) 60%, rgba(12, 12, 12, 0) 75%, rgba(12, 12, 12, 1) 85%, rgba(12, 12, 12, 1) 100%)", xs: "#121212", sm: "#121212" }  }}
          disableGutters>
          <Toolbar disableGutters sx={{ gap: "15px", flexDirection: "row", display: "flex" }}>
            {/* <img src={logo} width="30" alt="Crux Logo" /> */}
            {bp ? <Sidebar /> : null}

            {!bp && (
              <React.Fragment>
                <Box sx={{ display: "flex", flexGrow: "1" }} className="primary-menu">
                  <Box display="flex">
                    <Button component={NavLink} variant="h3" to="/" sx={{ fontSize: "1rem" }}>
                      Films
                    </Button>
                    {/* <Button component={NavLink} variant="h3" to="/" sx={{ fontSize: "1rem" }}>
                    Series
                  </Button>
                  <Button component={NavLink} variant="h3" to="/" sx={{ fontSize: "1rem" }}>
                    Documentaries
                  </Button> */}
                  </Box>
                </Box>

                <Box sx={{ display: "flex", gap: "10px" }} alignItems="center">
                  <Typography variant="h6" sx={{ fontFamily: "Lora", fontSize: ".85rem", textTransform: "none" }}>
                    Greetings, {first_name}!
                  </Typography>
                  {/* {user_cart.length > 0 ? <Tooltip  title="Shopping Cart" placement="bottom" >
                    <IconButton className="cart" variant="text"  onClick={() => navigate("/checkout")} sx={{ fontFamily: "Lora", letterSpacing: "2px", cursor: "pointer" }}>
                      <Badge overlap="circular" anchorOrigin={{ vertical: 'top', horizontal: 'right' }} badgeContent={<Typography fontSize='.75rem'>{cart_count}</Typography>} color='error'>
                        <ShoppingCartIcon />
                      </Badge>
                    </IconButton>
                  </Tooltip> : null} */}
                  <Tooltip title="User Menu" placement="bottom">
                    <SettingsMenu resetPw={handleSettingsOpen}/>
                  </Tooltip>
                  <Tooltip title="Logout" placement="bottom">
                    <IconButton aria-label="delete" onClick={() => dispatch(logOut())}>
                      <LogoutIcon color="inherit" />
                    </IconButton>
                  </Tooltip>
                </Box>
              </React.Fragment>
            )}
            {bp && (
              <React.Fragment>
                <UserSpeedDial first={first_name} last={last_name} logOut={() =>dispatch(logOut())} openSettings={handleSettingsOpen} />
              </React.Fragment>
            )}
          </Toolbar>
        </Container>
      </AppBar>
    </>
  );
}


{/* <Box sx={{display: { lg: "none", md: "none" }}}><SpeedDialNav /></Box> */}