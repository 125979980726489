import { configureStore } from '@reduxjs/toolkit'

import userReducer from './slices/user-slice';
import contentReducer from './slices/content-slice';
import shopSlice from './slices/shop-slice';

export const store = configureStore({
    reducer: {
        user: userReducer,
        content: contentReducer,
        shop: shopSlice,
    },
  })
