// This file is meant to be used to override MUI styles
// Do not use this for antying else!!

import { createTheme } from "@mui/material/styles";
import "@fontsource/oswald";
import "@fontsource/lora";

let theme = createTheme({
  palette: {
    mode: "dark",
    primary: {
      main: "#ffe8a8",
    },
    secondary: {
      main: "#F8CF40",
    },
    white: {
      main: "#ffffff",
    },
  },
  typography: {
    allVariants: {
      fontFamily: "Oswald",
      color: "#ffffff",
    },
    h1: {
      fontFamily: "Oswald Bold",
      textTransform: "uppercase",
      fontSize: "8rem",
    },
    h2: {
      fontFamily: "Oswald Light",
      fontSize: "8rem",
    },
    h3: {
      fontFamily: "Oswald Light",
      fontSize: "3rem",
    },
    h4: {
      fontFamily: "Oswald Semibold",
      fontSize: "3rem",
    },
    h5: {
      fontFamily: "Oswald Light",
      fontSize: "2rem",
    },
    h6: {
      fontFamily: "Oswald Light",
      textTransform: "uppercase",
    },
    body1: {
      fontFamily: "Lora",
      fontSize: "1.25rem",
      lineHeight: "1.6",
      color: "#cccccc",
    },
    body2: {
      fontFamily: "Lora",
      fontSize: "1.25rem",
      lineHeight: "1.6",
      color: "#545454",
    },
  },
});

theme = createTheme(theme, {
  typography: {
    allVariants: {
      color: "theme.palette.common.white",
    },
    h1: {
      [theme.breakpoints.down("md")]: {
        fontSize: "4rem",
      },
    },
    h2: {
      fontFamily: "Oswald Light",
      fontSize: "8rem",
      [theme.breakpoints.down("md")]: {
        fontSize: "4rem",
      },
    },
    h3: {
      fontFamily: "Oswald Light",
      fontSize: "3rem",
      [theme.breakpoints.down("md")]: {
        fontSize: "2rem",
      },
    },
    h4: {
      fontFamily: "Oswald Semibold",
      fontSize: "3rem",
      [theme.breakpoints.down("md")]: {
        fontSize: "2rem",
      },
    },
    h5: {
      fontFamily: "Oswald Light",
      fontSize: "2rem",
    },
    h6: {
      [theme.breakpoints.down("sm")]: {
        fontSize: "1rem",
      },
    },
    body1: {
      [theme.breakpoints.down("sm")]: {
        fontSize: "1rem",
      },
    },
    body2: {
      [theme.breakpoints.down("sm")]: {
        fontSize: "1rem",
      },
    },
  },
});

export default theme;
