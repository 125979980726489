import React, { useEffect, useLayoutEffect, useRef, useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";

// Data
import * as Constants from "../../services/constants/dummyData";
import { SHOP_URL, ITF_TRAILER } from "../../services/constants/keys";

// Redux
import { registerUser } from "../../redux/slices/user-slice";

// Services
import { checkPassword, isEmail, isNullOrEmpty } from "../../services/utilities";

// Components
import { Box, Button, Card, CardActionArea, CardActions, CardContent, CardMedia, Container, Fade, Grid, Link, Stack, TextField, Tooltip, Typography, useMediaQuery } from "@mui/material";
import Header from "../../layout/Header";
import Modal from "../../layout/Modal";

import { FeedBack, PasswordField, PasswordRequirements } from "../../components/CustomStyledComponents";

// Icons
import PlayArrowOutlinedIcon from "@mui/icons-material/PlayArrowOutlined";
import VolunteerActivismIcon from '@mui/icons-material/VolunteerActivism';

import { alpha } from "@mui/material/styles";
// Plugins
import { useTheme } from "@emotion/react";
import gsap from "gsap";
import ScrollToPlugin from "gsap/ScrollToPlugin";
import ScrollTrigger from "gsap/ScrollTrigger";
gsap.registerPlugin(ScrollTrigger);
gsap.registerPlugin(ScrollToPlugin);

// const OOS_POSTER = "https://itl-assets.b-cdn.net/thumbnails/oos_poster2.jpg";
// const ITL_POSTER = "https://itl-assets.b-cdn.net/thumbnails/itl_poster_grey.jpg";

const OOS_POSTER = "https://vz-a8b2ac35-299.b-cdn.net/4c3c10de-7169-4b34-82a2-87fd4b069273/preview.webp";
const ITL_POSTER = "https://itl-assets.b-cdn.net/thumbnails/ITL_loop.webp";

const alphaWh40 = alpha('#ffffff', .4);
const alphaWh70 = alpha('#ffffff', .7);

const alphaBl40 = alpha('#121212', .4);
const alphaBl70 = alpha('#121212', .7);

const ImageBox = () => {
  return (
    <>
    </>
  )
}

export default function LandingPage() {
  const navigate = useNavigate();
  const [loginState, setLoginState] = useState(false);
  const handleLoginOpen = function () {
    setLoginState(true);
  };
  const handleLoginClose = () => setLoginState(false);
  let heroh6,
    heroh1,
    heroButton,
    heroButton2,
    heroButton3 = useRef(null);

  const theme = useTheme();
  const bp = useMediaQuery(theme.breakpoints.down("md"));
  const imageSize= bp ? { width: "100vw", height: "40vh" } : { width: "35vw", height: "80vh" };

  return (
    <React.Fragment>
      {/* <SectionLinking /> */}
      {/* <SectionScrollAnimations /> */}
      <AnimationsOnSectionScroll />
      <Box className="sectionsWrapper">
        {loginState && <Modal type="login" handleClose={handleLoginClose} open={loginState} />}
        <Box
          sx={{
            width: "100%",
            height: "100%",
            overflowX: "hidden",
          }}>
          <Header type="landing" handleOpen={handleLoginOpen} />
          <Box
            display="flex"
            alignItems="stretch"
            className="section section1"
            id="home"
            sx={{
              backgroundImage: `url(${Constants.imageHero})`,
              backgroundSize: "cover",
              backgroundRepeat: "no-repeat",
              backgroundPosition: "center",
              backgroundAttachment: "fixed",
              width: "100%",
            }}
          >
            <Box
              display="flex"
              alignItems="stretch"
              sx={{
                width: "100%",
                background: "linear-gradient(180deg, rgba(18, 18, 18, 1) 0%, rgba(18, 18, 18, 1) 10%, rgba(18, 18, 18, 0) 25%, rgba(18, 18, 18, 0) 100%)",
              }}
            >
              <Box
                display="flex"
                alignItems="stretch"
                flexDirection='column'
                justifyContent="center"
                sx={{
                  width: "100%",
                  // height: '100%',
                  background: "linear-gradient(90deg, rgba(18, 18, 18, 1) 0%, rgba(18, 18, 18, .8) 35%, rgba(18, 18, 18, 0) 45%, rgba(18, 18, 18, .2) 70%, rgba(18, 18, 18, .2) 71%, rgba(18, 18, 18, .7) 76%, rgba(18, 18, 18, .8) 100%)",
                }}
              >
                <Container maxWidth="100%" sx={{ display: 'flex', flexDirection: "column", alignItems: 'center' }}>
                  <Stack direction={bp ? 'column' : 'row'} p={2} spacing={1} alignItems="center" justifyContent="center">
                    <Fade in timeout={400}>
                      <Box sx={{ ...imageSize, backgroundImage: `url(${OOS_POSTER})`, backgroundSize: "cover", backgroundPosition: "center", objectFit: "cover", position: "relative" }}>
                      <Box sx={{ background: 'rgb(150,153,161)', background: 'linear-gradient(188deg, rgba(150,153,161,0.1) 13%, rgba(48,44,45,.7) 77%)', height: 1 }}>
                        <Box sx={{ position: "absolute", top: '50%', right: '50%', transform: 'translate(50%,-50%)' }}>
                          <Stack spacing={4} alignItems="center">
                            <Typography variant="h1" fontSize={{ xs: "3rem", md: "3.5rem", lg:"4rem", xl: "5rem" }} noWrap sx={{ textShadow: '-4px 4px 0 #000', }}>Out of Shadows</Typography>
                            <Button ref={heroButton} variant="outlined" onClick={handleLoginOpen} sx={{ width: "150px", border: "2px solid", textShadow: '-2px 2px 0 #000', }}>
                              Watch Free
                            </Button>
                          </Stack>
                        </Box>
                        </Box>
                      </Box>
                    </Fade>
                    <Fade in timeout={400}>
                      <Box sx={{ ...imageSize, backgroundImage: `url(${ITL_POSTER})`,backgroundSize: "cover", backgroundPosition: "center", objectFit: "cover", position: "relative", }}>
                        {/* <Box sx={{ background: 'rgb(150,153,161)', background: 'linear-gradient(188deg, rgba(150,153,161,0.1) 13%, rgba(48,44,45,.7) 77%)', height: 1 }}> */}
                          <Box sx={{ position: "absolute", top: '50%', right: '50%', transform: 'translate(50%,-50%)' }}>
                            <Stack spacing={4} borderRadius={4} alignItems="center">
                              <Typography variant="h1" fontSize={{ xs: "3rem", md: "3.5rem", lg:"4rem", xl: "5rem" }} noWrap sx={{ textShadow: '-4px 4px 0 #000' }}>Into The Light</Typography>
                              <Button ref={heroButton2} variant="outlined" onClick={() => navigate('/purchase')} sx={{ width: "150px", border: "2px solid", textShadow: '-2px 2px 0 #000', }}>
                                  Watch
                              </Button>
                            </Stack>
                          </Box>
                        {/* </Box> */}
                      </Box>
                    </Fade>
                  </Stack>
                  <Stack spacing={2} sx={{ dixplay: "flex", flexDirection: "column", alignItems: "center", justifyContent: "center" }}>
                    <Typography ref={heroh1} variant="h6" textAlign="center">If you enjoyed Out of Shadows, please support our sequel Into The Light</Typography>
                    <Button ref={heroButton3} className="watch2Button" variant="outlined" size="small" sx={{ mt: 1, width: "150px", border: "2px solid" }} onClick={() => navigate("/redeem")}>
                      Have a code? Redeem
                    </Button>
                  </Stack>
                  
                  {/* TODO: Regular poster display */}
                  {/* <Box display="flex" justifyContent="space-evenly" alignItems="center" alignContent="space-between" flexDirection={bp ? "column" : "row"}>
                    {bp ? 
                      <Stack spacing={1}>
                        <Box sx={{backgroundImage: `url(${OOS_POSTER})`, backgroundSize: "cover", backgroundPosition: "center", width: "350px", height: "35vh"}} />
                        <Box sx={{backgroundImage: `url(${ITL_POSTER})`,backgroundSize: "cover", backgroundPosition: "center", width: "350px", height: "35vh"}} />
                      </Stack> :
                      <>
                        <Box sx={{backgroundImage: `url(${OOS_POSTER})`, backgroundSize: "cover", backgroundPosition: "center", width: "30vw", height: "50vh"}} />
                        <Box sx={{backgroundImage: `url(${ITL_POSTER})`,backgroundSize: "cover", backgroundPosition: "center", width: "30vw", height: "50vh"}} />
                      </>
                    }
                  </Box> */}
                </Container>
              </Box>
            </Box>
          </Box>
          {/* <Box
            display="flex"
            alignItems="stretch"
            className="section section1"
            id="home"
            sx={{
              backgroundImage: `url(${Constants.imageHero})`,
              backgroundSize: "cover",
              backgroundRepeat: "no-repeat",
              backgroundPosition: "center",
              backgroundAttachment: "fixed",
              width: "100%",
            }}>
            <Box
              display="flex"
              alignItems="stretch"
              sx={{
                width: "100%",
                background: "linear-gradient(180deg, rgba(18, 18, 18, 1) 0%, rgba(18, 18, 18, 1) 10%, rgba(18, 18, 18, 0) 25%, rgba(18, 18, 18, 0) 100%)",
              }}>
              <Box
                display="flex"
                alignItems="stretch"
                flexDirection='column'
                sx={{
                  width: "100%",
                  // height: '100%',
                  background: "linear-gradient(90deg, rgba(18, 18, 18, 1) 0%, rgba(18, 18, 18, .8) 35%, rgba(18, 18, 18, 0) 45%, rgba(18, 18, 18, .2) 70%, rgba(18, 18, 18, .2) 71%, rgba(18, 18, 18, .7) 76%, rgba(18, 18, 18, .8) 100%)",
                }}>
                <Box display="flex" justifyContent="center" alignItems="center" flexDirection='column' sx={{ width: "100%", height: "100%" }}>
                  <Container align="center">
                    <Typography ref={heroh6} variant="h6" gutterBottom sx={{ letterSpacing: "1px" }}>
                      From "Out of Shadows" we now take you
                    </Typography>
                    <Typography ref={heroh1} variant="h1" gutterBottom sx={{ fontWeight: "700" }}>
                      Into the Light
                    </Typography>
                    <Box display="flex" flexDirection="column" maxWidth="200px">
                      <Button ref={heroButton} className="watchButton" variant="contained" size="large"  onClick={handleLoginOpen}>
                        Sign Up and Watch
                      </Button>
                      <Button ref={heroButton2} className="redeemButton" variant="contained" size="large" sx={{ mt: 1 }} onClick={() => navigate("/redeem")}>
                        Have a code? Redeem
                      </Button>
                    </Box>
                  </Container>
                </Box>
                <Box sx={{ alignSelf:'center', mb: 1 }}>
                  <Typography ref={heroh1} variant="h6" gutterBottom sx={{ letterSpacing: "1px", mb: 1, textShadow: "1px 1px 2px black", textAlign: "center" }}>
                    <Typography ref={heroh1} variant="h5" sx={{mb: 1}}>Out of Shadows</Typography>
                      will always remain free, sign in to watch!
                      <Typography ref={heroh1} variant="h6" sx={{mb: 1}}>If you enjoyed Out of Shadows, please support our sequel Into The Light</Typography>
                  </Typography>
                </Box>
              </Box>
            </Box>
          </Box> */}

          {/* --------------------------------------- SECTION 2 --------------------------------------- */}
          {/* <Box
            display="flex"
            className="section section2"
            id="trailer"
            sx={{
              backgroundImage: `url(${Constants.imageHero})`,
              backgroundSize: "cover",
              backgroundRepeat: "no-repeat",
              backgroundPosition: "center",
              backgroundAttachment: "fixed",
              width: "100%",
            }}>
            <Box
              display="flex"
              justifyContent="center"
              alignItems="center"
              sx={{
                width: "100%",
              }}>
              <Container maxWidth="md">
                <Typography variant="h2" align="center" sx={{ textShadow: "1px 1px 2px black", fontWeight: "700" }} mb={2}>
                  Out Of Shadows
                </Typography>
                <Typography variant="h4" align="center" sx={{ textShadow: "1px 1px 2px black" }} mb={2}>
                  Official
                </Typography>
                <Card elevation={2} sx={{ backgroundColor: "transparent" }} onClick={handleLoginOpen}>
                  <CardMedia component="img" image="https://itl-assets.b-cdn.net/thumbnails/oos_poster2.jpg" />
                  <CardActionArea onClick={handleLoginOpen} className="watchButton" sx={{ mt: 2 }}>
                    <Link href="#">
                      <CardActions disableSpacing sx={{ justifyContent: "center", background: "#ffe8a8" }}>
                        <Typography variant="body2" align="center">
                          Watch for Free
                        </Typography>
                      </CardActions>
                    </Link>
                  </CardActionArea>
                </Card>
              </Container>
            </Box>
          </Box> */}
          {/* --------------------------------------- SECTION 2 --------------------------------------- */}
          <Box className="section section3" id="about" display="flex">
            <Box
              display="flex"
              sx={{
                backgroundImage: `url(${Constants.imageHero})`,
                backgroundSize: "cover",
                backgroundRepeat: "no-repeat",
                backgroundPosition: "center",
                backgroundAttachment: "fixed",
                width: "100%",
              }}>
              <Box display="flex" justifyContent="center" alignItems="center" sx={{ width: "100%", height: "100%" }}>
                <Container maxWidth="false">
                  <Typography component="h2" variant="h2" gutterBottom>
                    About the film
                  </Typography>
                  <Typography paragraph variant="body1" maxWidth="sm" sx={{ mb: 4 }}>
                    From the creators of Out of Shadows, we bring you Into the Light. Into the Light is a movie made to bring to surface that psychological operations are present and active in today's society. Into the Light will feature experts in psychological operations and mainstream media manipulation such as, General Michael Flynn, Lara Logan, Brian Gamble, Dr. Keith Rose, Boone Cutler and Mike Smith. This project brings an unbiased viewpoint to the control being done through psychological operations.
                  </Typography>
                  <Typography variant="body1" paragraph maxWidth="sm" sx={{ mb: 4 }}>
                    We hope that this movie brings to light the problems that need to be addressed. Our goal is to activate a community of people who are not afraid to stand up for the truth and make a change. Enjoy!
                  </Typography>
                  <Typography variant="body1" paragraph maxWidth="sm" sx={{ mb: 4 }}>
                    Our Terms of Service, Privacy Policy, Refund Policy can be found below.<Typography onClick={() => navigate('/policies')} color="skyblue" sx={{'&:hover': {cursor: 'pointer'}}}>Policies</Typography>
                  </Typography>
                  <Button className="watchButton" variant="contained" size="large" onClick={() => navigate('/purchase')}>
                    Purchase Film!
                    {/* <VolunteerActivismIcon sx={{ ml: 1, mb: .5 }} /> */}
                  </Button>
                </Container>
              </Box>
            </Box>
          </Box>
          <Box
            display="flex"
            className="section section2"
            id="trailer"
            sx={{
              width: "100%",
              backgroundImage: `url(${Constants.imageBackgroundVideo})`,
              backgroundPosition: "0 70%",
            }}>
            <Box
              display="flex"
              justifyContent="center"
              alignItems="center"
              sx={{
                width: "100%",
              }}>
              <Container maxWidth="md">
                <Typography variant="h3" align="center" sx={{ color: "#000000" }} mb={2}>
                  Into The Light Trailer
                </Typography>
                <Card elevation={2} sx={{ backgroundColor: "transparent" }}>
                  {/* <video src='https://itl-assets.b-cdn.net/videos/ITL_Trailer_1.mp4' controls controlsList="nodownload"/> */}
                  <video src={ITF_TRAILER} controls controlsList="nodownload"/>
                  <CardActionArea onClick={handleLoginOpen} className="watchButton">
                    <Link href="#">
                      <CardActions disableSpacing sx={{ justifyContent: "center", background: "#ffe8a8" }}>
                        <Typography variant="body2" align="center">
                          Watch or Gift film
                        </Typography>
                      </CardActions>
                    </Link>
                  </CardActionArea>
                </Card>
              </Container>
            </Box>
          </Box>
          <SubscriptionSection />
          <Box
            className="section section5"
            id="shop"
            display="flex"
            sx={{ backgroundImage: `url(${Constants.imageHero})`, backgroundSize: "cover", backgroundRepeat: "no-repeat", backgroundPosition: "center", backgroundAttachment: "fixed" }}>
            <Box
              display="flex"
              sx={{
                width: "100%",
                background: "linear-gradient(180deg, rgba(18, 18, 18, 1) 0%, rgba(18, 18, 18, 1) 10%, rgba(18, 18, 18, 0) 25%, rgba(18, 18, 18, 0) 100%)",
              }}>
              <Box
                display="flex"
                sx={{
                  width: "100%",
                  background:
                    "linear-gradient(90deg, rgba(18, 18, 18, 1) 0%, rgba(18, 18, 18, .8) 35%, rgba(18, 18, 18, 0) 45%, rgba(18, 18, 18, .2) 70%, rgba(18, 18, 18, .2) 71%, rgba(18, 18, 18, .7) 76%, rgba(18, 18, 18, .8) 100%)",
                  alignItems: { xs: "top", md: "center" },
                }}>
                <Container maxWidth="false">
                  <Box justifyContent="center" alignItems="center" sx={{ display: { xs: "block", lg: "flex" }, gap: "15px" }}>
                    <Box sx={{ marginRight: "2em", mt: 4, mb: 4 }} align="center">
                      <Typography component="h5" variant="h5" className="display small" gutterBottom sx={{ marginTop: { xs: "100px", md: "0" } }}>
                        the
                      </Typography>
                      <Typography component="h3" variant="h1" className="display" sx={{ fontSize: { xs: "8rem", lg: "15rem" } }}>
                        Shop
                      </Typography>
                    </Box>
                    <Grid container maxWidth="false" spacing={2}>
                      <Grid item xs={12} sm={12} md={4}>
                        <Card className="card" sx={{ width: "470px" }}>
                          <CardActionArea>
                            <Link href={SHOP_URL} target='_blank'>
                              <CardMedia component="img" height="470" image="https://itl-assets.b-cdn.net/store_items/itf_dvd_doublefeature.webp" alt="dvd" />
                            </Link>
                          </CardActionArea>
                        </Card>
                      </Grid>
                      <Grid item xs={12} sm={12} md={4}>
                        <Card className="card" sx={{ width: "470px" }}>
                          <CardActionArea>
                            <Link href={SHOP_URL} target='_blank'>
                              <CardMedia component="img" height="470" image="https://itl-assets.b-cdn.net/store_items/mens_shirt.webp" alt="shirt" />
                            </Link>
                          </CardActionArea>
                        </Card>
                      </Grid>
                      <Grid item xs={12} sm={12} md={4}>
                        <Card className="card" sx={{ width: "470px" }}>
                          <CardActionArea>
                            <Link href={SHOP_URL} target='_blank'>
                              <CardMedia component="img" height="470" image="https://itl-assets.b-cdn.net/store_items/hat.webp" alt="hat" />
                            </Link>
                          </CardActionArea>
                        </Card>
                      </Grid>
                    </Grid>
                  </Box>
                </Container>
              </Box>
            </Box>
          </Box>
        </Box>
      </Box>
    </React.Fragment>
  );
}

const SubscriptionSection = () => {
  const dispatch = useDispatch();
  const [firstName, setFirstName] = useState(null);
  const [lastName, setLastName] = useState(null);
  const [email, setEmail] = useState(null);
  const [password, setPassword] = useState(null);
  const [isDisabled, setDisabled] = useState(true);
  const [feedBack, setFeedback] = useState({ passed: null, comp: null });
  const [isPwVisible, setIsPwVisible] = useState(false);

  useEffect(() => {
    if (isNullOrEmpty(firstName) || isNullOrEmpty(lastName) || isNullOrEmpty(email) || !isEmail(email) || !checkPassword(password)) {
      setDisabled(true);
    } else {
      setDisabled(false);
    }
  }, [firstName, lastName, email, password]);

  const registerClick = async () => {
    const payload = {
      first_name: firstName,
      last_name: lastName,
      email: email,
      password: password,
    };
    try {
      setDisabled(true);
      await dispatch(registerUser(payload)).unwrap();
      setFeedback({ passed: false, comp: <FeedBack passed={true} message="Success!" mt={1} /> });
      setDisabled(false);
    } catch (exception) {
      let msg = "Registeration failed. Please try again";
      if (exception.detail == "email already registered") {
        msg = "Email already registered, please sign in";
      }
      setFeedback({ passed: false, comp: <FeedBack passed={false} message={msg} mt={1} /> });
      setDisabled(false);
    }
  };

  const theme = useTheme();
  const bp = useMediaQuery(theme.breakpoints.down("md"));

  return (
    <Box
      component="div"
      className="section section4"
      display="flex"
      id="subscribe"
      sx={{
        backgroundColor: "#000000",
        backgroundImage: `url(${Constants.imageNewsletter})`,
        backgroundPosition: "100% 50%",
        backgroundSize: "auto 100%",
        backgroundRepeat: "no-repeat",
      }}>
      <Box
        display="flex"
        sx={{
          width: "100%",
          background: "linear-gradient(90deg, rgba(18, 18, 18, 1) 0%, rgba(18, 18, 18, 1) 33%, rgba(18, 18, 18, 0) 80%)",
        }}>
        <Box display="flex" justifyContent="center" alignItems="center" sx={{ width: "100%", height: "100%" }}>
          <Container maxWidth="lg">
            <Grid container maxWidth="false" spacing={8} justifyContent="left" alignItems="center">
              <Grid item md={6}>
                <Typography component="h4" gutterBottom variant="h4" pr={2}>
                  Become a Member
                </Typography>
                <Typography variant="body1">
                  If you would like to receive exclusive and direct updates with our upcoming projects, sign up to become a member! We hope you enjoyed the film. Thank you!
                </Typography>
              </Grid>
              <Grid item md={6} sx={{ paddingTop: { xs: "0" } }}>
                <Box component="form" noValidate sx={{ mt: 3 }}>
                  <Grid container spacing={2}>
                    <Grid item xs={6} sm={6} sx={{ maxWidth: 460 }}>
                      <TextField className="animate" autoComplete="given-name" name="firstName" required fullWidth id="firstName" label="First Name" onChange={(e) => setFirstName(e.target.value)} />
                    </Grid>
                    <Grid item xs={6} sm={6}>
                      <TextField className="animate" required fullWidth id="lastName" label="Last Name" name="lastName" autoComplete="family-name" onChange={(e) => setLastName(e.target.value)} />
                    </Grid>
                    <Grid item xs={12}>
                      <TextField className="animate" required fullWidth id="email" label="Email Address" name="email" autoComplete="email" onChange={(e) => setEmail(e.target.value)} />
                    </Grid>
                    <Grid item xs={12}>
                      <Tooltip disableHoverListener open={isPwVisible} placement="top" title={<PasswordRequirements value={password} />}>
                        <Box>
                          <PasswordField
                            className="animate"
                            required
                            fullWidth
                            name="password"
                            label="Password"
                            id="password"
                            autoComplete="new-password"
                            onChange={(e) => setPassword(e.target.value)}
                            onFocus={()=>setIsPwVisible(true)}
                            onBlur={() => setIsPwVisible(false)}
                            onKeyPress={(e) => (e.code === "Enter" ? registerClick() : null)}
                          />
                        </Box>
                      </Tooltip>
                    </Grid>
                  </Grid>
                  <Button className="subscribeButton" variant="contained" color="primary" size="large" disabled={isDisabled} sx={{ mt: 3, mb: 2 }} onClick={registerClick}>
                    Become a Member
                  </Button>
                  {feedBack.passed !== null && feedBack.comp}
                </Box>
              </Grid>
            </Grid>
          </Container>
        </Box>
      </Box>
    </Box>
  );
};

function SectionLinking() {
  // Get all links
  let menuLinks = document.querySelectorAll(".primary-menu a");

  menuLinks.forEach((link, index, value) => {
    let underline = link.querySelector(".animated-underline");
    link.tl = gsap.timeline({ paused: true });

    link.tl.fromTo(
      underline,
      {
        width: "0%",
        left: "0%",
      },
      {
        width: "100%",
        duration: 1,
      }
    );

    link.tl.add("midway");

    link.tl.fromTo(
      underline,
      {
        width: "100%",
        left: "0%",
      },
      {
        width: "0%",
        left: "100%",
        duration: 1,
        immediateRender: false,
      }
    );
  });
}

function SectionScrollAnimations() {
  useEffect(() => {
    gsap.defaults({ overwrite: "auto", duration: 0.3 });
    let sections = gsap.utils.toArray(".section:not(.section5)");
    let navLinks = gsap.utils.toArray(".primary-menu a");

    gsap.utils.toArray(navLinks).forEach(function (a, i) {
      let tar = a.getAttribute("href");
      tar = tar.replace(/\/#/g, "");
      let distance = document.getElementById(tar).getBoundingClientRect().top + 1 + window.pageYOffset;
      a.addEventListener("click", function (e) {
        e.preventDefault();
        gsap.to(window, { duration: 0.3, scrollTo: distance, overwrite: true });
      });
    });

    sections.forEach((section, i) => {
      ScrollTrigger.create({
        trigger: ".section" + (i + 1) && fadeSection(i),
        onToggle: (self) => self.isActive,
        onEnter: () => navLinks[i].classList.add("active"),
        onEnterBack: () => navLinks[i].classList.add("active"),
        onLeave: () => navLinks[i].classList.remove("active"),
        onLeaveBack: () => navLinks[i].classList.remove("active"),
        pin: true,
        pinSpacing: false,
        scrub: 1,
        start: "top top",
        snap: 0,
      });
    });

    ScrollTrigger.create({
      trigger: ".section5",
      onToggle: (self) => self.isActive && fadeSection(5),
      onEnter: () => navLinks[4].classList.add("active"),
      onEnterBack: () => navLinks[4].classList.add("active"),
      onLeave: () => navLinks[4].classList.remove("active"),
      onLeaveBack: () => navLinks[4].classList.remove("active"),
      scrub: 1,
      start: "top top",
      snap: 0,
    });

    function fadeSection(i) {
      let currentTL = gsap.timeline();
      let sections = document.querySelectorAll(".section");
      sections.forEach(function (section) {
        section.classList.remove("active");
      });
      let currentSection = document.querySelector(".section" + i);
      currentSection.classList.add("active");
      currentTL.to(sections, { autoAlpha: 0 });
      currentTL.to(".section.active", { autoAlpha: 1 }, 0);

      // console.log("fading to section " + i);
    }

    // function setSection(section) {
    //   console.log("trying to set section");
    //   let sub = section.querySelector("h6");
    //   let h1 = section.querySelector("h1");
    //   let h2 = section.querySelector("h2");
    //   let h3 = section.querySelector("h3");
    //   let h4 = section.querySelector("h4");
    //   let h5 = section.querySelector("h5");
    //   let button = section.querySelector("button");
    //   let buttons = section.querySelectorAll("button");
    //   let video = section.querySelector("video");
    //   if (section.classList.contains("section1")) {
    //     tl.fromTo(sub, { y: -50, opacity: 0 }, { y: 0, opacity: 1 }).fromTo(h1, { scale: 1.2, opacity: 0 }, { scale: 1, opacity: 1 });
    //     let delay = 0.5;
    //     buttons.forEach((item) => {
    //       tl.fromTo(item, { opacity: 0, scale: 0.8 }, { opacity: 1, scale: 1 }, (delay += 0.5));
    //     });
    //     console.log("aiwdowiahdoiawdhoi");
    //   }
    //   if (section.classList.contains("section2")) {
    //     let p = section.querySelectorAll("p");
    //     tl.fromTo(h2, { x: -50, opacity: 0 }, { x: 0, opacity: 1 });
    //     p = [...p];
    //     p.forEach((p) => {
    //       tl.fromTo(p, { x: -100, opacity: 0 }, { x: 0, opacity: 1 });
    //     });
    //     tl.fromTo(button, { opacity: 0, scale: 0.8 }, { opacity: 1, scale: 1 });
    //   }
    //   if (section.classList.contains("section3")) {
    //     tl.fromTo(h3, { y: -50, opacity: 0 }, { y: 0, opacity: 1 })
    //       .fromTo(video, { scale: 1.2, opacity: 0 }, { duration: 1, scale: 1, opacity: 1 })
    //       .fromTo(button, { opacity: 0, y: 50 }, { opacity: 1, y: 0 });
    //   }
    //   if (section.classList.contains("section4")) {
    //     let p = section.querySelectorAll("p");
    //     tl.fromTo(h4, { x: -100, opacity: 0 }, { x: 0, opacity: 1 });
    //     p = [...p];
    //     p.forEach((p) => {
    //       tl.fromTo(p, { x: -100, opacity: 0 }, { x: 0, opacity: 1 });
    //     });
    //     let inputs = section.querySelectorAll(".animate");
    //     inputs = [...inputs];
    //     inputs.forEach((i) => {
    //       tl.fromTo(i, { x: 100, opacity: 0 }, { duration: 0.2, x: 0, opacity: 1 });
    //     });
    //     tl.fromTo(button, { opacity: 0, x: 100 }, { opacity: 1, x: 0 });
    //   }
    //   if (section.classList.contains("section5")) {
    //     tl.fromTo(h5, { y: -50, opacity: 0 }, { y: 0, opacity: 1 }).fromTo(h3, { scale: 1.2, opacity: 0 }, { scale: 1, opacity: 1 });
    //     let cards = section.querySelectorAll(".MuiCard-root");
    //     cards = [...cards];
    //     cards.forEach((item) => {
    //       tl.fromTo(item, { y: -50, opacity: 0, scale: 0 }, { duration: 0.2, y: 0, opacity: 1, scale: 1 });
    //     });
    //   }
    // }

    ScrollTrigger.create({
      start: 1,
    });

    ScrollTrigger.normalizeScroll(true);
  });
}

function AnimationsOnSectionScroll() {
  useLayoutEffect(() => {
    let navLinks = document.querySelectorAll(".sectionAnchor");

    gsap.defaults({ overwrite: "auto", duration: .8 });

    ScrollTrigger.create({
      start: 1,
    }).scroll(2);
    let s1a = gsap.timeline({
      scrollTrigger: {
        trigger: ".section1",
        start: "top center",
        endTrigger: ".section1",
        end: "bottom bottom",
        onEnter: () => navLinks[0].classList.add("active"),
        onEnterBack: () => navLinks[0].classList.add("active"),
        onLeave: () => navLinks[0].classList.remove("active"),
        onLeaveBack: () => navLinks[0].classList.remove("active"),
      },
    });

    s1a.fromTo(document.querySelector(".section1 h6"), { y: -50, opacity: 0 }, { y: 0, opacity: 1 });
    s1a.fromTo(document.querySelector(".section1 button.watchButton"), { x: -200, opacity: 0 }, { x: 0, opacity: 1 });
    s1a.fromTo(document.querySelector(".section1 button.watch2Button"), { y: 50, opacity: 0 }, { y: 0, opacity: 1 });
    s1a.fromTo(document.querySelector(".section1 button.redeemButton"), { x: 200, opacity: 0 }, { x: 0, opacity: 1 }, "<");
  }, []);
  useEffect(() => {
    let navLinks = document.querySelectorAll(".sectionAnchor");

    gsap.defaults({ overwrite: "auto", duration: 1 });
    let s2a = gsap.timeline({
      scrollTrigger: {
        trigger: ".section2",
        start: "top center",
        endTrigger: ".section2",
        scrub: true,
        end: "bottom bottom",

        onEnter: () => navLinks[1].classList.add("active"),
        onEnterBack: () => navLinks[1].classList.add("active"),
        onLeave: () => navLinks[1].classList.remove("active"),
        onLeaveBack: () => navLinks[1].classList.remove("active"),
      },
    });

    s2a.fromTo(document.querySelector(".section2 video"), { opacity: 0.5 }, { opacity: 1 });
    s2a.fromTo(document.querySelector(".section2 button.watchButton"), { y: 50, opacity: 0 }, { y: 0, opacity: 1 });

    let s3a = gsap.timeline({
      scrollTrigger: {
        trigger: ".section3",
        start: "top center",
        endTrigger: ".section3",
        scrub: true,
        end: "bottom bottom",

        onEnter: () => navLinks[2].classList.add("active"),
        onEnterBack: () => navLinks[2].classList.add("active"),
        onLeave: () => navLinks[2].classList.remove("active"),
        onLeaveBack: () => navLinks[2].classList.remove("active"),
      },
    });

    s3a.fromTo(document.querySelectorAll(".section3 p"), { y: 50, opacity: 0 }, { y: 0, opacity: 1 });
    s3a.fromTo(document.querySelector(".section3 button.watchButton"), { y: 50, opacity: 0 }, { y: 0, opacity: 1 });

    let s4a = gsap.timeline({
      scrollTrigger: {
        trigger: ".section4",
        start: "top center",
        endTrigger: ".section4",
        scrub: true,
        end: "bottom bottom",

        onEnter: () => navLinks[3].classList.add("active"),
        onEnterBack: () => navLinks[3].classList.add("active"),
        onLeave: () => navLinks[3].classList.remove("active"),
        onLeaveBack: () => navLinks[3].classList.remove("active"),
      },
    });

    s4a.fromTo(document.querySelectorAll(".section4 p"), { x: -100, opacity: 0 }, { x: 0, opacity: 1 });
    document.querySelectorAll(".section4 .animate").forEach(function (section, i) {
      s4a.fromTo(section, { x: 100, opacity: 0 }, { x: 0, opacity: 1 });
    });
    s4a.fromTo(document.querySelectorAll(".section4 button.subscribeButton"), { x: -100, opacity: 0 }, { x: 0, opacity: 1 });

    let s5a = gsap.timeline({
      scrollTrigger: {
        trigger: ".section5",
        start: "top center",
        endTrigger: ".section5",
        scrub: true,
        end: "bottom bottom",
        onEnter: () => navLinks[4].classList.add("active"),
        onEnterBack: () => navLinks[4].classList.add("active"),
        onLeave: () => navLinks[4].classList.remove("active"),
        onLeaveBack: () => navLinks[4].classList.remove("active"),
      },
    });

    document.querySelectorAll(".section5 .card").forEach(function (section, i) {
      s5a.fromTo(section, { scale: 0, opacity: 0 }, { scale: 1, opacity: 1 });
    });

    function getSamePageAnchor(link) {
      if (link.protocol !== window.location.protocol || link.host !== window.location.host || link.pathname !== window.location.pathname || link.search !== window.location.search) {
        return false;
      }
      link.classList.add("active");
      return link.hash;
    }

    function scrollToHash(hash, e) {
      const elem = hash ? document.querySelector(hash) : false;
      if (elem) {
        if (e) e.preventDefault();
        gsap.to(window, { scrollTo: elem, overwrite: true });
      }
    }

    navLinks.forEach((a) => {
      a.addEventListener("click", (e) => {
        navLinks.forEach((link) => {
          link.classList.remove("active");
        });

        scrollToHash(getSamePageAnchor(a), e);
      });
    });

    scrollToHash(window.location.hash);
  });
}
