// import axios from "axios";
import _ from "underscore";
import axios from "axios";
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { BASE_URL, authAxios, axiosConfig } from "../../services/client";

let initialState = {
  // shop_items: dummy_shop_items,
  // shop_categories: [{category:'Shirts', sub_category: ["men's", "women's"]}, {category:'headwear', sub_category:['caps', 'beanies']}, {category:'Mugs', sub_category:[]}, {category: 'Phone Cases', sub_category:['iPhone', 'Android']}, {category: 'Towels', sub_category: null}, {category: 'Trinkets', sub_category: null}, {category:'Shoes', sub_category: []}, {category: 'Bad'} ],
  shop_items: [],
  shop_categories: [],
  user_cart: [],
  order_history: [],
  redeemable_codes: [],
  promo_code: null,
  alignment: null,
};

export const shopSlice = createSlice({
  name: "shop",
  initialState,
  reducers: {
    addToCart: (state, action) => {
      state.user_cart.push(action.payload)
    },
    updateItemQuantity: (state, action) => {
      const { idx, quantity } = action.payload;
      let new_cart = state.user_cart;
      new_cart[idx].quantity = quantity;
      state.user_cart = new_cart;
    },
    removeFromCartByIndex: (state, action) => {
      state.user_cart = _.without(state.user_cart, state.user_cart[action.payload]);
    },
    setPromoCode: (state, action) => {
      state.promo_code = action.payload;
    },
    setReduxAlignment: (state, action) => {
      state.alignment = action.payload;
    }
  },
  extraReducers(builder) {
    loadStorefrontCases(builder);
    updateUserCartCases(builder);
    getUserCartCases(builder);
    checkoutCases(builder);
    getOrderHistoryCases(builder);
    getCodeHistoryCases(builder);
    orderGiftCodesCases(builder);
  },
});


// Action creators are generated for each case reducer function
export const { addToCart, removeFromCartByIndex, updateItemQuantity, setPromoCode, setReduxAlignment } = shopSlice.actions;
export const getProductById = (state, productId) => _.find(state.content.shop_items, (p) => p.id == productId);
export default shopSlice.reducer;


export const loadStorefront = createAsyncThunk("shop/loadStorefront", async (rejectWithValue) => {
  try {
    const response = await authAxios.get("shop/storefront", axiosConfig);
    return response.data;
  } catch (e) {
    return rejectWithValue(e.response.data);
  }
});

const loadStorefrontCases = (builder) =>
  builder
    .addCase(loadStorefront.pending, (state) => {
    })
    .addCase(loadStorefront.fulfilled, (state, action) => {
      const parsedPayload = JSON.parse(action.payload);
      state.shop_items = parsedPayload.shop_items;
      state.shop_categories = parsedPayload.shop_categories;
    })
    .addCase(loadStorefront.rejected, (state, action) => {
    });

export const updateUserCart = createAsyncThunk("shop/updateUserCart", async (userCart, rejectWithValue) => {
  try {
    const payload = {
      user_cart: JSON.stringify(userCart),
    }
    const response = await authAxios.post("shop/savecart", payload, axiosConfig);
    return response.data;
  } catch (e) {
    return rejectWithValue(e.response.data);
  }
});

const updateUserCartCases = (builder) =>
  builder
    .addCase(updateUserCart.pending, (state) => {
    })
    .addCase(updateUserCart.fulfilled, (state, action) => {
      const parsedPayload = JSON.parse(action.payload);
    })
    .addCase(updateUserCart.rejected, (state, action) => {
    });

export const getUserCart = createAsyncThunk("shop/getUserCart", async (rejectWithValue) => {
  try {
    const response = await authAxios.get("shop/cart", axiosConfig);
    return response.data;
  } catch (e) {
    return rejectWithValue(e.response.data);
  }
});

const getUserCartCases = (builder) =>
  builder
    .addCase(getUserCart.pending, (state) => {
    })
    .addCase(getUserCart.fulfilled, (state, action) => {
      if(action.payload) {
        const parsedPayload = JSON.parse(action.payload);
        state.user_cart = parsedPayload;
      }
    })
    .addCase(getUserCart.rejected, (state, action) => {
    });

export const checkout = createAsyncThunk("shop/checkout", async (payload, rejectWithValue) => {
  try {
    const response = await authAxios.post("shop/checkout", payload, axiosConfig);
    return response.data;
  } catch (e) {
    return rejectWithValue(e.response.data);
  }
});

const checkoutCases = (builder) =>
  builder
    .addCase(checkout.pending, (state) => {
    })
    .addCase(checkout.fulfilled, (state, action) => {
      
      // console.log('checkout resp', action.payload);
      if(action.payload) {
        // const parsedPayload = JSON.parse(action.payload);

        // console.log('checkout resp', action.payload);
        state.order_history = [{...action.payload },  ...state.order_history]
      }
      state.user_cart = [];

      // Object.assign(state.user_cart, []);
    })
    .addCase(checkout.rejected, (state, action) => {
    });

export const getOrderHistory = createAsyncThunk("shop/orderhistory", async (rejectWithValue) => {
  try {
    const response = await authAxios.get("shop/orderhistory", axiosConfig);
    return response.data;
  } catch (e) {
    return rejectWithValue(e.response.data);
  }
});

const getOrderHistoryCases = (builder) =>
  builder
    .addCase(getOrderHistory.pending, (state) => {
    })
    .addCase(getOrderHistory.fulfilled, (state, action) => {
      if(action.payload) {
        state.order_history = action.payload;
        // console.log(action.payload)
        // const parsedPayload = JSON.parse(action.payload);
      }
    })
    .addCase(getOrderHistory.rejected, (state, action) => {
    });


export const getCodeHistory = createAsyncThunk("shop/codeorders", async (rejectWithValue) => {
  try {
    const response = await authAxios.get("shop/codeorders", axiosConfig);
    return response.data;
  } catch (e) {
    return rejectWithValue(e.response.data);
  }
});

const getCodeHistoryCases = (builder) =>
  builder
    .addCase(getCodeHistory.pending, (state) => {
    })
    .addCase(getCodeHistory.fulfilled, (state, action) => {
      if(action.payload) {
        // console.log(action.payload)
        // console.log(action.payload)
        state.code_history = action.payload.code_history;
        // const parsedPayload = JSON.parse(action.payload);
      }
    })
    .addCase(getCodeHistory.rejected, (state, action) => {
    });


export const orderGiftCodes = createAsyncThunk("shop/orderGiftCodes", async (payload, { rejectWithValue }) => {
  try {
    const response = await authAxios.post("shop/order_codes", payload, axiosConfig);
    return response.data;
  } catch (e) {
    return rejectWithValue(e.response.data);
  }
});

const orderGiftCodesCases = (builder) =>
  builder
    .addCase(orderGiftCodes.pending, (state) => {
    })
    .addCase(orderGiftCodes.fulfilled, (state, action) => {
      
      // console.log('checkout gift code resp', action.payload);
      // if(action.payload) {
      //   // const parsedPayload = JSON.parse(action.payload);

      //   console.log('checkout resp', action.payload);
      //   state.order_history = [{...action.payload },  ...state.order_history]
      // }
      // state.user_cart = [];

      // Object.assign(state.user_cart, []);
    })
    .addCase(orderGiftCodes.rejected, (state, action) => {
    });


export const payForwardOrder = createAsyncThunk("shop/payForwardOrder", async (payload, { rejectWithValue }) => {
  try {
    const response = await axios.post(BASE_URL + `shop/payforward`, payload, axiosConfig);
    return response.data;
  } catch (e) {
    return rejectWithValue(e.response.data)
  }
});