import { StrictMode } from 'react';

import { createRoot } from 'react-dom/client';
import { Provider } from "react-redux";
import { ThemeProvider } from "@emotion/react";
import App from "./App";
import { loadAllContent } from "./redux/slices/content-slice";
import { verifyUser } from "./redux/slices/user-slice";
import { store } from "./redux/store";
import { getLocalStorage, setLocalForageDrivers } from "./services/cache";
import { authAxios } from "./services/client";
import { USER_TOKEN } from "./services/constants/keys";
import theme from "./theme";

// setLocalForageDrivers();



const storedToken = getLocalStorage(USER_TOKEN);
if (storedToken) {  
  store.dispatch(verifyUser(storedToken));
}


const root = createRoot(document.getElementById("root"));

root.render(
  <ThemeProvider theme={theme}>
    <Provider store={store}>
      {/* <StrictMode> */}
        <App />
      {/* </StrictMode> */}
    </Provider>
    </ThemeProvider>
);
