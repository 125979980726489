import React from "react";

// React Hooks
import { useLocation, useNavigate } from "react-router-dom";

// Components
import { Box, IconButton, Tooltip, Typography } from "@mui/material";

// Icons
import LocalOfferOutlinedIcon from "@mui/icons-material/LocalOfferOutlined";
import MovieFilterOutlinedIcon from "@mui/icons-material/MovieFilterOutlined";
import PodcastsIcon from '@mui/icons-material/Podcasts';

// consts
import { SHOP_URL, ITF_URL } from "../services/constants/keys";

export default function Nav() {
  const navigate = useNavigate();
  const { pathname } = useLocation();

  return (
    <Box
      sx={{
        zIndex: 2,
        position: { xs: "static", md: "fixed" },
        display: "flex",
        flexDirection: { xs: "row", md: "column" },
        alignItems: "center",
        gap: "10px",
        width: { xs: "auto", md: "60px" },
        minHeight: { xs: "auto", md: "100vh" },
        paddingTop: { xs: "0", md: "15px" },
        backgroundColor: "#121212",
      }}
      className="stage-navigation">
      <Box sx={{ marginTop: { xs: "0", md: "60px" }, display: { xs: "flex", md: "block" } }}>
        <Tooltip title="Film" placement="right">
          <IconButton aria-label="delete" size="large" color={pathname === "/" ? "primary" : "white"} onClick={() => navigate("/")}>
            <Box display="flex" flexDirection="column" justifyItems="center">
              <MovieFilterOutlinedIcon fontSize="large" color="inherit" />
              <Typography fontSize=".85rem" variant="subtitle1" color="inherit">Film</Typography>
            </Box>
          </IconButton>
        </Tooltip>
        <Tooltip title="Shop" placement="right">
          {/* onClick={() => navigate("/shop")} */}
          <IconButton aria-label="delete" size="large" color={pathname === "/shop" ? "primary" : "white"} href={SHOP_URL} target='_blank'>
            <Box display="flex" flexDirection="column" justifyItems="center">
              <LocalOfferOutlinedIcon fontSize="large" color="inherit" />
              <Typography fontSize=".85rem" variant="subtitle1" color="inherit">Shop</Typography>
            </Box>
          </IconButton>
        </Tooltip>
        <Tooltip title="ITF" placement="right">
          <IconButton aria-label="delete" size="large" color={"white"} href={ITF_URL} target='_blank'>
            <Box display="flex" flexDirection="column" justifyItems="center">
              <PodcastsIcon fontSize="large" color="inherit" />
              <Typography fontSize=".85rem" variant="subtitle1" color="inherit">ITF</Typography>
            </Box>
          </IconButton>
        </Tooltip>
      </Box>
    </Box>
  );
}
