import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams, useNavigate } from "react-router-dom";
import _ from "underscore";

// redux
import { getVideoById, getVideoUrl } from "../redux/slices/content-slice";
import { rehydrateUser, setToken } from "../redux/slices/user-slice";

// Components
import { Box, Button, CardMedia, Container, Typography, TextField, Autocomplete, useMediaQuery, useTheme } from "@mui/material";
import { GoToTop, CustomContainer } from "../components/CustomStyledComponents";
import BuyContentCode from "../layout/Modal/BuyContentCode";

// Icons
import RedeemIcon from '@mui/icons-material/Redeem';
import PodcastsIcon from '@mui/icons-material/Podcasts';

// Layouts
import Header from "../layout/Header";
import { Breadcrumbs } from "../layout/Header/Video";
import Modal from "../layout/Modal";
import Sidebar from "../layout/Sidebar";
import Tiles from "../layout/Tiles";

// constants
import { COUNTRIES, EXPIRED, ITF_URL } from "../services/constants/keys";

import gsap from "gsap";
import Flip from "gsap/Flip";
import ScrollToPlugin from "gsap/ScrollToPlugin";
import ScrollTrigger from "gsap/ScrollTrigger";
import { Stack } from "@mui/system";
gsap.registerPlugin(ScrollToPlugin);
gsap.registerPlugin(ScrollTrigger);
gsap.registerPlugin(Flip);

export default function FeaturedFilms() {
  const { videoId } = useParams();
  const selectedVideo = useSelector((state) => getVideoById(state, videoId));
  const user_subs = useSelector((state) => state.user.subscriptions);
  const user_rentals = useSelector((state) => state.user.rentals);
  const descDefault =
    "Incididunt minim eu esse elit pariatur non enim culpa magna ullamco. Non reprehenderit qui aliquip cillum ex irure laboris pariatur officia eiusmod. Officia laboris qui amet nisi consequat. Ut eiusmod excepteur magna eu ipsum ex officia elit id eiusmod mollit. Fugiat voluptate officia labore do qui deserunt culpa magna sit exercitation minim qui sunt mollit.";

  const sectionRef = useRef();
  const [unlockState, setUnlockState] = useState(false);
  const handleUnlockOpen = () => setUnlockState(true);
  const handleUnlockClose = () => setUnlockState(false);

  const [settingsState, setSettingsState] = useState(false);
  const handleSettingsOpen = () => setSettingsState(true);
  const handleSettingsClose = () => setSettingsState(false);


  const allVideos = useSelector((state) => state.content.all_videos);
  const vidUrl = useSelector((state) => state.content.selected_video.vid_url);
  const [selectedVid, setSelectedVid] = useState(allVideos.length > 0 ? allVideos[0].id : null);
  const [videoLanguage, setVideoLanguage] = useState(null);
  const dispatch = useDispatch();
  const [isAvaiable, setIsAvaiable] = useState(false);

  const theme = useTheme();
  const bp = useMediaQuery(theme.breakpoints.up("md"));

  useEffect(() => {
    if (selectedVideo) {
      const is_subscribed = _.contains(user_subs, selectedVideo.id) || _.contains(user_rentals, selectedVideo.id);
      if (selectedVideo.is_free || is_subscribed) {
        setIsAvaiable(true);
      }
    }
  }, [selectedVideo]);

  useEffect(() => {
    if (videoId && isAvaiable) {
      let watch_type = 'free';
      if (_.contains(user_subs, selectedVideo.id)) {
        watch_type = 'sub';
      } else if(_.contains(user_rentals, selectedVideo.id)) {
        watch_type = 'rent';
      }

      const payload = {
        vid_id: videoId,
        watch_type: watch_type,
        vid_language: videoLanguage,
      }
      dispatch(getVideoUrl(payload));
    }
  }, [videoId, isAvaiable, videoLanguage]);

  // TODO: add component if random video is typed in that is not within video array, when selectedVideo is null
  return (
    <>
      {unlockState && <Modal type="unlock" handleClose={handleUnlockClose} open={unlockState} vidId={selectedVid} />}
      {settingsState && <Modal type="settings" handleClose={handleSettingsClose} open={handleSettingsOpen} />}
      {/* {selectedVideo ? ( */}
        <React.Fragment>
          {bp ? <Sidebar /> : null}
          <Header type="video" video={selectedVideo} handleOpen={handleSettingsOpen}/>
          <Box sx={{ height: "100vh", width: { xs: "100vw", md: "calc(100vw - 60px)" }, marginLeft: { xs: "0", md: "60px" } }}>
            {/* <Box className="mainSection" ref={sectionRef} sx={{ height: "inherit", backgroundImage: `url(${selectedVideo.thumbnail_url})`, backgroundSize: "cover" }}> */}
            <Box className="mainSection" ref={sectionRef} sx={{ height: "inherit", backgroundColor: "#121212" }}>
              {selectedVideo ? <MainSection video={selectedVideo} bp={bp} title={selectedVideo.title} vidUrl={vidUrl} desc={selectedVideo.desc ? selectedVideo.desc : descDefault} time={selectedVideo.vid_length} setVideoLanguage={setVideoLanguage} /> : <VideoNotFound />}
              {/* {vidUrl === EXPIRED &&  <VideoExpired />}
              {selectedVideo && vidUrl !== EXPIRED && <MainSection video={selectedVideo} bp={bp} title={selectedVideo.title} vidUrl={vidUrl} desc={selectedVideo.desc ? selectedVideo.desc : descDefault} time={selectedVideo.vid_length} />}
              {!selectedVideo && <VideoNotFound />} */}
              
              <Tiles setSelectedVid={setSelectedVid} videos={allVideos} handleUnlockOpen={handleUnlockOpen} />
            </Box>
          </Box>
          <GoToTop />
        </React.Fragment>
      {/* ) : null} */}
    </>
  );
}

const VideoNotAvailable = ({ video }) => {
  const [isAvaiable, setIsAvaiable] = useState(true);
  if (video.release_date) {
    const d = new Date().toISOString();
    console.log(d);
    console.log(video.release_date);
    if (d < video.release_date) {
      setIsAvaiable(false);
      console.log('Not available')
    } else {
      console.log('film available')
    }
    // console.log(new Date(item.release_date))
  }
  if (!isAvaiable) {
    return (
      <>
        <Box width={1} height={.8} display='flex' alignItems='center' justifyContent='center'>
          <Typography variant="h1">Film available {video.release_date}!</Typography>
          <Typography variant="h1">Thank you for your support!</Typography>
        </Box>
        <Typography variant="h3" mb={4}>
          Other titles
        </Typography>
      </>
    )
  }
  
}

const VideoExpired = () => {
  const dispatch = useDispatch();
  const [newToken, setNewToken] = useState(null);

  useEffect(() => {
    updateToken();
  },[])

  useEffect(() => {
    if (newToken) {
      dispatch(setToken(newToken));
    }
  }, [newToken])
  
  const updateToken = async() => {
    try{
      const newToken = await dispatch(rehydrateUser()).unwrap();
      
      console.log('new token', newToken);
      if (newToken) {
        setNewToken(newToken);
      }
    } catch (exception) {

    }
  }
  
  return (
    <>
      <Box width={1} height={.8} display='flex' alignItems='center' justifyContent='center'>
        <Typography variant="h2" textAlign="center">Your rental has expired</Typography>
      </Box>
      <Typography variant="h3" mb={4}>
        Other titles
      </Typography>
    </>
  )
}

const VideoNotFound = () => {
  
  return (
    <>
    <Box width={1} height={.8} display='flex' alignItems='center' justifyContent='center'>
      <Typography variant="h2">Nothing Here</Typography>
    </Box>
    <Typography variant="h3" mb={4}>
      Other titles
    </Typography>
    </>
  )
}
function MainSection(props) {
  const { title, desc, time, vidUrl, bp, video, setVideoLanguage } = props;
  if (video.release_date) {
    //  ITL 6PM CST UTC RELEASE STAMP
    // 2023-08-04 23:00:00.000
    const d = new Date().toISOString();
    if (d < video.release_date) {
      return (
        <>
          <Box width={1} height={.8} display='flex' alignItems='center' justifyContent='center' flexDirection='column'>
          <Typography variant="h3" mb={1}>Film available</Typography>
            <Typography variant="h3">{new Date(video.release_date+'Z').toLocaleString()}!</Typography>
            <Typography variant="h3" mt={1}>Thank you for your support!</Typography>
          </Box>
          <Typography variant="h3" mb={4}>
            Other titles
          </Typography>
        </>
      )
    }
  }

  return <VideoContainer title={title} bp={bp} video={video} setVideoLanguage={setVideoLanguage} />;
}

MainSection.defaultProps = {
  title: "",
  time: "",
  desc: "",
};

// xs, extra-small: 0px
// sm, small: 600px
// md, medium: 900px
// lg, large: 1200px
// xl, extra-large: 1536px

const VideoContainer = ({ title, bp, video, setVideoLanguage }) => {
  const navigate = useNavigate();
  const [isBuyModalOpen, setIsBuyModalOpen] = useState(false);
  const { is_free, price } = video;
  // console.log('vid', video)
  const languages = useSelector((state) => state.content.selected_video.languages);

  const descDefault =
    "Incididunt minim eu esse elit pariatur non enim culpa magna ullamco. Non reprehenderit qui aliquip cillum ex irure laboris pariatur officia eiusmod. Officia laboris qui amet nisi consequat. ";
  useEffect(() => {
    /*
    let tl = gsap.timeline();
    let button = document.querySelector(".stage-button");
    let info = document.querySelector(".stage-info");
    let header = document.querySelector(".stage-header");
    let nav = document.querySelector(".stage-navigation");
    let h = 0;
    let n = 0;
    let video = document.querySelector("video");
    function playVideo(h, n) {
      video.play();
      calcDistances(h, n);
      tl.play();
      tl.to(nav, { opacity: 0, left: -n, duration: 0.3 });
      tl.to(header, { opacity: 0, top: -h, duration: 0.3 }, 0);
      tl.to(video, { opacity: 1, duration: 0.3 }, 0);
      tl.to(info, { opacity: 0, duration: 0.3 }, 0);
      info.style.display = "none";
      video.style.zIndex = 10000;
    }
    function calcDistances(h, n) {
      h = header.offsetHeight;
      n = nav.offsetWidth;
    }
    // On Button click show video
    button.addEventListener("click", function () {
      playVideo(h, n);
      console.log("trying to play");
    });
    // On video pause show info
    video.addEventListener("click", function (e) {
      e.preventDefault();
      video.pause();
      tl.reverse();
      info.style.display = "block";
      video.style.zIndex = 0;
      console.log("trying to pause");
    });
    */
  }, []);

  
  return (
    <Box sx={{ position: "relative", pt: 5 }}>
      {isBuyModalOpen && <BuyContentCode open={isBuyModalOpen} video={video} handleUnlockClose={() =>setIsBuyModalOpen(false)} /> }
      <Box
        sx={{ width: "100%", height: {xs: "70vh", sm: "70vh", md: "90vh", lg: "90vh", xl: "90vh"}, background: "linear-gradient(90deg, rgba(0,0,0,.6) 0%, rgba(0,0,0,0) 50%, rgba(0,0,0,0) 100%)" }}
        display="flex"
        justifyContent="center"
        alignItems="center">
        <Container maxWidth="false">
          {!bp ? <Breadcrumbs video={video} /> : null}
          <Box className="videoContainer" >
            <Box className="videoWrapper" sx={{ textAlign: "center", backgroundColor: "#000000" }}>
              {/* <video id="film-video" controls muted /> */}
              <VideoPlayer setVideoLanguage={setVideoLanguage} />
            </Box>
          </Box>
        </Container>
      </Box>
      <Box
        display="flex"
        alignItems="center"
        sx={{
          // position: "absolute",
          // left: "0",
          // right: "0",
          // bottom: "0",
          zIndex: "1",
          width: "100%",
          // height: {xs: "77px", sm: "50px", md: "100px"},
          // background: "linear-gradient(180deg, rgba(0,0,0,.7) 0, rgba(0,0,0,.3) 10%, rgba(0,0,0,1) 100%)",
          background: "#121212",
          padding: { xs: "0", sm: "0", md: "0 50px" },
        }}>
        <Container maxWidth="false">
          <Stack spacing={2} mb={3}>
            
            {/* <Typography variant="body1">Contine the fight with our webseries Into The Fight with Mike Smith and Brian Gamble</Typography>
            <Typography variant="body1">✅ Engaging Conversations: Dive into captivating discussions led by actual industry experts.</Typography>
            <Typography variant="body1">✅ Interactive Q&A: Get answers to your questions LIVE!</Typography>
            <Typography variant="body1">✅ Networking Opportunities: Connect with like-minded individuals and expand your professional circle.</Typography> */}
            {!is_free && 
            <Box>
              <Typography variant="h5">Support Our Mission!</Typography>
              <Typography variant="body1"  textAlign="left" mt={1}>Gift It Forward is an alternative purchase solution that allows viewers from around the world to enjoy the film and helps us fund our projects, server and bandwidth costs!</Typography>
              <Button variant="contained" size="large" sx={{width: 350, borderRadius: 4, my: 2}} onClick={() => navigate("/purchase")}>
                <Box display='flex' alignContent='center'><RedeemIcon sx={{mr: 1}}/>Gift Forward!</Box>
              </Button>
            </Box>
            }
            
            <Stack spacing={1} mb={1}>
              <Typography variant="h5">How To Continue The Fight</Typography>
              <Typography variant="body1">Continue the fight with our webseries "Into The Fight" with Mike Smith and Brian Gamble. Listen below!</Typography>
              <Box><Button variant="contained"  sx={{ width: 350, borderRadius: 4 }} href={ITF_URL} target='_blank'><Box display='flex' alignItems='center'><Typography variant="h6" color="black">Into The Fight</Typography><PodcastsIcon sx={{ml: .25}} fontSize='small'/></Box></Button></Box>
            </Stack>
          </Stack>
          <Typography variant="h3" mb={4}>
            Similar titles to {title}
          </Typography>
        </Container>
      </Box>
    </Box>
  );
};
/*
const VideoContainerCustom = ({ title, time, desc }) => {
  return (
    <CustomContainer maxWidth="xl">
      <Fade in timeout={800}>
        <Stack direction="column" mx={1}>
          <Box width={1} alignSelf="end" sx={{ justifySelf: "flex-end", alignSelf: "flex-end" }}>
            <video id="film-video" src={videoTrailer} controls muted />
          </Box>
          <Box sx={{ display: "flex", gap: "15px", mt: 8 }}>
            <Typography component="span" variant="subtitle2">
              Runtime
            </Typography>
            <Typography component="span" variant="subtitle2" className="film-runtime">
              {time}
            </Typography>
          </Box>
          <Box sx={{ display: "flex", gap: "15px", mt: 0 }}>
            <Typography component="h1" variant="h2" className="film-title" mt={2} mb={4}>
              {title}
            </Typography>
          </Box>
          <Box>
            <Typography component="div" variant="body1" maxWidth="md" className="film-description" mb={2}>
              {desc}
            </Typography>
          </Box>
        </Stack>
      </Fade>
    </CustomContainer>
  );
};
*/

const VideoPlayer = ({ setVideoLanguage }) => {
  // style="border: none; position: absolute; top: 0; height: 100%; width: 100%;"
  const vidUrl = useSelector((state) => state.content.selected_video.vid_url);
  const languages = useSelector((state) => state.content.selected_video.languages);
  // TODO: return 404 if video not in all videos
  // const allVideos = useSelector((state) => state.content.all_videos);
  // _.find(allVideos, vid => )
  // console.log('vid comp url', vidUrl)
  // if (vidUrl === EXPIRED) {
  //   return (
  //     <VideoExpired />
  //   )
  // }
  // if (vidUrl && vidUrl !== EXPIRED) {
  if (vidUrl) {
    return (
      <>
      <CardMedia
        component="iframe"
        sx={{ height: { xs: "50vh", md: "80vh"}, border: "none" }}
        // sx={{ height: "80vh", width: "100vw", width: "92vw", }}
        src={`${vidUrl}&autoplay=true`}
        allow="accelerometer; gyroscope; autoplay; encrypted-media; picture-in-picture;"
        allowFullScreen={true}
      />
      {/* <Box>Language</Box> */}
      <LanguageDropdown languages={languages} setVideoLanguage={setVideoLanguage} />
      </>
    );
  } else {
    return <></>;
  }
};

// <Box sx={{width: '100%', height: "100%" }}>
//   <iframe src="https://iframe.mediadelivery.net/embed/82994/77bfb1b3-51c2-4458-99ae-2927e1afb71e?autoplay=false" loading="lazy"  allow="accelerometer; gyroscope; autoplay; encrypted-media; picture-in-picture;" allowFullScreen={true}></iframe>
// </Box>


{/* <CustomContainer>
  <Box display="flex" flexDirection="row" justifyContent="space-between">
    <Box><Typography variant="body1">{title}</Typography></Box>
    <Box><Typography variant="body1">101,170,856</Typography></Box>
  </Box>
  <Box><Typography variant="body1">{descDefault}</Typography></Box>
</CustomContainer> */}



const LanguageDropdown = ({ languages, setVideoLanguage }) => {
  return (
    languages.length > 0 ? <Autocomplete
      sx={{ width: 250, mt: 2, textTransform: 'capitalize', float: { xs: "right", sm: "right", md: "left" } }}
      options={languages}
      autoHighlight
      getOptionLabel={(option) => option}
      defaultValue={languages[0]}
      // onChange={(e) => console.log(e.value)}
      onInputChange={(e,val) => setVideoLanguage(val)}
      renderOption={(props, option) => (
        <Box component="li" sx={{ '& > img': { mr: .5, flexShrink: 0 }, textTransform: 'capitalize' }} {...props}>
          {/* <img
            loading="lazy"
            width="20"
            // srcSet={`https://flagcdn.com/w40/${option.code.toLowerCase()}.png 2x`}
            // src={`https://flagcdn.com/w20/${option.code.toLowerCase()}.png`}
            alt={option}
          /> */}
          {/* {option.label} ({option.code}) +{option.phone} */}
          
          <Typography textTransform='capitalize'>{option}</Typography>
        </Box>
      )}
      renderInput={(params) => (
        <TextField
          {...params}
          label="Select Language"
          inputProps={{
            sx: {textTransform: 'capitalize'},
            ...params.inputProps,
          }}
        />
      )}
    /> : null
  );
}