import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

// Components
import { Box, Button, CssBaseline, Fade, Modal, Paper, Stack, Tab, Tabs, Tooltip } from "@mui/material";

import { PasswordField, PasswordRequirements } from "../../components/CustomStyledComponents";

// Styling
import { alpha, createTheme, ThemeProvider } from "@mui/material/styles";

// Redux
import { loginUser, resetPassword } from "../../redux/slices/user-slice";

// Services
import { isNullOrEmpty, checkPassword } from "../../services/utilities";

const modalStyle = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: alpha("#121212", 0.93),
  boxShadow: 5,
  p: 4,
};

const light = {
  palette: {
    mode: "light",
  },
};

export default function SettingsModal({ handleSettingsClose, open }) {
  return (
    <>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={open}
        onClose={handleSettingsClose}
        closeAfterTransition
        slotProps={{
          backdrop: { timeout: 300 },
        }}>
        <Fade in={open}>
          <Box sx={modalStyle}>
            <SettingsTab />
          </Box>
        </Fade>
      </Modal>
    </>
  );
}
/*
const BackdropImg = (props) => (
  <Box sx={{ background: `url(${imageHero})`, backgroundRepeat: "no-repeat", backgroundSize: "100% 100%", width: "100%", height: "100%" }}>
    <Backdrop {...props} />
  </Box>
);
*/
const Settings = () => {
  const dispatch = useDispatch();
  const [currentPassword, setCurrentPassword] = useState(null);
  const [newPassword, setNewPassword] = useState(null);
  const [isPwVisible, setIsPwVisible] = useState(false);
  const [isDisabled, setDisabled] = useState(true);

  useEffect(() => {
    if (isNullOrEmpty(currentPassword) || !checkPassword(newPassword)) {
      setDisabled(true);
    } else {
      setDisabled(false);
    }
  }, [currentPassword, newPassword]);

  const resetPasswordClick = async () => {
    const payload = {
      current_password: currentPassword,
      new_password: newPassword
    };
    try {
      await dispatch(resetPassword(payload)).unwrap();
    } catch (exception) {

    }
  };
  return (
    <React.Fragment>
      <ThemeProvider theme={createTheme(light)}>
        <CssBaseline />
        <Paper elevation={3} sx={{ p: 4 }}>
          <Stack spacing={2} component="form">
            <PasswordField required label="Current password" autoComplete="current-password" variant="standard" fullWidth sx={{ mb: "1rem" }} onChange={(e) => setCurrentPassword(e.target.value)} />
            <Tooltip disableHoverListener open={isPwVisible} placement="top" title={<PasswordRequirements value={newPassword} />} >
              <Box>
                <PasswordField required label="New password" autoComplete="new-password" variant="standard" onChange={(e) => setNewPassword(e.target.value)} onKeyPress={(e) => (e.code === "Enter" ? resetPassword() : null)} onFocus={()=>setIsPwVisible(true)} onBlur={() => setIsPwVisible(false)}/>
              </Box>
            </Tooltip>
          </Stack>
        </Paper>
      </ThemeProvider>
      <Button variant="contained" size="large" disabled={isDisabled} sx={{ mt: 3, width: "100%" }} onClick={resetPasswordClick}>
        Save Settings
      </Button>
    </React.Fragment>
  );
};

const TabPanel = (props) => {
  const { children, value, index, ...other } = props;

  return (
    <div role="tabpanel" hidden={value !== index} {...other}>
      {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
    </div>
  );
};

const SettingsTab = () => {
  const [value, setValue] = useState(0);

  const handleChange = (e, newValue) => {
    setValue(newValue);
  };

  return (
    <React.Fragment>
      <Box sx={{ borderBottom: 1, borderColor: "divider", display: "flex", justifyContent: "center" }}>
        <Tabs value={value} onChange={handleChange} aria-label="password reset tab" variant="fullWidth">
          <Tab label="Password Reset" sx={{ fontSize: "1.25rem" }} />
        </Tabs>
      </Box>
      <TabPanel value={value} index={0}>
        <Fade in>
          <Box>
            <Settings />
          </Box>
        </Fade>
      </TabPanel>
    </React.Fragment>
  );
};
