import React, { useEffect } from "react";

// Components
import Router from "./Router";
import PublicRouter from "./PublicRouter";

// Redux
import { useSelector, useDispatch } from "react-redux";
import { loadAllContent } from "./redux/slices/content-slice";
import { getOrderHistory, getUserCart, loadStorefront, getCodeHistory } from "./redux/slices/shop-slice";
import { getUserRentals } from "./redux/slices/user-slice";

// Styles
import "./App.css";

export default function App() {
  const dispatch = useDispatch();
  const { is_verified, token} = useSelector((state) => state.user);
  
  useEffect(()=> {
    if(is_verified && token ) {
      // FIX: if content isnt empty
      dispatch(loadAllContent());
      // dispatch(loadStorefront());
      // dispatch(getUserCart());
      // dispatch(getOrderHistory());
      // dispatch(getUserRentals());
      // dispatch(getCodeHistory());
    }
  }, [is_verified, token]);
  

  return (
    <>
      {is_verified ? <Router /> : <PublicRouter />}
    </>
  );
}
