import React, { useState, useEffect } from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import _ from "underscore";

import Shop from "./ui/Shop";
import Checkout from "./ui/CheckOut";
import OrderHistory from "./ui/OrderHistory";
import Video from "./ui/Video";
import Videos from "./ui/Videos";
import Redeem from "./ui/Redeem";
import About from "./ui/About";
import PurchasePage_orig from "./ui/Purchase_orig";
import PurchasePage from "./ui/Purchase";
import BookScreeningPage from "./ui/BookScreening";
import MediaRequestPage from "./ui/MediaRequest";
import PolicyPage from "./ui/Policies";
import Bio from "./ui/Bio";
import { updateUserCart } from "./redux/slices/shop-slice";

export default function Router() {
    // 60 * 1000 milsec
  // const dispatch = useDispatch();
  // const user_cart =  useSelector(state => state.shop.user_cart);
  // const [cart, setCart] = useState(user_cart);

  // updateUserCart
  // useEffect(() => {
  //   const isEqual = _.isEqual(cart, user_cart);
    
  //   const interval = setInterval(() => {
  //     console.log('isequal', isEqual);
  //     if (!isEqual && user_cart.length > 0) {
  //       setCart(user_cart);
  //       dispatch(updateUserCart(user_cart));
  //     }
  //   }, 10000);
  //   return () => {
  //     clearInterval(interval);
  //   };
  // }, [user_cart, cart]);
  
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Videos />}></Route>
        <Route path="/book-screening" element={<BookScreeningPage />}></Route>
        <Route path="/media-request" element={<MediaRequestPage />}></Route>
        <Route path="/watch/:videoId" element={<Video />}></Route>
        {/* <Route path="/shop" element={<Shop />}></Route> */}
        {/* <Route path="/checkout" element={<Checkout />}></Route> */}
        <Route path="/user/orders" element={<OrderHistory />}></Route>
        <Route path="/redeem" element={<Redeem />}></Route>
        <Route path="/redeem/:code" element={<Redeem />}></Route>
        <Route path="/about" element={<About />}></Route>
        <Route path="/policies" element={<PolicyPage />}></Route>
        {/* <Route path="/purchase" element={<PurchasePage />}></Route> */}
        <Route path="/purchase" element={<PurchasePage />}></Route>
        <Route path="/bio" element={<Bio />}></Route>
        <Route path="/bio/:bio" element={<Bio />}></Route>
        <Route path="/:promocode" element={<PurchasePage />}></Route>
        <Route path="*" element={<Videos />}></Route>
      </Routes>
    </BrowserRouter>
  );
}
