import localForage from 'localforage';

// Using setDriver()
export const setLocalForageDrivers = () => (
  localForage.setDriver([
    localForage.INDEXEDDB,
    localForage.WEBSQL,
]));

export const setCacheItem = (key, value) => (
    localForage.setItem(key, value)
)

export const getCacheItem = (key) => {
    return localForage.getItem(key);
}

export const removetCacheItem = (key) => {
    return localForage.removeItem(key);
}

export const getLocalStorage = (key) => (
    localStorage.getItem(key)
);

export const setLocalStorage = (key, value) => (
    localStorage.setItem(key, value)
);

export const removeLocalStorage = (key) => (
    localStorage.removeItem(key)
);
