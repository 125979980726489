import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useParams, useNavigate } from "react-router-dom";

import { Box, CardMedia, Paper, Stack, Typography, TextField, Button, Container, Tab, Tabs, Fade, FormControlLabel, Checkbox, Tooltip, Step, StepLabel, Stepper, StepIcon, StepButton } from "@mui/material";
import Header from "../layout/Header";
import Modal from "../layout/Modal";
import { PasswordField, PasswordRequirements, FeedBack } from "../components/CustomStyledComponents";

//Redux
import { redeemUnverified, setToken, redeemSubCode } from "../redux/slices/user-slice";

import { isNullOrEmpty, isEmail, checkPassword } from "../services/utilities";
import { setLocalStorage } from "../services/cache";
import { USER_TOKEN } from "../services/constants/keys";

import { imageHero } from "../services/constants/dummyData";
import { j_poster } from "../services/constants/dummyData";

import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';


const steps = ["Enter Code Below", "Review"];
const SIGN_IN = "sign_in";
const REGISTER = "register";

const itl_thumbnail = 'https://itl-assets.b-cdn.net/thumbnails/1920x1080_grey_oswald.jpeg';

export default function Redeem() {
  const { is_verified } = useSelector((state) => state.user);
  const [settingsState, setSettingsState] = useState(false);
  const [loginState, setLoginState] = useState(false);
  const handleSettingsOpen = () => setSettingsState(true);
  const handleSettingsClose = () => setSettingsState(false);
  const handleLoginOpen =() => setLoginState(true);
  const handleLoginClose = () => setLoginState(false);
  return (
    <Box sx={{ backgroundImage: `url(${imageHero})`, backgroundSize: "cover", backgroundRepeat: "no-repeat", backgroundPosition: "center", width: "100%", height: "100%", position: "fixed", overflowY: "scroll" }}>
      {is_verified ? <Header type="videos" handleOpen={handleSettingsOpen} /> : <Header type="landing" handleOpen={handleLoginOpen} />}
      {settingsState && <Modal type="settings" handleClose={handleSettingsClose} open={settingsState} />}
      {loginState && <Modal type="login" handleClose={handleLoginClose} open={loginState} />}
      <Container sx={{mt: 4 }} maxWidth="sm" >
        <Paper>
          {is_verified ? <VerifiedForm /> : <UnverifiedForm />}
        </Paper>
      </Container>
    </Box>
  );
}

const VerifiedForm = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { code } = useParams();
  const [resp , setResp] = useState();
  const [usrCode , setUsrCode] = useState(code);
  const [isDisabled, setIsDisabled] = useState(true);
  const [feedBack, setFeedback] = useState({passed: null, comp: null});
  const [btn, setBtn] = useState(0);

  const redeemClick = async () => {
    try {
      setFeedback({passed: null, comp: null})
      setIsDisabled(true);
      const token = await dispatch(redeemSubCode(usrCode)).unwrap();
      setFeedback({passed: true, comp: <FeedBack passed={true} message={"Success!"} mt={1} />});
      setResp(token);
      setBtn(1);
    } catch (exception) {
      setIsDisabled(false);
      const { detail } = exception;
      if (detail === "Not Acceptable") {
        setFeedback({passed: false, comp: <FeedBack passed={false} message={"Code unavailable!"} mt={1} />});
      }
    }
  }

  const finishClick = async () => {
    try {
      dispatch(setToken(resp));
      navigate("/");
    } catch (exception) {

    }
  }

  return(
    <Stack p={4} spacing={3} alignItems="center">
      <Box>
        <Typography
          variant="h4"
          noWrap
          sx={{
            fontWeight: 700,
            letterSpacing: ".3rem",
            textDecoration: "none",
            textTransform: "uppercase",
            flexGrow: 1,
            fontSize: '5rem'
          }}>
          Into the Light
        </Typography>
      </Box>
        <><Box maxWidth="500px">
          <CardMedia component="img" image={itl_thumbnail}/>
        </Box>
        <Box><Typography variant="body1" sx={{ fontFamily: "Lora", fontSize: "1.2rem", textTransform: "none", lineHeight: "2" }}>Thank you for supporting the sequel to Out of Shadows! Redeem your code and add "Into The Light" to your account!</Typography></Box>
        </>
      <TextField required fullWidth value={usrCode ? usrCode : ""} placeholder="enter code in here" variant="standard"  sx={{ mb: "1rem", fontFamily: "Lora" }} onChange={(e) => setUsrCode(e.target.value)} />
      {btn === 0 && <Box><Button size="large" disabled={usrCode || !isDisabled ? false : true} sx={{ position: "relative", borderRadius: "35px", padding: "8px 30px" }}variant="contained" onClick={redeemClick}>Redeem Code!</Button></Box>}
      {btn === 1 && <Box><Button size="large" sx={{ position: "relative", borderRadius: "35px", padding: "8px 30px" }} variant="contained" onClick={finishClick}>Watch now!</Button></Box>}
      {feedBack.passed !== null && feedBack.comp}
    </Stack>
  );
}

const UnverifiedForm = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { code } = useParams();
  const [resp , setResp] = useState();
  const [usrCode , setUsrCode] = useState(code);
  const [activeStep, setActiveStep] = useState(0);
  const [dataPayload, setDataPayload] = useState();
  const [isDisabled, setIsDisabled] = useState(true);
  const [feedBack, setFeedback] = useState({passed: null, comp: null});
  const [btnName, setBtnName] = useState('Login & Redeem!');

  const submitPayload = async () => {
    try {
      setFeedback({passed: null, comp: null})
      setIsDisabled(true);
      const token = await dispatch(redeemUnverified({...dataPayload, code: usrCode})).unwrap();
      setResp(token);
      stepForward();
    } catch (exception) {
      setIsDisabled(false);
      const { type } = dataPayload;
      const { detail } = exception;

      if (detail === "Not Acceptable") {
        setFeedback({passed: false, comp: <FeedBack passed={false} message={"Code unavailable!"} mt={1} />});
      } else if(detail === 'Conflict') {
        setFeedback({passed: false, comp: <FeedBack passed={false} message={"Content already owned!"} mt={1} />});
      }
      else {
        if (type === SIGN_IN) {
          setFeedback({passed: false, comp: <FeedBack passed={false} message="Sign-in failed. Please try again" mt={1} />});
        }

        if (type === REGISTER) {
          let msg = "Registeration failed. Please try again";
          if (detail == "email already registered") {
            msg = "Email already registered, please sign in";
          }
          setFeedback({passed: false, comp: <FeedBack passed={false} message={msg} mt={1} />});
        }
      }
    }
  }

  const finishClick = () => {
    const { remember_me } = dataPayload;
    if (remember_me) {
      setLocalStorage(USER_TOKEN, resp);
    }
    dispatch(setToken(resp));
    navigate("/");
  }

  const stepForward = () => {
    setActiveStep(activeStep + 1);
  };

  const GetButtonForStep = () => {
    switch(activeStep) {
      case 0:
        return (
          <Button size="large" disabled={usrCode ? false : true} sx={{ position: "relative", borderRadius: "35px", padding: "8px 30px" }} variant="contained" onClick={stepForward}>Proceed</Button>
        );
      case 1:
        return (
          <Button size="large" disabled={isDisabled || isNullOrEmpty(usrCode)} variant="contained" sx={{ position: "relative", borderRadius: "35px", padding: "8px 30px" }} onClick={submitPayload}>{btnName}</Button>
        );
      default:
        return <Button size="large" variant="contained" sx={{ position: "relative", borderRadius: "35px", padding: "8px 30px" }} onClick={finishClick} >Watch now!</Button>;
    }
  };

  return (
    <Stack p={4} spacing={3} alignItems="center">
      <Box>
        <Typography
          variant="h4"
          noWrap
          sx={{
            fontWeight: 700,
            letterSpacing: ".3rem",
            textDecoration: "none",
            textTransform: "uppercase",
            flexGrow: 1,
            fontSize: '5rem'
          }}>
          Into the Light
        </Typography>
      </Box>
      <Stepper activeStep={activeStep} sx={{ pt: 3, pb: { xs: 2, md: 5 } }}>
        {steps.map((label, idx) => (
          <Step key={label} sx={{mr: idx === 0 ? 5 : 0}}>
            {activeStep === 0 && idx === 0? 
              <StepButton icon={<ArrowDownwardIcon />}>
                <StepLabel sx={{ fontSize: ".85rem" }}>{label}</StepLabel>
              </StepButton> :
              <StepLabel sx={{ fontSize: ".85rem" }}>{label}</StepLabel>
            }
          </Step> 
        ))}
      </Stepper>
      {activeStep === steps.length ? null : <TextField required fullWidth value={usrCode ? usrCode : ""} placeholder="enter code in here" variant="standard"  sx={{ mb: "1rem", fontFamily: "Lora" }} onChange={(e) => setUsrCode(e.target.value)} />}
      <StepContent step={activeStep} setPayload={setDataPayload} setDisable={setIsDisabled} setBtnName={setBtnName} />
      <GetButtonForStep />
      {feedBack.passed !== null && feedBack.comp}
    </Stack>
  );
}

const StepContent = ({ step, setPayload, setDisable, setBtnName }) => {
  switch (step) {
    case 0:
      return (
        <>
          <Box maxWidth="500px">
            <CardMedia component="img" image={itl_thumbnail} />
          </Box>
          <Box>
            <Typography variant="body1" sx={{ fontFamily: "Lora", fontSize: "1.2rem", textTransform: "none", lineHeight: "2" }}> Thank you for supporting the sequel to Out of Shadows! Redeem your code and add "Into The Light" to your account!</Typography>
          </Box>
        </>);
    case 1:
      return <UnverifiedFormTabs setPayload={setPayload} setDisable={setDisable} setBtnName={setBtnName} />;
    default:
      return (
        <>
          <Box maxWidth="500px">
              <CardMedia component="img" image={itl_thumbnail} />
            </Box>
          <Box>
            <Typography variant="body1" sx={{ fontFamily: "Lora", fontSize: "1.2rem", textTransform: "none", lineHeight: "2" }}> Thank you for supporting the official release! Enjoy the movie!</Typography>
          </Box>
        </>);
  }
}

const TabPanel = (props) => {
  const { children, value, index, ...other } = props;

  return (
    <div role="tabpanel" hidden={value !== index} {...other}>
      {value === index && <Box sx={{ }}>{children}</Box>}
    </div>
  );
};

const UnverifiedFormTabs = ({setPayload, setDisable, setBtnName}) => {
  const [value, setValue] = useState(0);
  const handleChange = (e, newValue) => {
    setValue(newValue);
  };


  useEffect(() => {
    if (value === 0) {
      setBtnName("Join & Redeem!");
    } else if (value === 1) {
      setBtnName("Login & Redeem!");
    }
  }, [value, setBtnName]);


  return (
    <Box sx={{ width: "100%" }}>
      <Box my={1}><Typography variant="body1" sx={{ fontFamily: "Lora", fontSize: "1.3rem", textTransform: "none", lineHeight: "2" }}>Please sign in or register to redeem your code!</Typography></Box>
      <Box sx={{ borderBottom: 1, borderColor: "divider", mb: 2, mt: 4 }}>
        <Tabs value={value} onChange={handleChange} variant="fullWidth">
          <Tab label="Register" sx={{ fontSize: "1.25rem" }} />
          <Tab label="Sign In" sx={{ fontSize: "1.25rem" }} />
        </Tabs>
      </Box>
      <TabPanel value={value} index={0}>
        <Fade in>
          <Box>
            <SignUpTab setPayload={setPayload} setDisable={setDisable}/>
          </Box>
        </Fade>
      </TabPanel>
      <TabPanel value={value} index={1}>
        <Fade in>
          <Box>
            <SignInTab setPayload={setPayload} setDisable={setDisable} />
          </Box>
        </Fade>
      </TabPanel>
    </Box>
  );
}

const SignInTab = ({ setPayload, setDisable }) => {
  const [password, setPassword] = useState(null);
  const [email, setEmail] = useState(null);
  const [rememberMe, setRememberMe] = useState(true);

  useEffect(() => {
    if (email && isEmail(email) && password) {
      const payload = {
        email: email,
        password: password,
        type: SIGN_IN,
        remember_me: rememberMe
      };
      setPayload(payload);
      setDisable(false);
    } else {
      setDisable(true);
    }
  }, [email,password, rememberMe, setDisable, setPayload]);

  return (
    <>
    <Stack spacing={3} component="form" mt={1}>
      <TextField required fullWidth placeholder="e-mail" variant="standard" autoComplete="email" onChange={(e) => setEmail(e.target.value)} />
      <PasswordField required variant="standard" placeholder="password" autoComplete="current-password" onChange={(e) => setPassword(e.target.value)} />
      <FormControlLabel
        control={<Checkbox checked={rememberMe} />}
        label="Remember me"
        onChange={() => {
          setRememberMe(!rememberMe);
        }}
      />
    </Stack>
    </>
  );
}

const SignUpTab = ({ setPayload, setDisable }) => {
  const [firstName, setFirstName] = useState(null);
  const [lastName, setLastName] = useState(null);
  const [email, setEmail] = useState(null);
  const [password, setPassword] = useState(null);
  const [rememberMe, setRememberMe] = useState(true);
  const [isPwVisible, setIsPwVisible] = useState(false);
  
  useEffect(() => {
    if (!isNullOrEmpty(firstName) && !isNullOrEmpty(lastName) && !isNullOrEmpty(email) && isEmail(email) && checkPassword(password)) {
      const payload = {
        first_name: firstName,
        last_name: lastName,
        email: email,
        password: password,
        type: REGISTER,
        remember_me: rememberMe
      };
      setPayload(payload);
      setDisable(false);
    } else {
      setDisable(true);
    }
  }, [firstName, lastName, email, password, rememberMe, setDisable, setPayload]);

  return (
    <>
      <Stack spacing={4} component="form" mt={1}>
        <Stack direction="row" spacing={1}>
          <TextField required placeholder="First name" variant="standard" fullWidth onChange={(e) => setFirstName(e.target.value)} autoComplete="given-name"/>
          <TextField required placeholder="Last name" variant="standard" fullWidth onChange={(e) => setLastName(e.target.value)} autoComplete="family-name"/>
        </Stack>
        <TextField required fullWidth autoComplete="email" placeholder="e-mail" variant="standard" sx={{ mb: "1rem" }} onChange={(e) => setEmail(e.target.value)} />
        <Tooltip disableHoverListener open={isPwVisible} placement="top" title={<PasswordRequirements value={password} />} >
          <Box>
            <PasswordField required variant="standard" placeholder="password" autoComplete="new-password" onChange={(e) => setPassword(e.target.value)} onFocus={()=>setIsPwVisible(true)} onBlur={() => setIsPwVisible(false)}/>
          </Box>
        </Tooltip>
        <FormControlLabel
          control={<Checkbox checked={rememberMe} />}
          label="Remember me"
          onChange={() => {
            setRememberMe(!rememberMe);
          }}
        />
      </Stack>
    </>
  );
}