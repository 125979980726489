import * as React from "react";
import { useLayoutEffect, useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import _, { forEach } from "underscore";

// Assets

// Components
import { Alert, Box, Button, Container, Divider, Grid, Stack, Typography, useMediaQuery } from "@mui/material";
import { GoToTop } from "../components/CustomStyledComponents";
import BuyContentCode from "../layout/Modal/BuyContentCode";

// Hooks

// Layouts
import Header from "../layout/Header";
import Main from "../layout/Main";
import Modal from "../layout/Modal";
import Sidebar from "../layout/Sidebar";
import Tiles from "../layout/Tiles";

// Icons
import PlayArrowIcon from "@mui/icons-material/PlayArrow";
import ShopIcon from "@mui/icons-material/Shop";
import RedeemIcon from '@mui/icons-material/Redeem';

// Redux
import { useSelector } from "react-redux";
import { FEATURED_TYPES } from "../services/constants/keys";
import { rehydrateUser } from "../redux/slices/user-slice";

// Plugins
import { useTheme } from "@emotion/react";
import gsap from "gsap";
import Flip from "gsap/Flip";
import ScrollToPlugin from "gsap/ScrollToPlugin";
import ScrollTrigger from "gsap/ScrollTrigger";
import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css";

gsap.registerPlugin(ScrollToPlugin);
gsap.registerPlugin(ScrollTrigger);
gsap.registerPlugin(Flip);

export default function Videos() {
  const dispatch = useDispatch();
  const urlSearchString = window.location.search;
  const params = new URLSearchParams(urlSearchString);
  const stripe_ref = params.get("stripe_ref");
  const [unlockState, setUnlockState] = useState(false);
  const handleUnlockOpen = () => setUnlockState(true);
  const handleUnlockClose = () => setUnlockState(false);

  const [settingsState, setSettingsState] = useState(false);
  const handleSettingsOpen = () => setSettingsState(true);
  const handleSettingsClose = () => setSettingsState(false);
  const allVideos = useSelector((state) => state.content.all_videos);

  const rehydrate = async () => {
    try {
      await dispatch(rehydrateUser()).unwrap();
    }catch (exception) {
      console.log(exception)
    }
  }

  useEffect(() => {
    if (stripe_ref) {
      console.log(stripe_ref);
      rehydrate();
    }
  },[])

  const featuredVids = _.filter(allVideos, item => {
    if (_.contains(FEATURED_TYPES, item.vid_type)) {
      return item;
    }
  })
  const [selectedVid, setSelectedVid] = useState(null);

  const theme = useTheme();
  const bp = useMediaQuery(theme.breakpoints.up("md"));

  return (
    <Box sx={{ display: "flex", background: "black", backgroundAttachment: "fixed" }}>
      {bp ? <Sidebar /> : null}
      <Header type="videos" handleOpen={handleSettingsOpen} />
      <Main>
        <Box sx={{ flexGrow: 1, zIndex: "1", overflow: "hidden" }}>
          <FeaturedCarousel videos={featuredVids} setSelectedVid={setSelectedVid} handleUnlockOpen={handleUnlockOpen} />
          <Tiles setSelectedVid={setSelectedVid} videos={allVideos} handleUnlockOpen={handleUnlockOpen} />
        </Box>
      </Main>
      {unlockState && <Modal type="unlock" handleClose={handleUnlockClose} open={unlockState} vidId={selectedVid} />}
      {settingsState && <Modal type="settings" handleClose={handleSettingsClose} open={settingsState} />}
      <GoToTop />
    </Box>
  );
}

const tl = gsap.timeline();
const animateText = () => {
  tl.progress(0).clear();
  tl.fromTo(".film-title", { y: -50, opacity: 0 }, { y: 0, opacity: 1 });
  tl.fromTo(".film-features", { y: -50, opacity: 0 }, { y: 0, opacity: 1 });
  tl.fromTo(".film-description", { y: -50, opacity: 0 }, { y: 0, opacity: 1 });
  tl.fromTo(".film-button", { y: 50, opacity: 0 }, { y: 0, opacity: 1 });
  tl.fromTo(".film-status", { y: -50, opacity: 0 }, { y: 0, opacity: 1 }, 0);
};

function FeaturedCarousel({ videos, setSelectedVid, handleUnlockOpen }) {
  const navigate = useNavigate();
  const theme = useTheme();
  const bp = useMediaQuery(theme.breakpoints.up("md"));
  const [isBuyModalOpen, setIsBuyModalOpen] = useState(false);
  const user_subs = useSelector((state) => state.user.subscriptions);
  const user_rentals = useSelector((state) => state.user.rentals);
  const videosSorted = videos.sort((a, b) => a.id - b.id);
  const ITL_VIDEO_REC = _.find(videosSorted, v => v.id === 1);

  // Custom onchange animation handler
  // useLayoutEffect(() => {
    // animateText();
  // }, [videos]);


  return (
    <Box
      sx={{
        position: "relative",
        width: "inherit",
        height: { xs: "auto", md: "100vh" },
        background: "linear-gradient(90deg, rgba(0,0,0,.6) 0%, rgba(0,0,0,0) 50%, rgba(0,0,0,0) 100%)",
      }}
      className="mainSection">
      {isBuyModalOpen && <BuyContentCode open={isBuyModalOpen} video={ITL_VIDEO_REC} handleUnlockClose={() =>setIsBuyModalOpen(false)} /> }
      <Carousel infiniteLoop interval={3000} showThumbs={false} showStatus={false} animationHandler="fade" showIndicators={bp ? false : true} showArrows={bp ? true : false}>
        {videosSorted.map((item, index) => {
          return (<Grid
            container
            maxWidth="false"
            sx={{
              width: "inherit",
              height: "inherit",
              display: "flex",
              backgroundImage: `url(${item.preview_url ? item.preview_url : item.thumbnail_url })`,
              backgroundPosition: "center",
              backgroundSize: "cover",
              padding: { xs: "100px 0 50px 0", md: "0" },
            }}
            className="film-info"
            alignItems="left"
            key={index}>
            <Box
              className="gradient"
              sx={{
                width: "100%",
                height: "100%",
                position: "absolute",
                top: "0",
                bottom: "0",
                left: "0",
                right: "0",
                zIndex: "0",
                background: "linear-gradient(90deg, #121212 20%, rgba(18, 18, 18, 0) 70%, rgba(18,18,18,0) 100%)",
              }}></Box>
            <Grid
              item
              xs={12}
              md={12}
              lg={6}
              display="flex"
              flexDirection="column"
              justifyContent="center"
              alignItems="left"
              align="left"
              sx={{ paddingLeft: { xs: "0", md: "50px" }, position: "relative" }}>
              <Container maxWidth="false">
                {!item.is_free && (!user_subs.includes(item.id) && !user_rentals.includes(item.id)) && (
                  <Alert className="film-status" severity="warning" sx={{ alignItems: "center", background: "transparent", padding: "0" }}>
                    Requires Purchase
                  </Alert>
                )}
                <Typography component="h1" variant="h2" className="film-title" mt={2} mb={2} sx={{ fontSize: "4rem", fontWeight: "700" }} align="left">
                  {item.title}
                </Typography>
                <Box
                  className="film-features"
                  sx={{ display: "flex", alignItems: "center", gap: "15px", margin: "2em 0", color: "#ddd", fontFamily: "Oswald Bold", fontSize: "1rem", letterSpacing: "1px" }}>
                  {item.vid_type}
                  <Divider orientation="vertical" variant="middle" flexItem />
                  {item.vid_length}
                  <Divider orientation="vertical" variant="middle" flexItem />
                  {item.vid_year ? item.vid_year : null}
                  <Divider orientation="vertical" variant="middle" flexItem />
                  {item.vid_rating && <Box sx={{ border: "2px solid #ddd", padding: "4px 12px", letterSpacing: "2px" }}>{item.vid_rating}</Box>}
                </Box>
                <Typography component="div" variant="body1" maxWidth="md" className="film-description" mb={2} align="left">
                  {item.desc}
                </Typography>
                <ButtonCheck activated={item.is_free || user_subs.includes(item.id) || user_rentals.includes(item.id)} videoId={item.id} handleUnlockOpen={handleUnlockOpen} setSelectedVid={setSelectedVid} />
              </Container>
            </Grid>
          </Grid>)
      })}
      </Carousel>
      <Box
        display="flex"
        alignItems="center"
        sx={{
          position: { xs: "static", md: "absolute" },
          bottom: "0",
          zIndex: "1",
          width: "100%",
          height: "100px",
          background: { xs: "#000000", md: "linear-gradient(180deg, rgba(0,0,0,.7) 0, rgba(0,0,0,.3) 10%, rgba(0,0,0,1) 100%)" },
          padding: { xs: "0", md: "0 50px" },
        }}>
        <Container maxWidth="false">
          <Stack direction='row' spacing={1}>
            <Typography component="h3" variant="h5">
              Other Popular Titles
            </Typography>
            {ITL_VIDEO_REC && <Button variant="contained" sx={{borderRadius: 4, mt: 2}} onClick={() => navigate("/purchase")}><Box display='flex' alignItems='center'><RedeemIcon sx={{mr: .25}} fontSize='small'/>Pay It Forward</Box></Button>}
          </Stack>
        </Container>
      </Box>
    </Box>
  );
}

function ButtonCheck({ activated, videoId, handleUnlockOpen, setSelectedVid }) {
  const navigate = useNavigate();
  if (activated) {
    return (
      <Button
        size="large"
        component={Link}
        to={`watch/${videoId}`}
        sx={{ position: "relative", borderRadius: "35px", padding: "8px 30px" }}
        variant="contained"
        endIcon={<PlayArrowIcon />}
        className="film-button loaded">
        Watch
      </Button>
    );
  } else {
    return (
      <>
        <Button
          size="large"
          sx={{ position: "relative", borderRadius: "35px", padding: "8px 30px" }}
          variant="outlined"
          color="secondary"
          endIcon={<ShopIcon />}
          className="film-button loaded"
          onClick={() => {
            setSelectedVid(videoId);
            navigate("/purchase");
            // handleUnlockOpen();
          }}>
          Purchase or Redeem
        </Button>
      </>
    );
  }
}
