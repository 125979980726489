import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import _ from "underscore";

// redux
import { checkout, getProductById, removeFromCartByIndex, updateItemQuantity } from "../redux/slices/shop-slice";

// Components
import { AppBar, Badge, Box, Button, Card, CardMedia, Container, Divider, Grid, IconButton, Paper, Stack, TextField, Toolbar, Tooltip, Typography, useMediaQuery, useTheme } from "@mui/material";
import { alpha } from '@mui/material/styles';
import { GoToTop } from "../components/CustomStyledComponents";
import ModalCheck from "../layout/Modal";

// Layout
import Header from "../layout/Header";
import Sidebar from "../layout/Sidebar";

// Assets
import { imageHero } from "../services/constants/dummyData";

// Colors
const alphaBackgroundColor50 = alpha('#121212', .5);
const alphaBackgroundColor70 = alpha('#121212', .7);

export default function Checkout() {
  const theme = useTheme();
  const bp = useMediaQuery(theme.breakpoints.up("md"));

  const [settingsState, setSettingsState] = useState(false);
  const handleSettingsOpen = () => setSettingsState(true);
  const handleSettingsClose = () => setSettingsState(false);

  return (
    <Box sx={{ backgroundImage: `url(${imageHero})`, backgroundSize: "cover", backgroundPosition: "center", minHeight: '100vh', width: '100%',  }}>
      {bp ? <Sidebar /> : null}
      <Header type="videos" handleOpen={handleSettingsOpen} />
      <Box sx={{ display: "flex", minHeight: "100vh", left: "60px", right: "0", width: { xs: "100%", md: "calc(100vw - 60px)" }, ml: { xs: "0", md: "60px" }, mt: { xs: "50px", md: "0" } }}>
        <Container maxWidth='lg' sx={{ mt: '3.5rem', display: 'flex', flexDirection: 'column', minHeight: 'inherit' }}>
          <CheckoutItems />
        </Container>
      </Box>
      <GoToTop />
      {settingsState && <ModalCheck type="settings" handleClose={handleSettingsClose} open={settingsState} />}
    </Box>
  );
}

const CheckoutItems = () => {
  const user_cart = useSelector(state => state.shop.user_cart);
  const shop_items =  useSelector(state => state.shop.shop_items);
  const dispatch = useDispatch();

  // const [user_cart, setUserCart] = useState(u_cart);
  const cart_ids = _.pluck(user_cart, 'id');
  const priceMap = {}
  let totalPrice = 0;
  const cartLength = user_cart.length;

  _.forEach(cart_ids, id => priceMap[id] = _.find(shop_items, p => p.id === id).price);
  user_cart.forEach(item => totalPrice += (parseFloat(priceMap[item.id]) * parseFloat(item.quantity)));
  // console.log('price map', priceMap)

  const checkOut = () => {
    // user_cart.forEach(item => totalPrice += (parseFloat(priceMap[item.id]) * parseFloat(item.quantity)));
    const order = [];
    _.forEach(user_cart, i => order.push({...i, price: priceMap[i.id] }) )
    const payload = {
      order: JSON.stringify(order),
      total_price: totalPrice.toFixed(2),
    }
    dispatch(checkout(payload));
    
    // console.log('order', payload)
  }

  return (
    <Grid container display='flex'>
      <Grid item xs='12' >
        <Box display='flex' flexDirection='row' justifyContent='space-between'>
          <Typography component="h3" variant="h1"  sx={{ fontSize: { xs: "2rem", md: "8rem" }, alignSelf:'center' }}>
            Checkout
          </Typography>
          {cartLength > 0 && <Stack direction='column' spacing={3} mt={2}>
            <Typography variant="h5">Total: ${totalPrice.toFixed(2)}</Typography>
            <Button size="large" variant="outlined" onClick={checkOut}>Checkout</Button>
          </Stack>}
        </Box>
        <Divider flexItem sx={{ borderBottomWidth: 'medium', my: 2 }} />
      </Grid>
        { cartLength > 0 ?
          (user_cart.map((item, idx) => <Grid item xs='12'><CartItem item={item} price={priceMap[item.id]} idx={idx}/></Grid>)) :
          <Grid item xs='12'><EmptyCart /></Grid>
        }
    </Grid>
  );
}

const EmptyCart = () => {
  const navigate = useNavigate();
  return (
    <Stack bgcolor={alphaBackgroundColor50} alignItems='center' spacing={24} p={2} borderRadius={1}>
      <Typography variant="h3" textAlign='center' mt={6}>Your Cart is empty, checkout our shop!</Typography>
      <Button size="large" onClick={()=> navigate("/shop")}>Visit Shop</Button>
    </Stack>
  )
}

const CartItem = ({ item, price, idx }) => {
  const dispatch = useDispatch();
  const { name, item_url, quantity, filters } = item;
  const [qty, setQuantity] = useState(quantity);
  const min = 1;
  const max = 30;

  const removeItem = () => {
    dispatch(removeFromCartByIndex(idx));
  }

  const updateQuantity = (val) => {
    // if (parseInt(val) && val >= min && val <= max) {
    if (parseInt(val)) {
      setQuantity(val);
      dispatch(updateItemQuantity({idx, quantity: val}))
    }
  }

  return (
    <>
    <Stack direction='row' bgcolor={alphaBackgroundColor70}>
      <Grid item xs={4}>
        <CardMedia component="img" height="275" image={item_url} alt={name} />
      </Grid>
      <Grid item xs={4} sm={4}>
        <Stack direction='column' spacing={1} p={2}>
          <Typography variant="h5" mb={2} noWrap>{name}</Typography>
          {_.pairs(filters).map(i => <Typography variant="subtitle1">{`${i[0]}: ${i[1]} `}</Typography>)}
        </Stack>
      </Grid>
      <Grid item xs={4} sm={4}>
        <Stack direction='column' spacing={2} alignItems='center' p={2}>
          <Typography variant="h5" mb={2}>${(parseFloat(price) * parseFloat(quantity)).toFixed(2)}</Typography>
          <TextField
            label="Quantity"
            type="number"
            sx={{width: '5rem'}}
            value={qty}
            onChange={(e) => updateQuantity(e.target.value)}
            // inputProps={{ min, max }}
            InputLabelProps={{
              shrink: true
            }}
            variant="standard"
          />
          <Button onClick={removeItem}>Delete</Button>
        </Stack>
      </Grid>
    </Stack>
    <Divider flexItem sx={{ borderBottomWidth: 'medium', my: 1, mx:4 }} />
    </>
  );
}