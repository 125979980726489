import React, { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
// import { Helmet } from "react-helmet";

// Components
import { Accordion, AccordionDetails, AccordionSummary, Box, Button, Card, CardMedia, Container, Checkbox, Divider, Link, FormControlLabel, Grid, Paper, Stack, TextField, Typography, ToggleButton, ToggleButtonGroup, Tooltip, Input, useMediaQuery, useTheme, Fade, Skeleton, } from "@mui/material";
import { alpha } from '@mui/material/styles';
import { PasswordField, PasswordRequirements, FeedBack } from "../components/CustomStyledComponents";
import Header from "../layout/Header";
import CustomModals from "../layout/Modal";
import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css";


import { isNullOrEmpty, isEmail, checkPassword } from "../services/utilities";

// import Header
import { imageHero } from "../services/constants/dummyData";

// Icons
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';

// Styling
import { createTheme, ThemeProvider } from "@mui/material/styles";

// Redux
import { purchaseContent, setToken, registerUser, logOut } from "../redux/slices/user-slice";
import { orderGiftCodes, payForwardOrder, setPromoCode, setReduxAlignment } from "../redux/slices/shop-slice";

// Data
import { getVideoById } from "../redux/slices/content-slice";

// Consts
import {  GIFT_CODE, BUY, RENT, CONTENT, ITF_TRAILER, SHOP_URL, FILM_CHECKOUT_URL_SOURCE, SUPPORT_MISSION_VALUES_SOURCE, DEV, PROD } from "../services/constants/keys";

const itl_thumbnail = 'https://itl-assets.b-cdn.net/thumbnails/1920x1080_grey_oswald.jpeg';
const jbs_banner = 'https://itl-assets.b-cdn.net/thumbnails/jb_logo.png';

// NOTE: stripe checkout sources
const FILM_CHECKOUT_URL = FILM_CHECKOUT_URL_SOURCE[PROD];
const SUPPORT_MISSION_VALUES = SUPPORT_MISSION_VALUES_SOURCE[PROD];

const alphaBackgroundColor80 = alpha('#121212', .8);
const CODES = 'codes';

export default function PurchasePage() {
  const dispatch = useDispatch();
  const { is_verified } = useSelector((state) => state.user);
  const [settingsState, setSettingsState] = useState(false);
  const [loginState, setLoginState] = useState(false);

  const handleSettingsOpen = () => setSettingsState(true);
  const handleSettingsClose = () => setSettingsState(false);
  const handleLoginOpen =() => setLoginState(true);
  const handleLoginClose = () => setLoginState(false);

  const { promocode } = useParams();
  const reduxPromoCode = useSelector((state) => state.shop.promo_code);

  useEffect(() => {
    if(promocode && promocode !== "orders") {
      if (promocode !== reduxPromoCode) {
        dispatch(setPromoCode(promocode));
      }
    }
  },[promocode]);
  
  return (
    <>
    {/* <PaymentScript setTokenPayClass={setTokenPayClass} tokenPayClass={tokenPayClass}/> */}
    <Box sx={{ backgroundImage: `url(${imageHero})`, backgroundSize: "cover", backgroundRepeat: "no-repeat", backgroundPosition: "center", width: "100%", height: "100%", position: "fixed", overflowY: "scroll" }}>
      {is_verified ? <Header type="videos" handleOpen={handleSettingsOpen} /> : <Header type="landing" handleOpen={handleLoginOpen} />}
      {settingsState && <CustomModals type="settings" handleClose={handleSettingsClose} open={settingsState} />}
      {loginState && <CustomModals type="login" handleClose={handleLoginClose} open={loginState} />}
      <Container sx={{mt: 10 }} maxWidth="md" >
        <Stack spacing={3}>
          { promocode === "jbs" && <CardMedia component="img" image={jbs_banner} /> }
          <CheckOutPanel handleLoginOpen={handleLoginOpen} />
          <Typography variant="h3">Want a DVD?</Typography>
          <Paper sx={{ py: "25px", px: 2, bgcolor: alphaBackgroundColor80, display: "flex", flexDirection: "column", alignItems: "center" }}>
            <Link href={ promocode === "jbs" ? "https://intothelight.store/collections/jbs" : SHOP_URL } target='_blank'>
              <CardMedia component="img"  image="https://itl-assets.b-cdn.net/store_items/itf_dvd_doublefeature.webp" alt="dvd" sx={{ maxWidth: "380px" }} />
            </Link>
            <Typography variant="body1" sx={{ fontFamily: "Lora", fontSize: "1.2rem", textAlign: "center"}} >Purchase Into The Light for $14.99 or our DUAL PACK for $24.99!</Typography>
            <Button variant="contained" href={ promocode === "jbs" ? "https://intothelight.store/collections/jbs" : SHOP_URL } target='_blank'  sx={{ alignSelf: "center", mt: 1 }}>Click Here!</Button>
          </Paper>
          <Paper sx={{ py: "25px", px: 2, bgcolor: alphaBackgroundColor80 }}>
             <Endorsements /> 
          </Paper>
          <Paper sx={{ py: "25px", px: 2, bgcolor: alphaBackgroundColor80 }}>
            <MissionStatement />
          </Paper>
        </Stack>
      </Container>
    </Box>
    </>
  );
}

const CheckOutPanel = ({ handleLoginOpen }) => {
  const navigate = useNavigate();
  const reduxPromoCode = useSelector((state) => state.shop.promo_code);
  const reduxAlignment = useSelector((state) => state.shop.alignment);
  const [alignment, setAlignment] = useState(reduxAlignment ? reduxAlignment : BUY);

  return(
    <Paper sx={{ py: "25px", px: 2, bgcolor: alphaBackgroundColor80 }}>
      <Box mb={2} display='flex' flexDirection='row' alignItems='center' justifyContent="space-between"><Typography variant="h3">Ways To Watch</Typography><Button variant="contained" sx={{ mt: 1, width: 200 }} onClick={() => navigate("/redeem")}>Have a gift code? Redeem</Button></Box>
      <SelectPurchaseOption alignment={alignment} setAlignment={setAlignment} />
      {/* {is_verified && alignment === CODES && <SeeOrderHistory />} */}
      {alignment === BUY && <BuyForYourself handleLoginOpen={handleLoginOpen} />}
      {alignment === CODES && <BuyContentCodes />}
    </Paper>
  );
}



const BuyForYourself = ({ handleLoginOpen  }) => {
  const { is_verified, user_id, email } = useSelector((state) => state.user);
  const user_subs = useSelector((state) => state.user.subscriptions);
  const url = `${FILM_CHECKOUT_URL}?prefilled_email=${email.replace("@", "%40")}&client_reference_id=${user_id}`;

  const clickAction = () => {
    // logOut();
    window.location.href = url;
  }

  return (
    <Stack spacing={3} alignItems='flex-start' p={1} width={1}>
      <Box alignSelf="center">
        <Typography variant="h5" mb={2}>Into The Light Now Available!</Typography>
        <Box maxWidth="600px" width={1}>
          <CardMedia component="img" image={itl_thumbnail}/>
        </Box>
        
      </Box>
      {is_verified ? 
        !user_subs.includes(1) ? 
          <Stack spacing={5} alignSelf="center">
            <Typography variant="h5" textAlign="center" my={2} sx={{ lineHeight: '100px' }}>Own Into The Light and join our network for $9.99!</Typography>
              <Button size="large" fullWidth variant="contained" onClick={clickAction} sx={{
              alignSelf: "center",
              // width: "12rem",
              maxWidth: "600px",
              height: "3.5rem",
              borderRadius: '3rem',
              // bgcolor: "#834bff",
              boxShadow: "0 0 50px 5px #E67451",
              border: "2px solid yellow",
              "&:hover": {
                bgcolor: "#E67451",
                boxShadow: "0 0 40px 5px #E67451",
              }
            }}>
              <Typography variant="h6" color="black" sx={{ fontWeight: "700" }} >Click to Purchase Film!</Typography>
            </Button>
          </Stack>
           :
          <Box sx={{ alignSelf: "center" }}>
            <ContentAlreadyOwned/>
          </Box>
        :
        <Box sx={{ alignSelf: "center" }}>
          <SignUpTab showLoginModal={handleLoginOpen} />
        </Box>
      }
      </Stack>
  );
}

const BuyContentCodes = () => {
  const [selectedPriceIdx, setSelectedPriceIdx] = useState(1);

  return (
    <Stack direction='column' spacing={3} alignItems='center' justifyContent='space-between' p={1}>
      <Stack>
        <Accordion expanded={true}>
          <AccordionSummary><Typography variant="h5" mr={2} textAlign="center">Support Our Mission</Typography><ArrowDownwardIcon fontSize="large" /></AccordionSummary>
          <AccordionDetails>
            <SupportOurMission selectedPriceIdx={selectedPriceIdx} setSelectedPriceIdx={setSelectedPriceIdx} />
          </AccordionDetails>
        </Accordion>
      </Stack>
    </Stack>
  );
}

const SupportOurMission = ({ selectedPriceIdx, setSelectedPriceIdx }) => {
  const { is_verified, user_id, email } = useSelector((state) => state.user);

  const clickAction = () => {
    console.log('buy');
    
    if (is_verified) {
      const url = `${SUPPORT_MISSION_VALUES[selectedPriceIdx].url}?prefilled_email=${email.replace("@", "%40")}&client_reference_id=${user_id}`
      window.open(url, '_blank');
    } else {
      const url = SUPPORT_MISSION_VALUES[selectedPriceIdx].url;
      window.open(url, '_blank');
    }
  }
  
  return(
    <Stack spacing={4}>
      <Box>
        <Typography variant="p" fontFamily='Oswald Light' textAlign='center'>Thank you for joining this fight and supporting our mission! Your contribution will directly help cover essential server costs, enabling us to maintain and expand our digital infrastructure. Additionally, your support will fund future projects that continue to inspire and educate our community, while also allowing us to organize and host in-person events that foster connection and shared purpose. Together, we are building something impactful, and we couldn't do it without you.</Typography>
      </Box>
      <Box display='flex' flexDirection='row' flexWrap='wrap' gap={2} justifyContent='center'>
        {SUPPORT_MISSION_VALUES.map((i,idx) => {
          return (<Button key={idx} variant="contained" sx={{bgcolor: selectedPriceIdx === idx ? 'skyblue' : null}} onClick={() => { setSelectedPriceIdx(idx);}}>{i.price_label}</Button>)
        })}
      </Box>
      <Typography variant="h6" textAlign="center" my={2} sx={{ lineHeight: '100px', fontWeight: 700 }}>{`Selected Support Amount: ${SUPPORT_MISSION_VALUES[selectedPriceIdx].price_label}`}</Typography>
      <Button size="large" variant="contained" onClick={clickAction} sx={{
        alignSelf: "center",
        maxWidth: "600px",
        height: "3.5rem",
        borderRadius: '3rem',
        boxShadow: "0 0 50px 5px #E67451",
        border: "2px solid yellow",
        "&:hover": {
          bgcolor: "#E67451",
          boxShadow: "0 0 40px 5px #E67451",
        }
      }}>
        <Typography variant="h6" color="black" sx={{ fontWeight: "700" }} >Click Here To Support Us!</Typography>
      </Button>

      {/* {selectedPriceIdx === 8 && 
      <Box alignSelf='center'>
        <TextField label='Custom Amount $:' variant="filled" sx={{maxWidth: 360}} onChange={e => setPrice(e.target.value)}/>
      </Box>} */}
    </Stack>
  );
}


const endorsement_texts = [
  ['This film has been banned, doxxed, cyber attacked for all the wrong reasons. It’s time to sit down and watch it and gather friends and family around to do the same.', 'We need to maintain momentum to see, understand, and exploit the vulnerabilities and the enemy we are all facing. This film lays it all out. Thank you Director Mike Smith.', '— Lt. General Michael Flynn'],
  ['"Into the Light is one of the most powerful documentaries you will ever see. And you will have, as I did, that aha moment where you understand how you\'re being manipulated. This movie needs not only your viewership, but also your support."', '— Roger Stone'],
  ['“This film employs profoundly Ephesians 5:11: ‘And have no fellowship with the unfruitful works of darkness, but rather expose them.’ Every pastor and church in America needs to see this film!”', '— Rob McCoy, Pastor of Godspeak Calvary Chapel & Former Mayor of the City of Thousand Oaks'],
]

const Endorsements = () => {
  return(
    <Box mb={2}>
      <Box mb={1} display='flex' flexDirection='row' alignItems='center'><Typography variant="h3" mb={3}>What Others Are Saying</Typography></Box>
      <Carousel  autoPlay infiniteLoop interval={3000} showThumbs={false} showStatus={false} animationHandler="fade" showIndicators={true} showArrows={false}>
        {endorsement_texts.map(item => {
          return (
            <Fade in timeout={600}>
            <Stack spacing={1} mb={1} height={325}>
              {item.map(item => {
                return (<Typography paragraph variant="body1" textAlign="left">{item}</Typography>)
              })}
            </Stack>
            </Fade>
          )
        })}
      </Carousel>
      <Box width="100%" alignSelf='center' mb={3}>
        <video src={ITF_TRAILER} controls controlsList="nodownload"/>
        {/* <CardMedia component="img" image={itl_thumbnail}/> */}
      </Box>
    </Box>
  );
}

const MissionStatement = () => {
  return(
    <Stack spacing={2} my={2}>
      <Box mb={2} display='flex' flexDirection='row' alignItems='center'><Typography variant="h3">Our Mission</Typography></Box>
      <Typography paragraph variant="body1" maxWidth="sm" sx={{ mb: 4 }}>
        From the creators of Out of Shadows, we bring you Into the Light. Into the Light is a movie made to bring to surface that psychological operations are present and active in today's society. Into the Light will feature experts in psychological operations and mainstream media manipulation such as, General Michael Flynn, Lara Logan, Brian Gamble, Dr. Keith Rose, Boone Cutler and Mike Smith. This project brings an unbiased viewpoint to the control being done through psychological operations.
      </Typography>
      <Typography variant="body1" paragraph maxWidth="sm" sx={{ mb: 4 }}>
        We hope that this movie brings to light the problems that need to be addressed. Our goal is to activate a community of people who are not afraid to stand up for the truth and make a change. Enjoy!
      </Typography>
    </Stack>
  )
}

const SelectPurchaseOption = ({ alignment, setAlignment }) => {
  const handleChange = (e, newAlignment) => {
    if (newAlignment !== alignment && newAlignment !== null) {
      setAlignment(newAlignment);
    }
  };

  return (
    <Grid container>
      <ToggleButtonGroup
        color="primary"
        value={alignment}
        exclusive
        onChange={handleChange}
        aria-label="purchase options"
        fullWidth
        sx={{ height: '5rem' }}
      >
        <ToggleButton value={BUY} color="success" >   
          <Stack spacing={2}>
            <Typography variant="body1" sx={{ fontFamily: "Lora", fontSize: "1rem", textShadow: "#FC0 1px 0 10px" }} >Purchase For Yourself!</Typography>
          </Stack>
        </ToggleButton>
        <ToggleButton value={CODES} color="success" >
          <Stack spacing={2}>
            <Typography variant="body1" sx={{ fontFamily: "Lora", fontSize: "1rem", textShadow: "#FC0 1px 0 10px" }} >Support Our Mission!</Typography>
          </Stack>
        </ToggleButton>
      </ToggleButtonGroup>
    </Grid>
  );
}

const ContentAlreadyOwned = () => {
  const navigate = useNavigate();
  return(
    <Stack spacing={1} alignItems="center">
      <Typography variant="h6" textAlign="center" sx={{p: "5px"}}>This content is already owned! Please consider paying it forward!</Typography>
      <Typography variant="h6" textAlign="center" sx={{p: "5px"}}>Thank you for your support!</Typography>
      <Button variant="contained" sx={{width: "350px"}} onClick={() => navigate("/watch/1")}>Click to Watch!</Button>
    </Stack>
  )
}

const SignUpTab = ({ showLoginModal  }) => {
  const dispatch = useDispatch();
  const [firstName, setFirstName] = useState(null);
  const [lastName, setLastName] = useState(null);
  const [email, setEmail] = useState(null);
  const [password, setPassword] = useState(null);
  const [isPwVisible, setIsPwVisible] = useState(false);
  const [isDisabled, setDisabled] = useState(true);
  const [feedBack, setFeedback] = useState({ passed: null, comp: null });
  
  useEffect(() => {
    if (isNullOrEmpty(firstName) || isNullOrEmpty(lastName) || isNullOrEmpty(email) || !isEmail(email) || !checkPassword(password)) {
      setDisabled(true);
    } else {
      setDisabled(false);
    }
  }, [firstName, lastName, email, password]);

  const registerClick = async () => {
    const payload = {
      first_name: firstName,
      last_name: lastName,
      email: email,
      password: password,
    };
    try {
      setDisabled(true);
      await dispatch(registerUser(payload)).unwrap();
      setFeedback({ passed: true, comp: <FeedBack passed={true} message="Success!" mt={1} /> });
      setDisabled(false);
    } catch (exception) {
      let msg = "Registeration failed. Please try again";
      if (exception.detail == "email already registered") {
        msg = "Email already registered, please sign in";
      }
      setFeedback({ passed: false, comp: <FeedBack passed={false} message={msg} mt={1} /> });
      setDisabled(false);
    }
  };

  return (
    <>
      <Stack spacing={4} component="form" mt={1}>
        <Stack direction="row" spacing={1}>
          <TextField required placeholder="First name" variant="filled" fullWidth size="small" onChange={(e) => setFirstName(e.target.value)} autoComplete="given-name"/>
          <TextField required placeholder="Last name" variant="filled" fullWidth size="small" onChange={(e) => setLastName(e.target.value)} autoComplete="family-name"/>
        </Stack>
        <TextField required fullWidth autoComplete="email" placeholder="e-mail" size="small" variant="filled" sx={{ mb: "1rem" }} onChange={(e) => setEmail(e.target.value)} />
        <Box>
          <Tooltip disableHoverListener open={isPwVisible} placement="top" title={<PasswordRequirements value={password} />} >
            <Box>
              <PasswordField required variant="filled" placeholder="password" autoComplete="new-password" size="small" onChange={(e) => setPassword(e.target.value)} onFocus={()=>setIsPwVisible(true)} onBlur={() => setIsPwVisible(false)}/>
            </Box>
          </Tooltip>
          <Typography variant="subtitle2" color="lightblue" onClick={()=> showLoginModal()} sx={{'&:hover':{cursor: 'pointer'} }}>Have an account? Sign In</Typography>
        </Box>
        <Button variant="contained" size="large" disabled={isDisabled} sx={{ mt: 3, width: "100%" }} onClick={registerClick}>
          Register
        </Button>
        {feedBack.passed !== null && feedBack.comp}
      </Stack>
    </>
  );
}

const SeeOrderHistory = () => {
  const navigate = useNavigate();
  return(
    <Stack spacing={1} alignItems="center" mt={4}>
      <Typography variant="h6" textAlign="center" sx={{p: "5px"}}>Click below to your purchased codes and order history!</Typography>
      <Typography variant="h6" textAlign="center" sx={{p: "5px"}}>Thank you for your support!</Typography>
      <Button variant="contained" sx={{width: "350px"}} onClick={() => navigate("/user/orders")}>My Orders</Button>
    </Stack>
  );
}
