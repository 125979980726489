import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useParams, useNavigate } from "react-router-dom";
import _ from "underscore";

import { useMediaQuery, useTheme, Avatar, Box, Card, Paper, Stack, Typography, TextField, Button, Container, Tooltip, Grid, CardMedia, CardContent, ImageList, ImageListItem, ImageListItemBar, ListSubheader, Fade, CardActionArea } from "@mui/material";
import Header from "../layout/Header";
import Modal from "../layout/Modal";
import { PasswordField, PasswordRequirements, FeedBack } from "../components/CustomStyledComponents";
// import { cast_members } from "./About";

import { alpha, createTheme, styled, ThemeProvider } from "@mui/material/styles";
//Redux
import { forgotPasswordReset } from "../redux/slices/user-slice";

import { isEmail, checkPassword } from "../services/utilities";

import { imageHero } from "../services/constants/dummyData";

import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css";

const alphaWh70 = alpha('#ffffff', .7);
const alphaWh40 = alpha('#ffffff', .3);

const alphaBackgroundColor80 = alpha('#121212', .8);


const mike_hs = "https://itl-assets.b-cdn.net/thumbnails/ms_bio.jpg";
// const boone_hs = "https://itl-assets.b-cdn.net/thumbnails/boone_bio.jpeg";
const boone_hs = "https://itl-assets.b-cdn.net/thumbnails/boon_hs_2.jpeg";
const flynn_hs = "https://itl-assets.b-cdn.net/thumbnails/flynn_bio.jpg";
const rose_hs = "https://itl-assets.b-cdn.net/thumbnails/k_rose.jpg";
const lara_hs = "https://itl-assets.b-cdn.net/thumbnails/lara_bio.jpeg";
const bg_hs = "https://itl-assets.b-cdn.net/thumbnails/bg_bio.jpeg";
const liz_hs = "https://itl-assets.b-cdn.net/thumbnails/liz_crokin.jpeg";
const dvd_cover = "https://itl-assets.b-cdn.net/store_items/itf_dvd_doublefeature.webp";

const ITL_POSTER = "https://itl-assets.b-cdn.net/thumbnails/ITL_loop.webp";
// const ITL_POSTER = "https://itl-assets.b-cdn.net/thumbnails/itl_poster_grey.jpg";
// const bg_bio = "";

// const cast_members = [
//   {img: mike_hs, name: "Mike Smith" },
//   {img: boone_hs, name: "Boone Cutler" },
//   {img: flynn_hs, name: "Michael Flynn" },
//   {img: rose_hs, name: "Keith Rose" },
//   {img: lara_hs, name: "Lara Logan" },
//   {img: bg_hs, name: "Brian Gamble" },
//   {img: dvd_cover, name: "Liz Crokin" },
// ];

const bgBio = [
  `Brian Gamble is an accomplished data sciences professional and tech visionary with over three decades of experience in the industry. Throughout his distinguished career, Brian has left indelible marks across diverse sectors, holding pivotal positions including the role of Chief Information Officer for a prominent Fortune 500 enterprise.`,
  `Early in his career, Brian served as an intelligence analyst in the United States Navy during his deployment to the war in the former Yugoslavia. This experience wielded a transformative impact on Brian, charting his lifelong course to shed light on the techniques employed by deep state actors to manipulate the behaviors and cognitions of his fellow countrymen.`,
  `For years since leaving the military, Brian balanced his role as a corporate leader, employing technology to confront global challenges during the day, while discretely fostering awareness regarding deep state activities online by night.`,
  `This rhythm was disrupted when Brian noticed the rapid rise of online censorship. To combat this threat, in 2019 Brian founded a free-speech organization to give a voice to those silenced by big tech. He embarked on a nationwide journey, raising awareness about the growing dangers posed by emerging technologies and the influence of big tech on free speech.`,
  `Driven by his passion for exposing the evolving technocracy in the United States, Brian eventually completely transitioned away from the corporate world which he viewed as increasingly hostile towards his traditional American values.`,
`Brian now supports organizations dedicated to uncovering and exposing illegal activities within national institutions, striving to safeguard the principles of justice and transparency.`,
];

const msBio = [
  'Mike Smith is the creator, producer and director of the viral film sensation Out of Shadows. That documentary – viewed by well over 100 million people worldwide – shed light on media manipulation and child trafficking. Since the 2020 release of Out of Shadows, Mike has become a Champion in the fight against media censorship and exposing the dark side of Hollywood.',
  'After Out of Shadows was released, Mike Smith and his team went on to make his newest movie Into the Light. This movie took three years to produce and was released in August 2023. Since the release of Into the Light, Mike Smith has been traveling to different churches around the country to awaken the minds of the people and to encourage them to start brushfires of freedom in the minds of men.',
  'Mike Smith grew up in Houston, Texas where he began a multi- sport athletic career at a young age. Mike was a lifelong practitioner in various martial arts, horseback riding and motocross. On a diving scholarship, Mike graduated from Auburn University. He then went on to high dive in live shows at Magic Mountain and the MGM Grand Hotel.',
  `Mike then began working on movie sets doing action stunts and quickly became one of Hollywood's hottest stunt doubles. He served as doubles for many stars such as Mel Gibson, Ethan Hawke, Christian Bale, David Duchovney and several other A- list actors. He's been James Bond, Batman and countless other iconic movie heroes. Mike has appeared in hundreds of feature films and television productions.`,
  `As Mike’s career progressed he transitioned into the world of stunt coordinating and action directing. Mike became an honored member of Stunts Unlimited – one of Hollywood's most prestigious groups – and he served on the board of directors for multiple terms. Mike never stopped trying to develop his ability in filmmaking, and eventually he became one of Hollywood's most sought after action directors. Mike also accomplished the rare achievement in Hollywood of becoming a member of SAG/AFTRA, WGA, DGA, and the Academy of Motion Pictures.`,
  `Sadly, tragedy struck in 2014. Mike shattered his spine and became paralyzed from the waist down while working on a movie set. During his recovery, life took him on a journey that brought him to Christ and the realization that Hollywood was no longer a world he wanted to be a part of. Mike made the decision to sacrifice his career and livelihood, to expose what he believed to be the truth about the dark underbelly of media and Hollywood through his blockbuster film Out of Shadows.`,
  `Currently, Mike and his team are dedicated to revolutionizing and restructuring the media for the Judeo-Christian fundamentals that this nation was founded upon.`,
];

const bcBio = [
  `Boone Cutler's journey from a combat veteran serving in Iraq to an influential advocate for the Warfighter community and fellow Americans is deeply intertwined with his literary works. In the heart of Sadr City Boone served as a Psychological Operations team sergeant. This frontline experience became the cornerstone for his first book, "CallSign Voodoo". Written in the throes of battle and completed during his recovery at Walter Reed Army Medical Center amid the 2007 neglect scandal, the book offers a raw, firsthand perspective of war and recovery.`,
  `Boone's commitment to shedding light on the Warfighter's perspective led him to pen two more books focused on the challenges faced by veterans. His work paints an intimate portrait of the Sadr City conflict, the issues faced by patients at Walter Reed during the neglect scandal of 2007, the dark tunnel of prescription drug addiction, and the beacon of alternative medical treatments that include cannabis, stem cells, psychedelics, and hormone replacement therapy, including human growth hormone for traumatic brain injury.`,
  `In 2020, Boone introduces "Functional Personal Lifestyle (FPL)", a collaborative guide written with Geoff Dardia for veterans navigating post-combat life. His literary accolades further expanded with his collaboration with General Michael T. Flynn on "The Citizen's Guide to Fifth Generation Warfare", a comprehensive series of military manuals that delve into modern warfare dynamics written specifically for civilians to easily understand.`,
  `Amid his writing career, Boone launched "Tipping Point with Boone Cutler - The Warfighter Perspective" on Fox News affiliate 99.1 FM Talk, later partnering with 107.3 FM in Reno, Nevada. His radio presence, combine with his literary voice, magnified the pressing issues like Warfighter suicide and PTSD.`,
  `Determined to make a tangible impact, Boone introduced "The Spartan Pledge" in collaboration with GallantFew.org and SpartanSword.org, a campaign designed for Warfighters and first responders to combat suicide. His endeavors didn't stop there; in 2012, he was diagnosed with Early-Onset Parkinson's disease from a wartime injury, which propelled him to raise awareness of such injuries with the Warfighter community. `,
  `With a passion for the arts, Boone channeled his experiences into the music world as a producer/director for REDCON-1 MUSIC GROUP. As the spearhead of the Warfighters' Rights Movement in 2014, Boone's multifaceted approach to advocacy - through literature, media, and grassroots campaigns - continues to reshape the narrative and support for Warfighters nationwide.`,
];

const llBio = [
  `Lara Logan's candid  reporting, often from the most dangerous places in the world, has earned her a prominent place among the world's best foreign correspondents.`,
  `In her role at 60 Minutes, Logan helped us understand the political and human conflicts around the world, including Pakistan, Iraq, Afghanistan, Israel and Palestine, and Egypt.`,
  `Committed to the story, she lived in Iraq for five years at the height of the violence. She was one of the few journalists in Baghdad when the U.S. Military entered the city, reporting live in the square as the statue of Sadam fell. Logan has earned almost every journalistic and reporting award, among them are the highly prestigious duPont-Columbia University Silver Baton, the Overseas Press Club, the Emmys and the RTNDA/ Edward R. Murrow Award - to name a few.`,
  `Her fearless determination to get the story from its vortex, no matter how dangerous, has often put Logan herself in great danger. In Tahrir Square in Egypt, she came close to death when she was sexually assaulted by a mob while reporting for 60 Minutes on the Arab Spring.`,
  `A journalist who heads straight into hostile places not for spectacle, but for the sake of our understanding the real issues of our time, Logan is an insightful interpreter of international current events and a fearless interviewer asking tough questions of today's most powerful people.`,
  `Today she has a successful long-form, investigative TV series that takes on significant topics with an open mind and an open heart - the way journalism is supposed to be done. It’s called “Lara Logan Has No Agenda” and is broadcast on Fox Nation. The series has taken her deep into subjects like immigration and the south west border, socialism, media, veterans and so on.`,
  `You will often see Lara on various news programs and prime time Fox shows where she is a sought-after voice to comment on topics ranging from national security to terrorism, journalism, foreign affairs and so on.`
];

const krBio = [
  `Dr. Keith Rose maintains an active plastic surgery practice in Corpus Christi, Texas, The Rose Cosmetic Surgery Center.   He is also the CEO of Rose Medical Management LLC which provide medical management services, case management, and onsite clinics to over 100 companies across the United States and is CEO of the Doctors Center Urgent Care.   The Doctors Center Urgent Care (TDCUC) has 4 clinics in South Texas and has been in business for 23 years.`,
  `Dr. Rose obtained his Bachelor of Science degree from Baylor University, Waco, Texas, and his Medical Degree from the University of Texas Medical Branch, Galveston, Texas, where he was an Alpha Omega Alpha Honor Society member.  He completed his general surgery residency at the University of Texas, Southwestern Medical Center, Dallas, Texas, a pediatric burns and reconstructive fellowship at the Shriners Hospital for Crippled Children in Galveston, Texas, and a plastic and reconstructive surgery Fellowship at the University of Texas Southwestern Medical Center.  He is a member of the American Academy of Cosmetic Surgery, Alpha Omega Alpha Honor Medical Society, American Burn Association, American Cleft Palate-Craniofacial Association, Texas Medical Association,  and the American Association of Medical Review Officers.  He has published numerous articles in peer review journals and has been a featured speaker at numerous organizations and events in the US and around the world.`,
  `Dr. Rose is trained in general surgery, plastic surgery, burns and critical Care.  He has extensive field experience in trauma, burns, diving medicine, emergency medicine, and critical air transport.   He is a former Army Captain.  Upon leaving the military in 2002, he was recruited back into government service and has worked extensively in Central and South America, Africa, the Middle East, and Southeast Asia.`,
  `In addition, he provides tactical medicine training to the military, numerous government and law enforcement agencies, and security companies.  In 2008, Dr. Rose developed and patented (multiple patents) The Integrated Tourniquet System (ITS) which was used by Naval Special Warfare, law enforcement, and militaries around the world.`,
  `He currently lives in Texas and enjoys reading, surfing, kite surfing, and ranching.  He is a pilot with over 2500 hours of flight time.  He and his wife Erika have been blessed with eight children.`,
];

const gfBio = [
  `Lt. General Michael T. Flynn, USA (Ret.) dedicated his life to service to the nation with the U.S. Army for more than 33 years. His distinguished military career culminated as the Director of the Defense Intelligence Agency and as the nation’s highest-serving military intelligence officer.  Upon his retirement from the Army in 2014, he went on to serve in leadership positions in a variety of business, educational, and nonprofit organizations. He currently serves as America’s Future, Inc. Chair of the Board.`,
  `In 2017, he was appointed as the country’s 25th National Security Advisor to the 45th President of the United States, Donald J. Trump. He is an expert in diplomacy, statecraft, foreign policy, strategy formulation, defense, intelligence, and other areas of national and international security, and is a prominent commentator and sought-after speaker on military and global affairs.`,
  `He is the author of A Letter To America: The Time To Fight For Your Faith And Family Is Now and the national best seller, The Field of Fight: How We Can Win the Global War Against Radical Islam and its Allies.  He is also the co-author of a fifth-generation-warfare information series, The Citizen’s Guide To Fifth Generation Warfare. How to Fight Artificial Intelligence (AI) is the second book of the multipart series. His numerous articles, essays, and papers have been published worldwide.`,
  `General Flynn has earned three master’s degrees and is the recipient of numerous civilian, military, intelligence, and law enforcement awards. He is married with two sons and has several grandchildren.`
];

const lizBio = [
  `Liz Crokin is an award-winning author, an independent investigative journalist and a documentary film producer best known for her relentless work exposing sex trafficking.`,
  `Liz executive produced Slave Princess released in the fall of 2021. The documentary film covered Britney Spears’s illicit conservatorship and the systematic corruption surrounding it involving media, Hollywood and the government. In spring of 2020, Liz appeared in the viral documentary Out Of Shadows that has been viewed by over 100 million people globally and translated into over two-dozen languages.`,
  `In 2016, Liz broke several exclusive viral stories for the New York Observer on the presidential election and corruption in media and big tech. Her stories received millions of impressions and some were featured on the front-page of the Drudge Report. Liz published a political-romantic thriller, Malice, which received an award at the Hollywood Book Festival in 2015.`,
  `Liz has worked for various major media companies over the course of two decades. She started as a wire reporter covering hard news at the City News bureau at the Chicago Tribune. She ran a weekly gossip column in the RedEye Edition of the Chicago Tribune for seven years. She also had a regular column in the Chicago Sun-Times: Splash & Townhall.`,
  `She’s worked as a senior reporter for Us Weekly and also as an editor for both In Touch Weekly and Star magazines. Her work has also been featured at Daily Caller, Elite Daily, and Marie Claire. In her early years, she interned for the State Department’s White House correspondent in the White House and trained with Bill O’Reilly during a summer internship at Fox News. Liz graduated from the University of Iowa in 2001 with a degree in journalism and political science.`
];

export const cast_members = {
  "mike-smith": {img: mike_hs, name: "Mike Smith", bio: msBio },
  "mike-flynn": {img: flynn_hs, name: "LTG Michael T. Flynn, Ret.", bio: gfBio }, // Lt. General Michael T. Flynn, USA (Ret.)
  "brian-gamble": {img: bg_hs, name: "Brian Gamble", bio: bgBio },
  "boone-cutler": {img: boone_hs, name: "Boone Cutler", bio: bcBio },
  "keith-rose": {img: rose_hs, name: "Keith Rose", bio: krBio },
  "lara-logan": {img: lara_hs, name: "Lara Logan", bio: llBio },
  "liz-crokin": {img: liz_hs, name: "Liz Crokin", bio: lizBio },
};

export default function Bio() {
  const { bio } = useParams();
  const { is_verified } = useSelector((state) => state.user);
  const navigate = useNavigate();
  const [loginState, setLoginState] = useState(false);
  const [settingsState, setSettingsState] = useState(false);
  const handleSettingsOpen = () => setSettingsState(true);
  const handleSettingsClose = () => setSettingsState(false);
  const handleLoginOpen =() => setLoginState(true);
  const handleLoginClose = () => setLoginState(false);


  return (
    <Box sx={{ backgroundImage: `url(${imageHero})`, backgroundSize: "cover", backgroundRepeat: "no-repeat", backgroundPosition: "center", width: "100%", height: "100vh", position: "fixed", overflowY: "scroll" }}>
      {is_verified ? <Header type="videos" handleOpen={handleSettingsOpen} /> : <Header type="landing" handleOpen={handleLoginOpen} />}
      {settingsState && <Modal type="settings" handleClose={handleSettingsClose} open={settingsState} />}
      {loginState && <Modal type="login" handleClose={handleLoginClose} open={loginState} />}
      <Container maxWidth="lg"  sx={{alignItems: 'flex-start', justifyContent:'center', display:'flex', mt:8, flexDirection: "column", }} >
        {_.property(bio, cast_members) ? <BioDiscription img={cast_members[bio].img} bio={cast_members[bio].bio} /> : navigate('/about') }
      </Container>
    </Box>
  );
}


const biographies = [
  { img: mike_hs, bio: ['Mike Smith is the creator, producer and director of the viral film sensation Out of Shadows. That documentary – viewed by well over 100 million people worldwide – shed light on media manipulation and child trafficking. Since the 2020 release of Out of Shadows, Mike has become a Champion in the fight against media censorship and exposing the dark side of Hollywood.'] },
  { img: flynn_hs, bio: ['Lt. General Michael T. Flynn, USA (Ret.) dedicated his life to service to the nation with the U.S. Army for more than 33 years. His distinguished military career culminated as the Director of the Defense Intelligence Agency and as the nation’s highest-serving military intelligence officer.  Upon his retirement from the Army in 2014, he went on to serve in leadership positions in a variety of business, educational, and nonprofit organizations. He currently serves as America’s Future, Inc. Chair of the Board. '] },
  { img: bg_hs, bio: ['Brian Gamble is an accomplished data sciences professional and tech visionary with over three decades of experience in the industry. Throughout his distinguished career, Brian has left indelible marks across diverse sectors, holding pivotal positions including the role of Chief Information Officer for a prominent Fortune 500 enterprise.'] },
  { img: boone_hs, bio: [`Boone Cutler's journey from a combat veteran serving in Iraq to an influential advocate for the Warfighter community and fellow Americans is deeply intertwined with his literary works. In the heart of Sadr City Boone served as a Psychological Operations team sergeant. This frontline experience became the cornerstone for his first book, "CallSign Voodoo". Written in the throes of battle and completed during his recovery at Walter Reed Army Medical Center amid the 2007 neglect scandal, the book offers a raw, firsthand perspective of war and recovery.`] },
  { img: rose_hs, bio: [`Dr. Keith Rose maintains an active plastic surgery practice in Corpus Christi, Texas, The Rose Cosmetic Surgery Center.   He is also the CEO of Rose Medical Management LLC which provide medical management services, case management, and onsite clinics to over 100 companies across the United States and is CEO of the Doctors Center Urgent Care.   The Doctors Center Urgent Care (TDCUC) has 4 clinics in South Texas and has been in business for 23 years.`] },
  { img: lara_hs, bio: [`Lara Logan's candid  reporting, often from the most dangerous places in the world, has earned her a prominent place among the world's best foreign correspondents. In her role at 60 Minutes, Logan helped us understand the political and human conflicts around the world, including Pakistan, Iraq, Afghanistan, Israel and Palestine, and Egypt.`] },
];

const BioDiscription = ({ img, bio }) => {
  const bp = useMediaQuery((theme) =>theme.breakpoints.up("md"));
  return (  
    <Paper sx={{ py: "25px", px: 2,  background: 'linear-gradient(273deg, rgba(48,44,45,.9) 53%, rgba(150,153,161,0.52) 91%);', }}>
      <Box>
        <CardMedia component="img" src={img} sx={{ width: bp? '40%' : 1, height: '100%', objectFit: "cover", float: bp ? "left" : null, m: .5, mr: 3 }}/>
        {bio.map((item) => {
          return (
            <><Typography textAlign="justify">{item}</Typography><br/></>
          );
        })}
      </Box> 
    </Paper>
  );
}
