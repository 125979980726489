import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { Helmet } from "react-helmet";

// Components
import { Box, Button, Card, CardMedia, Container, CssBaseline, Divider, Fade, Grid, Modal, Paper, Skeleton, Stack, Step, StepLabel, Stepper, Switch, TextField, Typography, ToggleButton, ToggleButtonGroup } from "@mui/material";
import { FeedBack } from "../../components/CustomStyledComponents";

// Icons
import LockOpenIcon from "@mui/icons-material/LockOpen";

// Styling
import { alpha, createTheme, styled, ThemeProvider } from "@mui/material/styles";

// Redux
import { setToken, redeemSubCode, purchaseContent } from "../../redux/slices/user-slice";
import { orderGiftCodes } from "../../redux/slices/shop-slice";

// Data
import { getVideoById } from "../../redux/slices/content-slice";

// Consts
import { CONTENT, GIFT_CODE, BUY, RENT } from "../../services/constants/keys";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: { xs: "translate(-50%, -50%) scale(.75)", md: "translate(-50%, -50%)" },
  // width: 1200,
  bgcolor: alpha("#121212", 0.93),
  boxShadow: 5,
  p: 0,
  width: { xs: "100%", md: "auto" },
};

export default function BuyContentCode({ handleUnlockClose, open, video }) {
  return (
    <Modal
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      open={open}
      onClose={handleUnlockClose}
      closeAfterTransition
      slotProps={{
        backdrop: { timeout: 300 },
      }}>
      <Fade in={open}>
        <Box sx={style}>
          <RedeemCode handleUnlockClose={handleUnlockClose} video={video} />
        </Box>
      </Fade>
    </Modal>
  );
}

const steps = ["Purchase Options", "Review"];

function getStepContent(step, video, setPaymentToken, handleNext, setCodeQuantity, codeQuantity) {
  switch (step) {
    case 0:
      return <PaymentForm video={video} handleNext={handleNext} setPaymentToken={setPaymentToken} setCodeQuantity={setCodeQuantity} />;
    case 1:
      return <Review video={video} codeQuantity={codeQuantity}/>;
    default:
      throw new Error("Unknown step");
  }
}

const RedeemCode = ({ handleUnlockClose, video }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  // const selectedVideo = useSelector((state) => getVideoById(state, vidId));
  const selectedVideo = video;
  const [activeStep, setActiveStep] = useState(0);
  const [feedBack, setFeedback] = useState({passed: null, comp: null});
  const [redeemDisabled, setRedeemDisabled] = useState(false);
  const [paymentToken, setPaymentToken] = useState(null);
  const [codeQuantity, setCodeQuantity] = useState(null);
  const [alignment, setAlignment] = useState(RENT);

  useEffect(() => {
    console.log('alignment', alignment);
  },[alignment])
  
  
  const finishClick = async () => {
    handleUnlockClose();
    navigate("/user/orders");
  }

  const redeemCode = async () => {
    try {
      setFeedback({passed: null, comp: null})
      setRedeemDisabled(true);
      const order = {
        content_id: selectedVideo.id,
        quantity: parseInt(codeQuantity),
        payment_token: paymentToken,
        order_type: GIFT_CODE,
        purchase_type: alignment
      }
      console.log('order', order);
      const resp = await dispatch(orderGiftCodes(order)).unwrap();
      
      handleNext();
    } catch (exception) {
      setRedeemDisabled(false);
      console.log('ex', exception);
      const { detail } = exception;
      if (detail && detail.detail){
        const detail_2 = detail.detail;
        const { description } = detail_2;
        if (description) {
          setFeedback({passed: false, comp: <FeedBack passed={false} message={"Checkout failed. " + description} mt={1} />});
        } else {
          setFeedback({passed: false, comp: <FeedBack passed={false} message={"Checkout failed, please try again."} mt={1} />});
        }
      } else {
        setFeedback({passed: false, comp: <FeedBack passed={false} message={"Checkout failed, please try again."} mt={1} />});
      }
    }
  };

  const handleNext = () => {
    setActiveStep(activeStep + 1);
  };

  const handleBack = () => {
    setFeedback({passed: null, comp: null})
    setActiveStep(activeStep - 1);
  };

  useEffect(()=> {
    // console.log('payment tk', paymentToken)
  },[paymentToken])

  return (
    <React.Fragment>
      <Container disableGutters component="main" maxWidth="md" sx={{ pl: 0, height: "100%" }}>
        {/* <PaymentScript setToken={setPaymentToken} /> */}
        {/* <Helmet>
          <script type="text/javascript" src="https://www.bridgepaynetsecuretest.com/Bridgepay.WebSecurity/TokenPay/js/tokenPay.js" async="false" />
        </Helmet> */}
        <Paper variant="outlined" sx={{ p: 0 }}>
          <Box sx={{ background: `url(${selectedVideo.thumbnail_url})`, backgroundSize: "cover" }}>
            <Box
              sx={{
                background: "linear-gradient(132deg, rgba(255,255,255,.3) 0%, rgba(0,0,0,.3) 22%, rgba(0,0,0,0.7) 30%, rgba(0,0,0,0.7) 73%, rgba(0,0,0,.3) 82%, rgba(255,255,255,.3) 100%);",
                p: 1,
              }}>
              {/* <Box display="flex" justifyContent="center" gap={1} mt={2}>
                <Typography component="h5" variant="h6" align="center" mb={2} sx={{ fontSize: "1.4rem" }}>
                  Gift
                </Typography>
                <LockOpenIcon color="warning" />
              </Box>
              <Typography component="h1" variant="h3" align="center" mb={2}>
                " {selectedVideo.title} "
              </Typography> */}
            </Box>
          </Box>
          <Divider />

          <Container maxWidth="sm">
            <Stepper activeStep={activeStep} sx={{pb: { xs: 0, md: 0 } }}>
              {steps.map((label) => (
                <Step key={label}>
                  <StepLabel sx={{ fontSize: "1rem" }}>{label}</StepLabel>
                </Step>
              ))}
            </Stepper>
          </Container>

          {activeStep === steps.length ? (
            <Container maxWidth="md">
              <Box sx={{ display: "flex", justifyContent: "flex-end", flexDirection: "column", p: 1 }}>
                <ThemeProvider theme={createTheme(light)}>
                  <CssBaseline />
                  <Paper elevation={3} sx={{ p: 1 }}>
                    <Typography variant="h5" fontSize="1.25rem" gutterBottom>
                      Thank you for supporting the official release! Your codes can be viewed on your orders page under the menu options!
                    </Typography>
                  </Paper>
                </ThemeProvider>
                <Button variant="contained" onClick={finishClick} sx={{ mt: { xs: 0, md: 3 }, mt: 2,  borderRadius: "35px", padding: "8px 30px", maxWidth: "300px", alignSelf: "center"  }}>View Codes!</Button>
              </Box>
            </Container>
          ) : (
            <Box>
              {/* {getStepContent(activeStep, selectedVideo, setPaymentToken, handleNext, setCodeQuantity, codeQuantity)} */}
              {activeStep === 0 && <PaymentForm video={video} handleNext={handleNext} setPaymentToken={setPaymentToken} setCodeQuantity={setCodeQuantity} alignment={alignment} setAlignment={setAlignment} />}
              {activeStep === 1 && <Review video={video} codeQuantity={codeQuantity} alignment={alignment} />}
              <Box sx={{ display: "flex", justifyContent: "flex-end", }}>
                {activeStep !== 0 && (
                    <Button variant="contained" disabled={redeemDisabled} onClick={handleBack} sx={{alignSelf: "start", mr: 2}}>
                      Back
                    </Button>
                  )}
                {activeStep === 1 && <Button variant="contained" disabled={redeemDisabled} onClick={redeemCode}>Complete Purchase!</Button>}
              </Box>
              <Box sx={{float: "right", mr: 2}}>
                {feedBack.passed !== null && feedBack.comp}
              </Box>
            </Box>
          )}
        </Paper>
      </Container>
    </React.Fragment>
  );
};

const light = {
  palette: {
    mode: "light",
  },
};


function PaymentForm({ video, handleNext, setPaymentToken, setCodeQuantity, alignment, setAlignment }) {
  const [qty, setQuantity] = useState(1);
  // const [hiddenPrice, setHiddenPrice] = useState(qty * 100 * video.price);
  const [price, setPrice] = useState(video.price);
  const [pendingSubmit, setPendingSubmit] = useState(false);
  const [tokenPayClass, setTokenPayClass] = useState(null);
  
  
  useEffect(() => {
    // setHiddenPrice(qty * 100 * video.price);
    if(alignment === BUY) {
      setPrice(video.price);
    } else if (alignment === RENT) {
      setPrice(video.rent_price);
    }
  }, [alignment]);

  const createToken = (result) => {
    console.log('create token fired')
    console.log('TOKEN RESULT', result);
    setPaymentToken(result.token);
    setPendingSubmit(false);
    setCodeQuantity(qty);
    handleNext();
  }
  const createTokenErr = (result) => {
    setPendingSubmit(false);
    // console.log('theres an error');
    // console.log("error: " + result);
  }
  const submitClick = () => {
    // console.log('submitgot clicked');
    setPendingSubmit(true);
    tokenPayClass.createToken(createToken, createTokenErr);
  }

  const updateQuantity = (val) => {
    // if (parseInt(val) && val >= min && val <= max) {
    if(val === "" || (val > 0)) {
      setQuantity(val);
    }
  }

  return (
    <Box p={{ xs: 0, md: 1 }}>
      <Typography component="p" variant="body1" gutterBottom align="center">
        Purchase redeemable gift codes!
      </Typography>
      <ThemeProvider theme={createTheme(light)}>
        <CssBaseline />
        <Paper elevation={3} sx={{ p: 1 }}>
          <Box m={2}>
            <Stack direction="column" spacing={0} alignItems="center">
              <Card>
                <CardMedia component="img" height="200" image={video.thumbnail_url} />
              </Card>
              <Box>
                <PurchaseOptions video={video} alignment={alignment} setAlignment={setAlignment}  />
              </Box>
            </Stack>
          </Box>
          <Stack direction='row' spacing={1} alignItems='center' justifyContent='space-between' my={2} px={1}>
            <Box display='flex' flexDirection='row' alignItems='center'>
              <TextField label='Quantity' variant="standard" value={qty} sx={{width: 70}} onChange={e => updateQuantity(e.target.value)}/>
              <Typography variant="body1" sx={{ fontFamily: "Lora", fontSize: "1rem", mt: 2}} >x ${price}</Typography>
            </Box>
            <Typography variant="body2" sx={{ fontFamily: "Lora", fontSize: "1rem"}} >{qty > 0 ? `Total: $${(price * qty).toFixed(2)}` : ''}</Typography>
          </Stack>
          {
          <>
            <Box px={1}>
              <Typography variant="h6" gutterBottom>
                Payment method
              </Typography>
                <Grid container spacing={0} maxWidth="sm" component="form" id='paymentForm' visibility={pendingSubmit ? 'hidden' : 'visible'}>
                  <Grid item xs={12} md={12} id="card" />
                  <Grid item xs={12} md={12} id="amount" visibility='hidden' position='absolute'>{qty * 100 * price}</Grid>
                </Grid>
                <Typography id="errorMessage"/>
                {pendingSubmit ? <Skeleton variant="rectangular" width='100%' height={50}/> : <Button id='token-submit' type="submit" variant="contained" fullWidth onClick={submitClick} disabled={pendingSubmit || !parseInt(qty)}>Continue</Button>}
            </Box>
            <PaymentScript setTokenPayClass={setTokenPayClass} tokenPayClass={tokenPayClass}/>
          </>}
        </Paper>
      </ThemeProvider>
    </Box>
  );
}

function Review({ video, codeQuantity, alignment }) {
  let price = video.price;
  if (alignment === RENT) {
    price = video.rent_price;
  }
  return (
    <Box sx={{ p: 4 }}>
      <Typography variant="h6" gutterBottom mb={2}>
        Item summary
      </Typography>
      <ThemeProvider theme={createTheme(light)}>
        <CssBaseline />
        <Paper elevation={3} sx={{ p: 2 }}>
          <Stack direction="row" spacing={2} alignItems="center">
            <Box key={video.title}>
              <Card>
                <CardMedia component="img" height="100" image={video.thumbnail_url} />
              </Card>
            </Box>
            <Box p={2}>
              <Typography variant="h6">{video.title}</Typography>
              <Box display='flex' justifyContent='center' mt={1}>
                <Typography variant="h5" sx={{ fontFamily: "Lora", fontSize: "1rem"}}>{`${codeQuantity} codes!`}</Typography>
              </Box>
              {/* <Typography>{video.desc.substring(0, 30) + "..."}</Typography> */}
            </Box>
            <Box p={2}>
              <Typography variant="body2" sx={{ fontFamily: "Lora", fontSize: "1rem"}}>{'$' +(codeQuantity * price).toFixed(2)}</Typography>
            </Box>
          </Stack>
          <Box p={2}>
            <Typography variant="subtitle2" sx={{ fontFamily: "Lora", fontSize: "1rem", textDecoration: 'it'}}><i>if checkout fails, you may have to call your financial institution</i></Typography>
          </Box>
        </Paper>
      </ThemeProvider>
    </Box>
  );
}

function PurchaseOptions({video, alignment, setAlignment}) {
  const handleChange = (event, newAlignment) => {
    if (newAlignment !== alignment && newAlignment !== null) {
      setAlignment(newAlignment);
    }
  };

  return (
    <ToggleButtonGroup
      color="primary"
      value={alignment}
      exclusive
      onChange={handleChange}
      aria-label="purchase options"
    >
      <ToggleButton value={RENT} color="success" sx={{ height: '110px', width: '140px'}}>
        <Stack spacing={2}>
          {/* <CardMedia component="img" height="128" image={video.thumbnail_url} /> */}
          <Typography variant="body2" sx={{ fontFamily: "Lora", fontSize: "1rem"}} >Rent ${video.rent_price}</Typography>
          <Typography variant="subtitle1" sx={{ fontFamily: "Lora", fontSize: ".75rem", }} >Rent for 3 Days!</Typography>
        </Stack>
      </ToggleButton>
      <ToggleButton value={BUY} color="success" sx={{ height: '110px', width: '140px'}}>
        <Stack spacing={2}>
          {/* <CardMedia component="img" height="128" image={video.thumbnail_url}/> */}
          <Typography variant="body2" sx={{ fontFamily: "Lora", fontSize: "1rem"}} >Buy ${video.price}</Typography>
          <Typography variant="subtitle1" sx={{ fontFamily: "Lora", fontSize: ".75rem", }} >Own Forever!</Typography>
        </Stack>
      </ToggleButton>
    </ToggleButtonGroup>
  );
}

const PaymentScript = ({ setTokenPayClass, tokenPayClass }) => {
  useEffect(() => {
    const script = document.createElement("script");
    // DEV
    // const url = 'https://www.bridgepaynetsecuretest.com/Bridgepay.WebSecurity/TokenPay/js/tokenPay.js';
    
    // PROD
    const url = 'https://api.tpcgateway.com/WebSecurity/TokenPay/js/tokenPay.js';

    script.src = url
    document.body.appendChild(script);
    
    var callback = () => {
      if (tokenPayClass === null) {
        // DEV
        // const tokenpay = window.TokenPay('tokenpay56036api20231301011300303');
        // PROD
        const tokenpay = window.TokenPay('tokenpay1783api20234804074845160');
        tokenpay.initialize({
          dataElement: '#card',
          errorElement: '#errorMessage',
          amountElement: '#amount',
          //if displaying all 4 fields then useStyles=false, disableZip=false, disableCvv=false
          //if displaying 3 out of 4 fields then useStyles=false, and set disableZip or disableCvv equal to true
          //if displaying 2 out of 4 fields then useStyles=true, disableZip=true, disableCvv=true
          useStyles: false,
          useACH: false,
          disableZip: true,
          disableCvv: false
        });

        setTokenPayClass(tokenpay);
      }
    }

    script.onload = callback;
    

    return () => {
      delete window.TokenPay;
      document.body.removeChild(script);
    };
  },[]);
}
