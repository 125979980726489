import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";

// Components
import { Box, Button, Checkbox, CssBaseline, Fade, FormControlLabel, Modal, Paper, Stack, Tab, Tabs, TextField, Tooltip, Typography } from "@mui/material";

// Styles
import { alpha, createTheme, ThemeProvider } from "@mui/material/styles";

//Redux
import { loginUser, registerUser, forgotPassword } from "../../redux/slices/user-slice";

// export const alphaBackgroundColor = theme => alpha(theme.palette.background.paper, .90);
import { FeedBack, PasswordField, PasswordRequirements } from "../../components/CustomStyledComponents";

import { checkPassword, isEmail, isNullOrEmpty } from "../../services/utilities";

import gsap from "gsap";
import ScrollTrigger from "gsap/ScrollTrigger";
gsap.registerPlugin(ScrollTrigger);

const loginSingupStyles = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: alpha("#121212", 0.93),
  boxShadow: 5,
  p: 4,
};

const light = {
  palette: {
    mode: "light",
  },
};

export default function LoginOrRegisterModal({ handleLoginClose, open }) {
  return (
    <Box sx={{ position: "fixed" }}>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={open}
        onClose={handleLoginClose}
        closeAfterTransition
        slotProps={{
          backdrop: { timeout: 300 },
        }}>
        <Fade in={open}>
          <Box sx={loginSingupStyles}>
            <LoginSignupTabs />
          </Box>
        </Fade>
      </Modal>
    </Box>
  );
}
const ForgotPassword = () => {
  const dispatch = useDispatch();
  const [email, setEmail] = useState(null);
  const [isDisabled, setDisabled] = useState(true);
  const [feedBack, setFeedback] = useState({ passed: null, comp: null });

  useEffect(() => {
    if (isEmail(email)) {
      setDisabled(false);
    } else {
      setDisabled(true);
    }
  }, [email]);

  const forgotPW = async () => {
    const payload = {
      email: email
    };
    try {
      setDisabled(true);
      await dispatch(forgotPassword(payload)).unwrap();
      setFeedback({ passed: true, comp: <FeedBack passed={true} message="Password reset email sent!" mt={1} /> });
    } catch (exception) {
      setFeedback({ passed: false, comp: <FeedBack passed={false} message="Something went wrong, please try again" mt={1} /> });
      setDisabled(false);
    }
  };

  return(
    <>
      <Stack>
        <TextField required fullWidth label="E-mail" variant="standard" autoComplete="email" sx={{ mb: "1rem" }} onChange={(e) => setEmail(e.target.value)} />
        <Button variant="contained" size="small" disabled={isDisabled}  sx={{ mt: 3, width: "100%" }} onClick={forgotPW}>
          Send E-Mail
        </Button>
      </Stack>
      {feedBack.passed !== null && feedBack.comp}
    </>
  );
}
const Login = () => {
  const dispatch = useDispatch();
  const [password, setPassword] = useState(null);
  const [email, setEmail] = useState(null);
  const [isDisabled, setDisabled] = useState(true);
  const [rememberMe, setRememberMe] = useState(true);
  const [feedBack, setFeedback] = useState({ passed: null, comp: null });
  const [forgotMode, setForgotMode] = useState(false);

  useEffect(() => {
    if (email && password && isEmail(email)) {
      setDisabled(false);
    } else {
      setDisabled(true);
    }
  }, [email, password]);

  const loginClick = async () => {
    const payload = {
      userInfo: {
        email: email,
        password: password,
      },
      rememberUser: rememberMe,
    };
    try {
      setDisabled(true);
      await dispatch(loginUser(payload)).unwrap();
      setDisabled(false);
    } catch (exception) {
      setFeedback({ passed: false, comp: <FeedBack passed={false} message="Sign-in failed. Please try again" mt={1} /> });
      setDisabled(false);
    }
  };
  return (
    <React.Fragment>
      <ThemeProvider theme={createTheme(light)}>
        <CssBaseline />
        <Paper elevation={3} sx={{ p: 4, background: "white" }}>
          {forgotMode ? <ForgotPassword /> :
          
          <Stack component="form" spacing={2}>
            <TextField required fullWidth label="E-mail" variant="standard" autoComplete="email" sx={{ mb: "1rem" }} onChange={(e) => setEmail(e.target.value)} />
            <PasswordField
              required
              variant="standard"
              label="Password"
              autoComplete="current-password"
              onChange={(e) => setPassword(e.target.value)}
              onKeyPress={(e) => (e.code === "Enter" ? loginClick() : null)}
            />
            <FormControlLabel
              control={<Checkbox checked={rememberMe} />}
              label="Remember me"
              onChange={() => {
                setRememberMe(!rememberMe);
              }}
            />
            <Typography variant="subtitle1" color="steelblue"  sx={{ mt: 1, alignSelf: 'center', '&:hover': {cursor: 'pointer'}, fontWeight: 600, fontSize:'.75rem' }} onClick={()=>setForgotMode(true)}>
              Forgot Password
            </Typography>
          </Stack>
          }
        </Paper>
      </ThemeProvider>
      {!forgotMode && <Button variant="contained" size="large" disabled={isDisabled} sx={{ mt: 3, width: "100%" }} onClick={loginClick}>
        Sign In
      </Button>}
      {feedBack.passed !== null && feedBack.comp}
    </React.Fragment>
  );
};

const SignUp = () => {
  const dispatch = useDispatch();
  const [firstName, setFirstName] = useState(null);
  const [lastName, setLastName] = useState(null);
  const [email, setEmail] = useState(null);
  const [password, setPassword] = useState(null);
  const [isDisabled, setDisabled] = useState(true);
  const [feedBack, setFeedback] = useState({ passed: null, comp: null });
  const [isPwVisible, setIsPwVisible] = useState(false);

  useEffect(() => {
    if (isNullOrEmpty(firstName) || isNullOrEmpty(lastName) || isNullOrEmpty(email) || !isEmail(email) || !checkPassword(password)) {
      setDisabled(true);
    } else {
      setDisabled(false);
    }
  }, [firstName, lastName, email, password]);

  const registerClick = async () => {
    const payload = {
      first_name: firstName,
      last_name: lastName,
      email: email,
      password: password,
    };
    try {
      setDisabled(true);
      await dispatch(registerUser(payload)).unwrap();
      setFeedback({ passed: true, comp: <FeedBack passed={true} message="Success!" mt={1} /> });
      setDisabled(false);
    } catch (exception) {
      let msg = "Registeration failed. Please try again";
      if (exception.detail == "email already registered") {
        msg = "Email already registered, please sign in";
      }
      setFeedback({ passed: false, comp: <FeedBack passed={false} message={msg} mt={1} /> });
      setDisabled(false);
    }
  };
  return (
    <>
      <ThemeProvider theme={createTheme(light)}>
        <CssBaseline />
        <Paper elevation={3} sx={{ p: 4 }}>
          <Stack spacing={2} component="form">
            <Stack direction="row" spacing={1}>
              <TextField required label="First name" variant="standard" fullWidth onChange={(e) => setFirstName(e.target.value)} autoComplete="given-name" />
              <TextField required label="Last name" variant="standard" fullWidth onChange={(e) => setLastName(e.target.value)} autoComplete="family-name" />
            </Stack>
            <TextField required fullWidth autoComplete="email" label="E-mail" variant="standard" sx={{ mb: "1rem" }} onChange={(e) => setEmail(e.target.value)} />
            <Tooltip disableHoverListener open={isPwVisible} placement="top" title={<PasswordRequirements value={password} />}>
              <Box>
                <PasswordField required variant="standard" label="Password" autoComplete="new-password" onChange={(e) => setPassword(e.target.value)}  onFocus={()=>setIsPwVisible(true)} onBlur={() => setIsPwVisible(false)} onKeyPress={(e) => (e.code === "Enter" ? registerClick() : null)} />
              </Box>
            </Tooltip>
          </Stack>
        </Paper>
      </ThemeProvider>
      {/* <Typography variant="subtitle1">Privacy is one of ours top concerns, we will never sell your data.</Typography> */}
      <Button variant="contained" size="large" disabled={isDisabled} sx={{ mt: 3, width: "100%" }} onClick={registerClick}>
        Register
      </Button>
      {feedBack.passed !== null && feedBack.comp}
    </>
  );
};

const TabPanel = (props) => {
  const { children, value, index, ...other } = props;

  return (
    <div role="tabpanel" hidden={value !== index} {...other}>
      {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
    </div>
  );
};

const LoginSignupTabs = () => {
  const [value, setValue] = useState(0);

  const handleChange = (e, newValue) => {
    setValue(newValue);
  };

  return (
    <Box sx={{ width: "100%" }}>
      <Box sx={{ borderBottom: 1, borderColor: "divider", display: "flex", justifyContent: "center" }}>
        <Tabs value={value} onChange={handleChange} aria-label="basic tabs example" variant="fullWidth">
          <Tab label="Register" sx={{ fontSize: "1.25rem" }} />
          <Tab label="Sign In" sx={{ fontSize: "1.25rem" }} />
        </Tabs>
      </Box>
      <TabPanel value={value} index={0}>
        <Fade in>
          <Box>
            <SignUp />
          </Box>
        </Fade>
      </TabPanel>
      <TabPanel value={value} index={1}>
        <Fade in>
          <Box>
            <Login />
          </Box>
        </Fade>
      </TabPanel>
    </Box>
  );
};
