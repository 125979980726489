import axios from "axios";
import _ from "underscore";

import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { authAxios, axiosConfig, BASE_URL } from "../../services/client";
import { EXPIRED } from '../../services/constants/keys';

let initialState = {
  all_videos: [],
  selected_video: {
    vid_url: "",
    languages: [],
  }
};

export const contentSlice = createSlice({
  name: "content",
  initialState,
  reducers: {},
  extraReducers(builder) {
    loadContentCases(builder);
    getVideoUrlCases(builder);
  },
});

export const loadAllContent = createAsyncThunk("content/loadAllContent", async () => {
  const response = await authAxios.get("content/all", axiosConfig);
  return response.data;
});

const loadContentCases = (builder) =>
  builder
    .addCase(loadAllContent.pending, (state) => {
    })
    .addCase(loadAllContent.fulfilled, (state, action) => {
      state.all_videos = action.payload;
    })
    .addCase(loadAllContent.rejected, (state) => {
    });

export const getVideoUrl = createAsyncThunk("content/getVideoUrl", async (payload, { rejectWithValue } ) => {
  try {
    const { vid_id, watch_type, vid_language } = payload;
    const response = await authAxios.get(`content/url/${vid_id}/${watch_type}${vid_language ? '?lg=' + vid_language : ''}`, axiosConfig);
    return response.data;
  } catch (e) {
    return rejectWithValue(e.response.data)
  }
});

const getVideoUrlCases = (builder) =>
  builder
    .addCase(getVideoUrl.pending, (state) => {
    })
    .addCase(getVideoUrl.fulfilled, (state, action) => {
      state.selected_video.vid_url = action.payload.vid_url;
      state.selected_video.languages = action.payload.languages;
    })
    .addCase(getVideoUrl.rejected, (state, action) => {
      state.selected_video.vid_url = null;
      state.selected_video.languages = [];
      // const { detail } = action.payload;
      // if (detail && detail == 'Gone') {
      //   state.vid_url = EXPIRED;
      // } else {
      //   state.vid_url = null;
      // }
    });


export const loadVideo = createAsyncThunk("content/loadVideo", async (vid_id, { rejectWithValue }) => {
  const config = {
    headers: {
      "Content-Type": "application/json"
    }
  }
  const response = await axios.get(BASE_URL +`content/video/${vid_id}`, config);
  return response.data;
});

export const getForwardUrl = createAsyncThunk("content/getForwardUrl", async (payload, { rejectWithValue } ) => {
  try {
    const { vid_id } = payload;
    const response = await axios.get(BASE_URL + `content/forward/url/${vid_id}`, axiosConfig);
    return response.data;
  } catch (e) {
    return rejectWithValue(e.response.data)
  }
});


export const createScreeningForm = createAsyncThunk("content/createScreeningForm", async (payload, { rejectWithValue } ) => {
  try {
    const response = await axios.post(BASE_URL + `contact/screening`,payload, axiosConfig);
    return response.data;
  } catch (e) {
    return rejectWithValue(e.response.data)
  }
});

export const createMediaRequest = createAsyncThunk("content/createMediaRequest", async (payload, { rejectWithValue } ) => {
  try {
    const response = await axios.post(BASE_URL + `contact/media-request`,payload, axiosConfig);
    return response.data;
  } catch (e) {
    return rejectWithValue(e.response.data)
  }
});

// Action creators are generated for each case reducer function
// export const { increment, decrement, incrementByAmount } = contentSlice.actions

export const getVideoById = (state, videoId) => _.find(state.content.all_videos, (v) => v.id == videoId);

export default contentSlice.reducer;
