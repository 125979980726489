import React from "react";

// Layouts
import LoginModal from "./Modal/Login";
import SettingsModal from "./Modal/Settings";
import UnlockModal from "./Modal/Unlock";

// Default function to check which modal was called
export default function ModalCheck({ type, handleClose, open, vidId }) {
  let selectedModal = null;
  switch (type) {
    case "login":
      selectedModal = <LoginModal handleLoginClose={handleClose} open={open} />;
      break;
    case "settings":
      selectedModal = <SettingsModal handleSettingsClose={handleClose} open={open} />;
      break
    case "unlock":
      selectedModal = <UnlockModal handleUnlockClose={handleClose} open={open} vidId={vidId} />;
    default:
      break;
  }
  return (
    // <React.Fragment>
    //   {(() => {
    //     switch (type) {
    //       case "login":
    //         return <LoginModal handleLoginClose={handleClose} open={open} />;
    //       case "settings":
    //         return <SettingsModal handleSettingsClose={handleClose} open={open} />;
    //       case "unlock":
    //         return <UnlockModal handleUnlockClose={handleClose} open={open} vidId={vidId} />;
    //       default:
    //         return null;
    //     }
    //   })()}
    // </React.Fragment>
        <React.Fragment>
        {selectedModal}
      </React.Fragment>
  );
}
