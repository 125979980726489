import React, { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { Helmet } from "react-helmet";

// Components
import { Autocomplete, Box, Button, Card, CardContent, CardMedia, CardActionArea, Container, Checkbox, CssBaseline, Divider, Fade, FormControlLabel, Grid, Modal, Paper, Skeleton, Stack, Step, StepLabel, Stepper, Switch, TextField, Typography, ToggleButton, ToggleButtonGroup, Slider, Input, useMediaQuery, useTheme } from "@mui/material";
import { FeedBack, TextFieldStyled } from "../components/CustomStyledComponents";
import Header from "../layout/Header";
import CustomModals from "../layout/Modal";


// import Header
import { imageHero } from "../services/constants/dummyData";

// Icons
import LockOpenIcon from "@mui/icons-material/LockOpen";
import VolunteerActivismIcon from '@mui/icons-material/VolunteerActivism';

// Styling
import { alpha, createTheme, styled, ThemeProvider } from "@mui/material/styles";

// Redux

import { purchaseContent } from "../redux/slices/user-slice";
import { orderGiftCodes, payForwardOrder } from "../redux/slices/shop-slice";

// Data
import { getVideoById, createScreeningForm } from "../redux/slices/content-slice";

// Consts
import {  GIFT_CODE, BUY, RENT, STATES } from "../services/constants/keys";

const m_s_img = "https://itl-assets.b-cdn.net/thumbnails/mike-smith.jpg";


const light = {
  palette: {
    mode: "light",
  },
};

export default function BookScreeningPage() {
  const dispatch = useDispatch();
  const { is_verified } = useSelector((state) => state.user);
  const [settingsState, setSettingsState] = useState(false);
  const [loginState, setLoginState] = useState(false);
  const [payload, setPayload] = useState();
  const [feedBack, setFeedback] = useState({passed: null, comp: null});
  const [submitDisabled, setSubmitDisabled] = useState(false);

  const handleSettingsOpen = () => setSettingsState(true);
  const handleSettingsClose = () => setSettingsState(false);
  const handleLoginOpen =() => setLoginState(true);
  const handleLoginClose = () => setLoginState(false);


  const submitForm = async () => {
    try {
      setSubmitDisabled(true);
      await dispatch(createScreeningForm(payload)).unwrap();
      setFeedback({passed: true, comp: <FeedBack passed={true} message={"Form Submitted!"} mt={1} />});
    } catch (exception) {
      setSubmitDisabled(false);
      setFeedback({passed: false, comp: <FeedBack passed={false} message={"Form failed, please try again or email info.itlproductions@proton.me"} mt={1} />});
    }
  }


  return (
    <Box sx={{ backgroundImage: `url(${imageHero})`, backgroundSize: "cover", backgroundRepeat: "no-repeat", backgroundPosition: "center", width: "100%", height: "100%", position: "fixed", overflowY: "scroll" }}>
      {is_verified ? <Header type="videos" handleOpen={handleSettingsOpen} /> : <Header type="landing" handleOpen={handleLoginOpen} />}
      {settingsState && <CustomModals type="settings" handleClose={handleSettingsClose} open={settingsState} />}
      {loginState && <CustomModals type="login" handleClose={handleLoginClose} open={loginState} />}
      <Container sx={{mt: 10 }} maxWidth="md" >
        <Box mb={2} display='flex' flexDirection='row' alignItems='center'><Typography variant="h3" textAlign="center">Bring Into The Light To Your Organization!</Typography></Box>
        <ThemeProvider theme={createTheme(light)}>
          <BringToOrgForm setPayload={setPayload} />
        </ThemeProvider>
        <Button fullWidth variant="contained" sx={{ mt: 1 }} onClick={submitForm} disabled={submitDisabled}>Submit</Button>
        <Box sx={{float: "left", mr: 2}}>
          {feedBack.passed !== null && feedBack.comp}
        </Box>
      </Container>
    </Box>
  );
}

// up to 500 - $2,500 (button)
// 500 - 2,000 - $5,000 (button)
// 2,000 and up $7,500 (button)

const AUDIENCE_SIZE = [
  "up to 500",
  "500 - 2000",
  "2000+"
];
const PRICE_LIST = {
  "up to 500": "$2,500",
  "500 - 2000": "$5,000",
  "2000+": "$7,500",
}

const BringToOrgForm = ({ setPayload }) => {
  const theme = useTheme();
  const bp = useMediaQuery(theme.breakpoints.up("md"));
  const [firstName, setFirstName] = useState(null);
  const [lastName, setLastName] = useState(null);
  const [email, setEmail] = useState(null);
  const [orgName, setOrgName] = useState(null);

  const [addressOne, setAddressOne] = useState(null);
  const [addressTwo, setAddressTwo] = useState(null);
  const [city, setCity] = useState(null);
  const [state, setState] = useState(STATES[0]);
  const [zip, setZip] = useState(null);
  const [panelRequest, setPanelRequest] = useState(false);

  const [audienceSize, setAudienceSize] = useState(AUDIENCE_SIZE[0]);

  const payload = {
    first_name: firstName,
    last_name: lastName,
    email: email,
    org_name: orgName,
    address_one: addressOne,
    address_two: addressTwo,
    city: city,
    state_name: state,
    zip_code: zip,
    panel_request: panelRequest,
    audience_size: audienceSize
  };
  useEffect(() => {
    console.log('payload', payload);
    setPayload(payload);
  }, [firstName, lastName, email, orgName, addressOne, addressTwo, city, state, zip, panelRequest,audienceSize]);
  
  return (
    <>
    <Stack direction="column" bgcolor="white" mt={2} spacing={1} sx={{
      '& > :not(style)': { p: 1},
    }}>
      <Stack direction={bp ? "row" : "column"} spacing={2} sx={{ mb: 6 }}>
        <Box maxWidth="500px" alignSelf='center'>
          <CardMedia component="img" image={m_s_img} alt="Mike Smith"/>
        </Box>
        <Typography variant="body1" fontFamily="Oswald">
          “Thank you for your interest in hosting Into the Light at your church or organization! I know that that the powerful message that is revealed in this film will resonate with your congregation and bring about a shout for change.” Mike Smith
        </Typography>
      </Stack>
      <Stack direction={bp ? "row" : "column"} spacing={2}>
        <TextField required fullWidth label="First Name" onChange={(e) => setFirstName(e.target.value)} />
        <TextField required fullWidth label="Last Name"  onChange={(e) => setLastName(e.target.value)} />
      </Stack>
      <TextField required fullWidth label="Email" onChange={(e) => setEmail(e.target.value)} />
      <TextField required fullWidth label="Organization or Church" onChange={(e) => setOrgName(e.target.value)} />
      <TextField required fullWidth label="Address 1" onChange={(e) => setAddressOne(e.target.value)} />
      <TextField fullWidth label="Address 2" onChange={(e) => setAddressTwo(e.target.value)} />
      <Stack direction={bp ? "row" : "column"} spacing={2}>
        <TextField required fullWidth label="City" onChange={(e) => setCity(e.target.value)} />
        <Autocomplete required fullWidth value={state} options={STATES} onChange={(e, val) => setState(val)} renderInput={(params) => <TextField {...params} label="State" />}/>
        <TextField required fullWidth label="Zip Code" type="number" onChange={(e) => setZip(e.target.value)} />
      </Stack>
      <Autocomplete required fullWidth value={audienceSize} options={AUDIENCE_SIZE} onChange={(e, val) => setAudienceSize(val)} renderInput={(params) => <TextField {...params} label="Audience Size" />}/>
      {/* <Typography fullWidth>Cost: {PRICE_LIST[audienceSize]}</Typography> */}
      <FormControlLabel control={<Checkbox checked={panelRequest}  onChange={(e) => setPanelRequest(e.target.checked)} inputProps={{ 'aria-label': 'request panel' }} />} label="Check here if you would be interested in hosting a panel discussion with experts from Into the Light. This option will involve additional fees and our team will get in touch with you!" />
    </Stack>
    </>
  );
}