import React, { useEffect, useState } from "react";
import {  useDispatch } from "react-redux";
import { useParams } from "react-router-dom";

import { Box, Paper, Stack, Typography, TextField, Button, Container, Tooltip } from "@mui/material";
import Header from "../layout/Header";
import Modal from "../layout/Modal";
import { PasswordField, PasswordRequirements, FeedBack } from "../components/CustomStyledComponents";

//Redux
import { forgotPasswordReset } from "../redux/slices/user-slice";

import { isEmail, checkPassword } from "../services/utilities";

import { imageHero } from "../services/constants/dummyData";


export default function PasswordReset() {
  const [loginState, setLoginState] = useState(false);
  const handleLoginOpen =() => setLoginState(true);
  const handleLoginClose = () => setLoginState(false);
  return (
    <Box sx={{ backgroundImage: `url(${imageHero})`, backgroundSize: "cover", backgroundRepeat: "no-repeat", backgroundPosition: "center", width: "100%", height: "100vh", position: "fixed", overflowY: "scroll", display:'flex', alignItems: 'center' }}>
      <Header type="landing" handleOpen={handleLoginOpen} />
      {loginState && <Modal type="login" handleClose={handleLoginClose} open={loginState} />}
      <Container sx={{alignItems: 'center', justifyContent:'center', display:'flex' }} maxWidth="sm" >
        <Paper>
          <PasswordResetForm />
        </Paper>
      </Container>
    </Box>
  );
}

const PasswordResetForm = () => {
  const dispatch = useDispatch();
  const { code } = useParams();
  const [isDisabled, setIsDisabled] = useState(true);
  const [feedBack, setFeedback] = useState({passed: null, comp: null});
  const [email, setEmail] = useState(null);
  const [password, setPassword] = useState(null);
  const [isPwVisible, setIsPwVisible] = useState(false);

  useEffect(() => {
    if(isEmail(email) && checkPassword(password)) {
      setIsDisabled(false)
    } else {
      setIsDisabled(true)
    }

  },[email, password])

  const resetClick = async () => {
    try {
      setFeedback({passed: null, comp: null})
      setIsDisabled(true);
      const payload = {
        email: email,
        new_password: password,
        unique_code: code
      }
      const token = await dispatch(forgotPasswordReset(payload)).unwrap();
      setFeedback({passed: true, comp: <FeedBack passed={true} message={"Success! Please sign in"} mt={1} />});
    } catch (exception) { 
      setIsDisabled(false);
      const { detail } = exception;
      if (detail === "Not Acceptable") {
        setFeedback({passed: false, comp: <FeedBack passed={false} message={"Password didn't pass requirements"} mt={1} />});
      }
      if (detail === "Gone") {
        setFeedback({passed: false, comp: <FeedBack passed={false} message={"Your link has expired"} mt={1} />});
      }
    }
  }

  return(
    <Stack p={4} spacing={3}>
      <Box>
        <Typography
          variant="h4"
          noWrap
          sx={{
            fontWeight: 700,
            letterSpacing: ".3rem",
            textDecoration: "none",
            textTransform: "uppercase",
            flexGrow: 1,
            fontSize: '2rem'
          }}>
          Reset your Password
        </Typography>
      </Box>
        <Box><TextField required fullWidth autoComplete="email" placeholder="e-mail" variant="standard" sx={{ mb: "1rem" }} onChange={(e) => setEmail(e.target.value)} /></Box>
        <Tooltip disableHoverListener open={isPwVisible} placement="top" title={<PasswordRequirements value={password} />} >
          <Box>
            <PasswordField required variant="standard" placeholder="new-password" autoComplete="new-password" onChange={(e) => setPassword(e.target.value)} onFocus={()=>setIsPwVisible(true)} onBlur={() => setIsPwVisible(false)}/>
          </Box>
        </Tooltip>
      <Button size="large" disabled={isDisabled} sx={{ position: "relative", borderRadius: "35px", padding: "8px 30px", alignSelf: 'center' }}variant="contained" onClick={resetClick}>Reset Password</Button>
      {feedBack.passed !== null && feedBack.comp}
    </Stack>
  );
}