import React, { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
// import { Helmet } from "react-helmet";

// Components
import { Accordion, AccordionDetails, AccordionSummary, Box, Button, Card, CardMedia, Container, Checkbox, Divider, Link, FormControlLabel, Grid, Paper, Stack, TextField, Typography, ToggleButton, ToggleButtonGroup, Tooltip, Input, useMediaQuery, useTheme, Fade, Skeleton, } from "@mui/material";
import { alpha } from '@mui/material/styles';
import { PasswordField, PasswordRequirements, FeedBack } from "../components/CustomStyledComponents";
import Header from "../layout/Header";
import CustomModals from "../layout/Modal";
import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css";


import { isNullOrEmpty, isEmail, checkPassword } from "../services/utilities";


// import Header
import { imageHero } from "../services/constants/dummyData";

// Icons
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';

// Styling
import { createTheme, ThemeProvider } from "@mui/material/styles";

// Redux

import { purchaseContent, setToken, registerUser } from "../redux/slices/user-slice";
import { orderGiftCodes, payForwardOrder, setPromoCode } from "../redux/slices/shop-slice";

// Data
import { getVideoById } from "../redux/slices/content-slice";

// Consts
import {  GIFT_CODE, BUY, RENT, CONTENT, ITF_TRAILER, SHOP_URL } from "../services/constants/keys";

const itl_thumbnail = 'https://itl-assets.b-cdn.net/thumbnails/1920x1080_grey_oswald.jpeg';
const jbs_banner = 'https://itl-assets.b-cdn.net/thumbnails/jb_logo.png';


const alphaBackgroundColor80 = alpha('#121212', .8);

const REGISTER = "register";
const OWN = 'own';
const CODES = 'codes';
const PAY_FORWARD = 'pay_forward';
const PRICE_LIST = {
  [RENT]: 4.99,
  [BUY]: 9.99
}

export default function PurchasePage() {
  const dispatch = useDispatch();
  const [alignment, setAlignment] = useState(OWN);
  const { is_verified } = useSelector((state) => state.user);
  const [settingsState, setSettingsState] = useState(false);
  const [loginState, setLoginState] = useState(false);
  const navigate = useNavigate();

  const handleSettingsOpen = () => setSettingsState(true);
  const handleSettingsClose = () => setSettingsState(false);
  const handleLoginOpen =() => setLoginState(true);
  const handleLoginClose = () => setLoginState(false);

  const { promocode } = useParams();
  const reduxPromoCode = useSelector((state) => state.shop.promo_code);

  useEffect(() => {
    if(promocode && promocode !== "orders") {
      if (promocode !== reduxPromoCode) {
        dispatch(setPromoCode(promocode));
      }
    }
  },[promocode, reduxPromoCode]);
  // initiats popup on landing
  // useEffect(() => {
  //   if(!is_verified) {
  //     setLoginState(true);
  //   } else {
  //     setLoginState(false);
  //   }
  // }, [is_verified, loginState])
  
  return (
    <>
    {/* <PaymentScript setTokenPayClass={setTokenPayClass} tokenPayClass={tokenPayClass}/> */}
    <Box sx={{ backgroundImage: `url(${imageHero})`, backgroundSize: "cover", backgroundRepeat: "no-repeat", backgroundPosition: "center", width: "100%", height: "100%", position: "fixed", overflowY: "scroll" }}>
      {is_verified ? <Header type="videos" handleOpen={handleSettingsOpen} /> : <Header type="landing" handleOpen={handleLoginOpen} />}
      {settingsState && <CustomModals type="settings" handleClose={handleSettingsClose} open={settingsState} />}
      {loginState && <CustomModals type="login" handleClose={handleLoginClose} open={loginState} />}
      <Container sx={{mt: 10 }} maxWidth="md" >
        <Stack spacing={3}>
          { promocode === "jbs" && <CardMedia component="img" image={jbs_banner} /> }
          {/* { promocode === "jbs" ? null : <Paper sx={{ py: "25px", px: 2, bgcolor: alphaBackgroundColor80 }}>
             <Endorsements /> 
          </Paper> } */}
          <Paper sx={{ py: "25px", px: 2, bgcolor: alphaBackgroundColor80 }}>
            <Box mb={2} display='flex' flexDirection='row' alignItems='center' justifyContent="space-between"><Typography variant="h3">Ways To Watch</Typography><Button variant="contained" sx={{ mt: 1, width: 200 }} onClick={() => navigate("/redeem")}>Have a gift code? Redeem</Button></Box>
            <SelectPurchaseOption alignment={alignment} setAlignment={setAlignment} />
            {alignment === OWN && <BuyForYourself showLoginModal={handleLoginOpen} isVerified={is_verified} />}
            {alignment === CODES && <BuyContentCodes showLoginModal={handleLoginOpen} isVerified={is_verified} />}
            {/* {alignment === "PIF" && <PayItForward />} */}
          </Paper>
          <Typography variant="h3">Want a DVD?</Typography>
          <Paper sx={{ py: "25px", px: 2, bgcolor: alphaBackgroundColor80, display: "flex", flexDirection: "column", alignItems: "center" }}>
            <Link href={ promocode === "jbs" ? "https://intothelight.store/collections/jbs" : SHOP_URL } target='_blank'>
              <CardMedia component="img"  image="https://itl-assets.b-cdn.net/store_items/itf_dvd_doublefeature.webp" alt="dvd" sx={{ maxWidth: "380px" }} />
            </Link>
            <Typography variant="body1" sx={{ fontFamily: "Lora", fontSize: "1.2rem", textAlign: "center"}} >Purchase Into The Light for $14.99 or our DUAL PACK for $24.99!</Typography>
            {/* <Typography variant="body1" sx={{ fontFamily: "Lora", fontSize: "1rem"}} >Into The Light & Out of Shadows DOUBLE Feature! $24.99</Typography> */}
            <Button variant="contained" href={ promocode === "jbs" ? "https://intothelight.store/collections/jbs" : SHOP_URL } target='_blank'  sx={{ alignSelf: "center", mt: 1 }}>Click Here!</Button>
          </Paper>
          <Paper sx={{ py: "25px", px: 2, bgcolor: alphaBackgroundColor80 }}>
             <Endorsements /> 
          </Paper>
          <Paper sx={{ py: "25px", px: 2, bgcolor: alphaBackgroundColor80 }}>
            <MissionStatement />
          </Paper>
        </Stack>
      </Container>
    </Box>
    </>
  );
}

const endorsement_texts = [
  ['This film has been banned, doxxed, cyber attacked for all the wrong reasons. It’s time to sit down and watch it and gather friends and family around to do the same.', 'We need to maintain momentum to see, understand, and exploit the vulnerabilities and the enemy we are all facing. This film lays it all out. Thank you Director Mike Smith.', '— Lt. General Michael Flynn'],
  ['"Into the Light is one of the most powerful documentaries you will ever see. And you will have, as I did, that aha moment where you understand how you\'re being manipulated. This movie needs not only your viewership, but also your support."', '— Roger Stone'],
  ['“This film employs profoundly Ephesians 5:11: ‘And have no fellowship with the unfruitful works of darkness, but rather expose them.’ Every pastor and church in America needs to see this film!”', '— Rob McCoy, Pastor of Godspeak Calvary Chapel & Former Mayor of the City of Thousand Oaks'],
]

const Endorsements = () => {
  return(
    <Box mb={2}>
      <Box mb={1} display='flex' flexDirection='row' alignItems='center'><Typography variant="h3" mb={3}>What Others Are Saying</Typography></Box>
      <Carousel  autoPlay infiniteLoop interval={3000} showThumbs={false} showStatus={false} animationHandler="fade" showIndicators={true} showArrows={false}>
        {endorsement_texts.map(item => {
          return (
            <Fade in timeout={600}>
            <Stack spacing={1} mb={1} height={325}>
              {item.map(item => {
                return (<Typography paragraph variant="body1" textAlign="left">{item}</Typography>)
              })}
            </Stack>
            </Fade>
          )
        })}
      </Carousel>
      <Box width="100%" alignSelf='center' mb={3}>
        <video src={ITF_TRAILER} controls controlsList="nodownload"/>
        {/* <CardMedia component="img" image={itl_thumbnail}/> */}
      </Box>
    </Box>
  );
}

const MissionStatement = () => {
  return(
    <Stack spacing={2} my={2}>
      <Box mb={2} display='flex' flexDirection='row' alignItems='center'><Typography variant="h3">Our Mission</Typography></Box>
      <Typography paragraph variant="body1" maxWidth="sm" sx={{ mb: 4 }}>
        From the creators of Out of Shadows, we bring you Into the Light. Into the Light is a movie made to bring to surface that psychological operations are present and active in today's society. Into the Light will feature experts in psychological operations and mainstream media manipulation such as, General Michael Flynn, Lara Logan, Brian Gamble, Dr. Keith Rose, Boone Cutler and Mike Smith. This project brings an unbiased viewpoint to the control being done through psychological operations.
      </Typography>
      <Typography variant="body1" paragraph maxWidth="sm" sx={{ mb: 4 }}>
        We hope that this movie brings to light the problems that need to be addressed. Our goal is to activate a community of people who are not afraid to stand up for the truth and make a change. Enjoy!
      </Typography>
    </Stack>
  )
}

const SelectPurchaseOption = ({ alignment, setAlignment }) => {
  const handleChange = (e, newAlignment) => {
    if (newAlignment !== alignment && newAlignment !== null) {
      setAlignment(newAlignment);
    }
  };

  return (
    <Grid container>
      <ToggleButtonGroup
        color="primary"
        value={alignment}
        exclusive
        onChange={handleChange}
        aria-label="Platform"
        fullWidth
        sx={{ height: '5rem' }}
      >
        <ToggleButton value={OWN} color="success" >   
          <Stack spacing={2}>
            <Typography variant="body1" sx={{ fontFamily: "Lora", fontSize: "1rem", textShadow: "#FC0 1px 0 10px" }} >Purchase For Yourself!</Typography>
          </Stack>
        </ToggleButton>
        <ToggleButton value={CODES} color="success" >
          <Stack spacing={2}>
            <Typography variant="body1" sx={{ fontFamily: "Lora", fontSize: "1rem", textShadow: "#FC0 1px 0 10px" }} >Gift It Forward!</Typography>
          </Stack>
        </ToggleButton>
      </ToggleButtonGroup>
    </Grid>
  );
}


const BuyForYourself = ({ showLoginModal, isVerified  }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const reduxPromoCode = useSelector((state) => state.shop.promo_code);
  const [affiliateCode, setAffiliateCode] = useState(reduxPromoCode);

  useEffect(() => {
    if (affiliateCode !== reduxPromoCode) {
      setAffiliateCode(reduxPromoCode);
    }
  },[reduxPromoCode]);
  
  const user_subs = useSelector((state) => state.user.subscriptions);
  const [tokenPayClass, setTokenPayClass] = useState(null);
  const [paymentToken, setPaymentToken] = useState();
  const [processFee, setProcessFee] = useState(null);
  const [totalPrice, setTotalPrice] = useState(null);
  const [alignment, setAlignment] = useState(BUY);
  const [disableSubmit, setDisableSubmit] = useState(false);
  const [feedBack, setFeedback] = useState({passed: null, comp: null});

  const handleChange = (event, newAlignment) => {
    if (newAlignment !== alignment && newAlignment !== null) {
      setAlignment(newAlignment);
    }
  };
  const payload = {
    content_id: 1,
    payment_token: paymentToken,
    order_type: CONTENT,
    purchase_type: alignment,
    promo_code: affiliateCode ? affiliateCode : null,
  }
  
  const ContentAlreadyOwned = () => {
    return(
      <>
        <Typography variant="h6" textAlign="center" sx={{p: "5px"}}>This content is already owned! Please consider paying it forward!</Typography>
        <Typography variant="h6" textAlign="center" sx={{p: "5px"}}>Thank you for your support!</Typography>
      </>
    )
  }
  
  const submitPayment = async () => {
    try {
      const token = await dispatch(purchaseContent(payload)).unwrap();
      setFeedback({ passed: true, comp: <FeedBack passed={true} message="Purchase Completed, Thank You!" mt={1} /> });
      dispatch(setToken(token));
      setTimeout(() => {
        navigate("/");
      }, "1000");
      
    } catch (exception) {
      const { detail } = exception;
      if (detail && detail.detail){
        const detail_2 = detail.detail;
        const { description } = detail_2;
        if (description) {
          setFeedback({passed: false, comp: <FeedBack passed={false} message={"Checkout failed. " + description} mt={1} />});
        } else {
          setFeedback({passed: false, comp: <FeedBack passed={false} message={"Checkout failed, please try again."} mt={1} />});
        }
      } else {
        setFeedback({passed: false, comp: <FeedBack passed={false} message={"Checkout failed, please try again."} mt={1} />});
      }
      setDisableSubmit(false);
      console.log('subpaymetn ex', exception)
    }
  }

  useEffect(() =>{
    if (paymentToken) {
      console.log('payment token', paymentToken)
      submitPayment();
    }
  }, [paymentToken]);

  useEffect(() =>{
    setTotalPrice(PRICE_LIST[alignment] * 100);
  }, [alignment]);

  return (
    <Stack spacing={3} alignItems='flex-start' p={1} width={1}>
      
      {/* <Box maxWidth="600px" width={1}>
        <ToggleButtonGroup
          color="primary"
          value={alignment}
          exclusive
          onChange={handleChange}
          aria-label="Platform"
          fullWidth
        >
          <ToggleButton value={RENT} color="success" >
            <Stack spacing={2}>
              <Typography variant="body1" sx={{ fontFamily: "Lora", fontSize: "1rem"}} >Rent ${PRICE_LIST[RENT]}</Typography>
              <Typography variant="subtitle1" sx={{ fontFamily: "Lora", fontSize: ".75rem", }} >Rent for 3 Days!</Typography>
            </Stack>
          </ToggleButton>
          <ToggleButton value={BUY} color="success" >
            <Stack spacing={2}>
              <Typography variant="body1" sx={{ fontFamily: "Lora", fontSize: "1rem"}} >Buy ${PRICE_LIST[BUY]}</Typography>
              <Typography variant="subtitle1" sx={{ fontFamily: "Lora", fontSize: ".75rem", }} >Watch Forever!</Typography>
            </Stack>
          </ToggleButton>
        </ToggleButtonGroup>
      </Box> */}
      <Box alignSelf="center">
        <Typography variant="h5" mb={2}>Into The Light Now Available!</Typography>
        <Box maxWidth="600px" width={1}>
          
          <CardMedia component="img" image={itl_thumbnail}/>
          <Typography variant="body1" mt={.5}><b>Total:</b> ${PRICE_LIST[BUY]}</Typography>
        </Box>
      </Box>
      {user_subs.includes(1) ? <ContentAlreadyOwned/> : 
        <>
          <EnterPaymentInformation setTokenPayClass={setTokenPayClass} tokenPayClass={tokenPayClass} hiddenPrice={totalPrice} setPaymentToken={setPaymentToken} disableSubmit={disableSubmit} setDisableSubmit={setDisableSubmit} showLoginModal={showLoginModal} isVerified={isVerified} />
          {/* <TextField label='Affiliate code' variant="standard" value={affiliateCode} sx={{width: 325}} onChange={e => setAffiliateCode(e.target.value)}/> */}
        </>
      }
      {feedBack.passed !== null && feedBack.comp}
      {/* <PaymentScript setTokenPayClass={setTokenPayClass} tokenPayClass={tokenPayClass}/> */}
    </Stack>
  );
}

const PayItForward_Box = ({ selectedPriceIdx, setSelectedPriceIdx, setCustomPrice }) => {
  const setPrice = (val) => {
    if(val > 0) {
      setCustomPrice(parseInt(val) * 100)
    }
  }
  return(
    <Stack spacing={4}>
      <Box>
        <Typography variant="p" fontFamily='Oswald Light' textAlign='center'>Gift It Forward is an alternative purchase solution that allows viewers from around the world to enjoy the film and helps us fund our projects, server and bandwidth costs! If you are able, please consider paying it forward and contribute to our free viewing pool!</Typography>
      </Box>
      <Box display='flex' flexDirection='row' flexWrap='wrap' gap={2} justifyContent='center'>
        {payForwardOptions_1000.map((i,idx) => {
          return (<Button key={idx} variant="contained" sx={{bgcolor: selectedPriceIdx === idx ? 'skyblue' : null}} onClick={() => { setSelectedPriceIdx(idx); setCustomPrice(null)}}>{i.price_label}</Button>)
        })}
      </Box>
      {selectedPriceIdx === 8 && 
      <Box alignSelf='center'>
        <TextField label='Custom Amount $:' variant="filled" sx={{maxWidth: 360}} onChange={e => setPrice(e.target.value)}/>
      </Box>}
      <Box alignSelf='center'>
        <Typography variant="h5">{`Unlock ${payForwardOptions_1000[selectedPriceIdx].view_label} views`}</Typography>
      </Box>
    </Stack>
  );
}


const BuyContentCodes = ({ showLoginModal, isVerified, }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const reduxPromoCode = useSelector((state) => state.shop.promo_code);
  const [affiliateCode, setAffiliateCode] = useState(reduxPromoCode);
  const [selectedPriceIdx, setSelectedPriceIdx] = useState(0);
  const [customPrice, setCustomPrice] = useState(null)

  useEffect(() => {
    if (affiliateCode !== reduxPromoCode) {
      setAffiliateCode(reduxPromoCode);
    }
  },[reduxPromoCode]);

  // useEffect(() => {
  //   dispatch(setPromoCode(affiliateCode));
  // },[affiliateCode]);

  const [alignment, setAlignment] = useState(RENT);
  const [payForward, setPayForward] = useState(false);
  const [tokenPayClass, setTokenPayClass] = useState(null);
  const [paymentToken, setPaymentToken] = useState();
  const [processFee, setProcessFee] = useState(null);
  const [totalPrice, setTotalPrice] = useState(null);
  const [disableSubmit, setDisableSubmit] = useState(false);
  const [feedBack, setFeedback] = useState({passed: null, comp: null});
  // const [submitPayload, setSubmitPayload] = useState({});

  const [expanded, setExpanded] = useState(PAY_FORWARD);

  const handleAccordianChange = (panel) => (newExpanded) => {
    if (expanded !== panel) {
      setExpanded(newExpanded ? panel : false);
    }
  };

  const handleChange = (event, newAlignment) => {
    if (newAlignment !== alignment && newAlignment !== null) {
      setAlignment(newAlignment);
    }
  };
  const [qty, setQuantity] = useState(1);

  const updateQuantity = (val) => {
    // if (parseInt(val) && val >= min && val <= max) {
    if(val === "" || (val > 0)) {
      setQuantity(val);
    }
  }

  useEffect(() => {
    if (expanded === PAY_FORWARD) {
      setPayForward(true);
    } else {
      setPayForward(false);
    }
  }, [expanded]);

  // useEffect(() => {
  //   let payload = {
  //     content_id: 1,
  //     // quantity: null,
  //     payment_token: paymentToken,
  //     order_type: GIFT_CODE,
  //     purchase_type: alignment,
  //     promo_code: affiliateCode ? affiliateCode : null,
  //     add_to_pool: payForward
  //   }
  //   if (payForward) {
  //       payload.quantity = payForwardOptions_1000[selectedPriceIdx].view_raw;
  //   } else {
  //     payload.quantity = parseInt(qty);
  //   }
  //   setSubmitPayload(payload)

  // }, [payForward]);

  const submitPayload = {
    content_id: 1,
    quantity: payForward ? payForwardOptions_1000[selectedPriceIdx].view_raw : parseInt(qty),
    custom_price: selectedPriceIdx === 8 ? customPrice : null,
    payment_token: paymentToken,
    order_type: GIFT_CODE,
    purchase_type: alignment,
    promo_code: affiliateCode ? affiliateCode : null,
    add_to_pool: payForward
  }

  

  const submitPayment = async () => {
    try {
      console.log(submitPayload);
      
      // return;
      const resp = await dispatch(orderGiftCodes(submitPayload)).unwrap();
      setFeedback({ passed: true, comp: <FeedBack passed={true} message="Purchase Completed, Thank You!" mt={1} /> });
      console.log('payforward', resp);
      console.log('code payment submitted', submitPayload)
      setTimeout(() => {
        navigate("/user/orders");
      }, "1000");
      
    } catch (exception) {
      const { detail } = exception;
      if (detail && detail.detail){
        const detail_2 = detail.detail;
        const { description } = detail_2;
        if (description) {
          setFeedback({passed: false, comp: <FeedBack passed={false} message={"Checkout failed. " + description} mt={1} />});
        } else {
          setFeedback({passed: false, comp: <FeedBack passed={false} message={"Checkout failed, please try again."} mt={1} />});
        }
      } else {
        setFeedback({passed: false, comp: <FeedBack passed={false} message={"Checkout failed, please try again."} mt={1} />});
      }
      setDisableSubmit(false);
      console.log('subpaymetn ex', exception)
    }
  }

  // TODO: change this for payforward vs code
  useEffect(() =>{
    if (payForward) {
      if (selectedPriceIdx === 8) {
        setTotalPrice(customPrice)
      } else {
        setTotalPrice(payForwardOptions_1000[selectedPriceIdx].price_raw) 
      }
      // console.log('total price: ', payForwardOptions_1000[selectedPriceIdx].price_raw)
      // setTotalPrice(payForwardOptions_1000[selectedPriceIdx].price_raw)
    } else {
      if(qty && qty > 0) {
        setDisableSubmit(false);
      } else {
        setDisableSubmit(true);
      }
      setTotalPrice(parseInt((PRICE_LIST[alignment] * qty) * 100));
    }
    

  }, [alignment, qty, payForward, selectedPriceIdx, customPrice]);
  
  useEffect(() =>{
    if (paymentToken) {
      submitPayment();

    }
  }, [paymentToken]);

  useEffect(() =>{
    // setProcessFee(totalPrice / 100)
    const fee = parseInt((totalPrice / 1000) * 100);
    // const fee = (totalPrice / 10).toFixed(0);
    setProcessFee(fee);
  }, [totalPrice]);

  return (
    <Stack direction='column' spacing={3} alignItems='center' justifyContent='space-between' p={1}>
      <Typography variant="h5">Gift It Forward With Codes!</Typography>
      <Stack>
        <Accordion expanded={expanded === PAY_FORWARD} onChange={handleAccordianChange(PAY_FORWARD)}>
          <AccordionSummary><Typography variant="h5" mr={2} textAlign="center">Click To Gift It Forward</Typography><ArrowDownwardIcon fontSize="large" /></AccordionSummary>
          <AccordionDetails>
            <PayItForward_Box selectedPriceIdx={selectedPriceIdx} setSelectedPriceIdx={setSelectedPriceIdx} setCustomPrice={setCustomPrice} />
          </AccordionDetails>
        </Accordion>
        <Box alignSelf="center" my={2}><Typography variant="h5" textAlign="center">OR</Typography></Box>
        <Accordion expanded={expanded === CODES} onChange={handleAccordianChange(CODES)}>
          <AccordionSummary><Typography variant="h5" mr={2} textAlign="center">Click To Purchase Film Codes</Typography><ArrowDownwardIcon fontSize="large" /></AccordionSummary>
          <AccordionDetails sx={{ alignSelf: "center" }} >
            <Box width={1} alignSelf="center" sx={{ alignSelf: "center" }}>
              <Box mb={2}>
                <Typography variant="p" fontFamily='Oswald Light' textAlign='center'>Purchase redeemable codes you can share with anyone and allow them to unlock the film Into The Light!</Typography>
              </Box>
              <ToggleButtonGroup
                color="primary"
                value={alignment}
                exclusive
                onChange={handleChange}
                aria-label="Platform"
                fullWidth
              >
                <ToggleButton value={RENT} color="success" >
                  <Stack spacing={2}>
                    <Typography variant="body1" sx={{ fontFamily: "Lora", fontSize: "1rem"}} >Rent ${PRICE_LIST[RENT]}</Typography>
                    <Typography variant="subtitle1" sx={{ fontFamily: "Lora", fontSize: ".75rem", }} >Rent for 3 Days!</Typography>
                  </Stack>
                </ToggleButton>
                <ToggleButton value={BUY} color="success" >
                  <Stack spacing={2}>
                    <Typography variant="body1" sx={{ fontFamily: "Lora", fontSize: "1rem"}} >Buy ${PRICE_LIST[BUY]}</Typography>
                    <Typography variant="subtitle1" sx={{ fontFamily: "Lora", fontSize: ".75rem", }} >Watch Forever!</Typography>
                  </Stack>
                </ToggleButton>
              </ToggleButtonGroup>
            </Box>
            <Stack direction='row' spacing={1} alignItems='center' justifyContent='space-between' my={2} px={1} width={1} >
              <Box display='flex' flexDirection='row' alignItems='center' justifyContent="space-evenly">
                <TextField label='Quantity' variant="standard" value={qty} sx={{width: 120}} onChange={e => updateQuantity(e.target.value)}/>
                <Typography variant="body1" sx={{ fontFamily: "Lora", fontSize: "1rem", mt: 2}} >x ${PRICE_LIST[alignment]}</Typography>
              </Box>
              {/* <Typography variant="body1" sx={{ fontFamily: "Lora", fontSize: "1rem"}} >{qty > 0 ? `Total: $${(PRICE_LIST[alignment] * qty).toFixed(2)}` : ''}</Typography> */}
            </Stack>
            {/* <Stack direction="row" alignItems="center" spacing={0}><FormControlLabel control={<Checkbox checked={payForward}  onChange={(e) => setPayForward(e.target.checked)} inputProps={{ 'aria-label': 'request panel' }} />} /><Typography variant="subtitle1">Check here if you would like to give your codes to our Pay It Forward shared community pool!</Typography></Stack> */}
          </AccordionDetails>
        </Accordion>
      </Stack>
      <Stack spacing={1}>
        <Typography variant="body1" sx={{ fontFamily: "Lora", fontSize: "1rem"}} >{qty > 0 ? `Price: $${(totalPrice / 100).toFixed(2)}` : ''}</Typography>
        {/* <Typography variant="body1" sx={{ fontFamily: "Lora", fontSize: "1rem"}} >{qty > 0 ? `Processing: $${processFee }` : ''}</Typography>
        <Typography variant="body1" sx={{ fontFamily: "Lora", fontSize: "1rem"}} >{qty > 0 ? `Total: $${((totalPrice + processFee) / 100).toFixed(2)}` : ''}</Typography> */}
        {/* <Typography variant="body1" sx={{ fontFamily: "Lora", fontSize: "1rem"}} >{qty > 0 ? `hidden: $${totalPrice}` : ''}</Typography> */}
        <TextField label='Affiliate code' variant="standard" value={affiliateCode} sx={{width: 325}} onChange={e => setAffiliateCode(e.target.value)}/>
      </Stack>
      
      <EnterPaymentInformation setTokenPayClass={setTokenPayClass} tokenPayClass={tokenPayClass} hiddenPrice={totalPrice} setPaymentToken={setPaymentToken} disableSubmit={disableSubmit} setDisableSubmit={setDisableSubmit}showLoginModal={showLoginModal} isVerified={isVerified}/>
      {feedBack.passed !== null && feedBack.comp}
      {/* <PaymentScript setTokenPayClass={setTokenPayClass} tokenPayClass={tokenPayClass}/> */}
    </Stack>
  );
}

let payForwardOptions_1000 = [
  {
    price_label: '$10',
    price_raw: 1000,
    view_label: '5',
    view_raw: 5,

  },
  {
    price_label: '$20',
    price_raw: 2000,
    view_label: '12',
    view_raw: 12,
  },
  {
    price_label: '$50',
    price_raw: 5000,
    view_label: '30',
    view_raw: 30,
  },
  {
    price_label: '$100',
    price_raw: 10000,
    view_label: '60',
    view_raw: 60,
  },
  {
    price_label: '$250',
    price_raw: 25000,
    view_label: '150',
    view_raw: 150,
  },
  {
    price_label: '$500',
    price_raw: 50000,
    view_label: '300',
    view_raw: 300,
  },
  {
    price_label: '$750',
    price_raw: 75000,
    view_label: '500',
    view_raw: 500,
  },
  {
    price_label: '$1,000',
    price_raw: 100000,
    view_label: '700',
    view_raw: 700,
  },
  {
    price_label: 'Custom Amount',
    price_raw: 0,
    view_label: 'custom',
    view_raw: 0,
  }
];


const light = {
  palette: {
    mode: "light",
  },
};

const EnterPaymentInformation = ({ setTokenPayClass, tokenPayClass, hiddenPrice, setPaymentToken, disableSubmit, setDisableSubmit, showLoginModal, isVerified }) => {
  const dispatch = useDispatch();
  const { is_verified } = useSelector((state) => state.user);
  const [token, setToken] = useState();
  const [tries, setTries] = useState(0);
  const [registerPayload, setRegisterPayload] = useState();
  const [isRegAndPayDisabled, setRegAndPayDisabled] = useState(true);
  const [feedBack, setFeedback] = useState({passed: null, comp: null});
  
  const reduxPromoCode = useSelector((state) => state.shop.promo_code);
  const [affiliateCode, setAffiliateCode] = useState(reduxPromoCode);

  useEffect(() => {
    if (affiliateCode !== reduxPromoCode) {
      setAffiliateCode(reduxPromoCode);
    }
  },[reduxPromoCode]);

  const createTkn = (result) => {
    console.log('TOKEN RESULT', result);
    if (!result.token) {
      setTries(tries+1)
    }else {
      setToken(result.token);
      setPaymentToken(result.token);
      setToken(null);
      setTries(0);
    }
    
  }
  const createTokenErr = (result) => {
    setFeedback({passed: false, comp: <FeedBack passed={false} message={"Something went wrong, please refresh your page!"} mt={1} />});
    setDisableSubmit(false);
    console.log("error: " + result);
  }
  

  useEffect(() =>{
    if(!token && tries < 5 && tries !== 0) {
      console.log('tries', tries)
      getTokenAction();
    }
  }, [tries]);

  const getTokenAction = async () => {
    try {
      // if(!isVerified) {
      //   showLoginModal();
      //   return;
      // }
      if (tokenPayClass) {
        setDisableSubmit(true);
        tokenPayClass.createToken(createTkn, createTokenErr);
      }
    } catch (exception) {
      setFeedback({passed: false, comp: <FeedBack passed={false} message={"Something went wrong, please refresh your page!"} mt={1} />});
      window.location.reload();
      setDisableSubmit(false);
      console.log('ex',exception)
    }
  }

  const register_checkout = async () => {
    try {
      setFeedback({passed: null, comp: null})
      // setIsDisabled(true);
      const token = await dispatch(registerUser({ ...registerPayload })).unwrap();
      console.log('reg checkout token', token)
      await getTokenAction();

    } catch (exception) {
      console.log(exception)

    }
  }

  return(
    <Stack spacing={2}  px={1} alignItems="">
      <PaymentScript setTokenPayClass={setTokenPayClass} tokenPayClass={tokenPayClass}/>
      {is_verified ?
        <>
          <Box width='360px'>
            <PaymentScript setTokenPayClass={setTokenPayClass} tokenPayClass={tokenPayClass}/>
            <ThemeProvider theme={createTheme(light)}>
              <Typography variant="h6" gutterBottom mb={4}>
                Payment method
              </Typography>
              <Paper>
                <Box width={1}>
                  <Grid container spacing={0} component="form" id='paymentForm'>
                    <Grid item xs={12} md={12} id="card" />
                    <Grid item xs={12} md={12} id="amount" visibility='hidden' position='absolute'>{hiddenPrice}</Grid>
                  </Grid>
                  <Typography id="errorMessage"/>
                </Box>
              </Paper>
            </ThemeProvider>
            <TextField label='Affiliate Code?' variant="filled" size="small" value={affiliateCode} sx={{width: 360}} onChange={e => setAffiliateCode(e.target.value)}/>
          </Box>
          {disableSubmit ? <Skeleton variant="rounded" width={200} height={37} sx={{ display: "flex", alignItems: "center", justifyContent: "center" }}>Confirming...</Skeleton> : <Button variant="contained" sx={{ width: '200px', }} onClick={() => getTokenAction()}>Confirm Payment</Button>}
          {feedBack.passed !== null && feedBack.comp}
        </>
        :
        <>
          <Stack direction={{ xs: "column-reverse", md: "row" }} spacing={6}>
            <Stack spacing={3}>
              <Box>
                <Typography variant="h6" gutterBottom mb={2}>
                  Checkout Information
                </Typography>
                <SignUpTab setRegisterPayload={setRegisterPayload} setDisable={setRegAndPayDisabled} showLoginModal={showLoginModal} />
              </Box>
            </Stack>
            <Box>
              <Stack spacing={3}>
                <Box width='360px'>
                  <PaymentScript setTokenPayClass={setTokenPayClass} tokenPayClass={tokenPayClass}/>
                  <ThemeProvider theme={createTheme(light)}>
                    <Typography variant="h6" gutterBottom>
                      Payment method
                    </Typography>
                    <Paper>
                      <Box width={1}>
                        <Grid container spacing={0} component="form" id='paymentForm'>
                          <Grid item xs={12} md={12} id="card" />
                          <Grid item xs={12} md={12} id="amount" visibility='hidden' position='absolute'>{hiddenPrice}</Grid>
                        </Grid>
                        <Typography id="errorMessage"/>
                      </Box>
                    </Paper>
                  </ThemeProvider>
                </Box>
                <TextField label='Affiliate Code?' variant="filled" size="small" value={affiliateCode} sx={{width: 360}} onChange={e => setAffiliateCode(e.target.value)}/>
              </Stack>
            </Box>
          </Stack>
          <Button variant="contained" size="large" disabled={isRegAndPayDisabled} onClick={() => register_checkout()}>Register and Purchase!</Button>
        </>
      }   
      
    </Stack>
  );
}

const PaymentScript_org = ({ setTokenPayClass, tokenPayClass }) => {
  const [success, setSuccess] = useState(false);

  useEffect(() => {
    // const script = document.createElement("script");
    // // DEV
    // const url = 'https://www.bridgepaynetsecuretest.com/Bridgepay.WebSecurity/TokenPay/js/tokenPay.js';
    
    // // PROD
    // // const url = 'https://api.tpcgateway.com/WebSecurity/TokenPay/js/tokenPay.js';

    // script.src = url
    // document.body.appendChild(script);
    if(window['TokenPay']) {
      console.log('tkpay', window.TokenPay)
      setSuccess(true);
    } else {
      // DEV
      let script = document.createElement("script");
      // DEV
      const url = 'https://www.bridgepaynetsecuretest.com/Bridgepay.WebSecurity/TokenPay/js/tokenPay.js';
      
      // PROD
      // const url = 'https://api.tpcgateway.com/WebSecurity/TokenPay/js/tokenPay.js';

      // script.src = url
      // document.body.appendChild(script);

      script.setAttribute('src', url);
      document.body.appendChild(script);
      script.addEventListener("load", scriptLoaded, false);
      // const tokenpay = window.TokenPay('tokenpay56036api20231301011300303');
      // // PROD Batch
      // // const tokenpay = window.TokenPay('tokenpay1783api20234804074845160');

      // // PROD no-batch
      // // const tokenpay = window.TokenPay('tokenpay1803api20230721040747186');
      // tokenpay.initialize({
      //   dataElement: '#card',
      //   errorElement: '#errorMessage',
      //   amountElement: '#amount',
      //   //if displaying all 4 fields then useStyles=false, disableZip=false, disableCvv=false
      //   //if displaying 3 out of 4 fields then useStyles=false, and set disableZip or disableCvv equal to true
      //   //if displaying 2 out of 4 fields then useStyles=true, disableZip=true, disableCvv=true
      //   useStyles: false,
      //   useACH: false,
      //   disableZip: true,
      //   disableCvv: false
      // });
      // setTokenPayClass(tokenpay);
      return () => {
        script.removeEventListener("load", scriptLoaded);
      }
    }

   
    
    // var callback = () => {
    //   if (tokenPayClass === null) {
    //     // DEV
    //     const tokenpay = window.TokenPay('tokenpay56036api20231301011300303');
    //     // PROD Batch
    //     // const tokenpay = window.TokenPay('tokenpay1783api20234804074845160');

    //     // PROD no-batch
    //     // const tokenpay = window.TokenPay('tokenpay1803api20230721040747186');
    //     tokenpay.initialize({
    //       dataElement: '#card',
    //       errorElement: '#errorMessage',
    //       amountElement: '#amount',
    //       //if displaying all 4 fields then useStyles=false, disableZip=false, disableCvv=false
    //       //if displaying 3 out of 4 fields then useStyles=false, and set disableZip or disableCvv equal to true
    //       //if displaying 2 out of 4 fields then useStyles=true, disableZip=true, disableCvv=true
    //       useStyles: false,
    //       useACH: false,
    //       disableZip: true,
    //       disableCvv: false
    //     });
    //     setTokenPayClass(tokenpay);
    //   }
    // }

    // script.onload = callback;
    

    // return[success];
    // return () => {
      // delete window.TokenPay;
      // document.body.removeChild(script);
    // };
  },[tokenPayClass]);

  function scriptLoaded() {
    setSuccess(true);
    const tokenpay = window.TokenPay('tokenpay56036api20231301011300303');
    // PROD Batch
    // const tokenpay = window.TokenPay('tokenpay1783api20234804074845160');

    // PROD no-batch
    // const tokenpay = window.TokenPay('tokenpay1803api20230721040747186');
    tokenpay.initialize({
      dataElement: '#card',
      errorElement: '#errorMessage',
      amountElement: '#amount',
      //if displaying all 4 fields then useStyles=false, disableZip=false, disableCvv=false
      //if displaying 3 out of 4 fields then useStyles=false, and set disableZip or disableCvv equal to true
      //if displaying 2 out of 4 fields then useStyles=true, disableZip=true, disableCvv=true
      useStyles: false,
      useACH: false,
      disableZip: true,
      disableCvv: false
    });
    setTokenPayClass(tokenpay);
    
  }

}


const RegisterAndCheckOut = () => {
  <Stack direction={{sm: "column", md: "row"}} spacing={3} >


  </Stack>
}

const SignUpTab = ({ setRegisterPayload, setDisable, showLoginModal }) => {
  const [firstName, setFirstName] = useState(null);
  const [lastName, setLastName] = useState(null);
  const [email, setEmail] = useState(null);
  const [password, setPassword] = useState(null);
  const [rememberMe, setRememberMe] = useState(true);
  const [isPwVisible, setIsPwVisible] = useState(false);
  
  useEffect(() => {
    if (!isNullOrEmpty(firstName) && !isNullOrEmpty(lastName) && !isNullOrEmpty(email) && isEmail(email) && checkPassword(password)) {
      const payload = {
        first_name: firstName,
        last_name: lastName,
        email: email,
        password: password,
        type: REGISTER,
        remember_me: rememberMe
      };
      setRegisterPayload(payload);
      setDisable(false);
    } else {
      setDisable(true);
    }
  }, [firstName, lastName, email, password, rememberMe]);

  return (
    <>
      <Stack spacing={4} component="form" mt={1}>
        <Stack direction="row" spacing={1}>
          <TextField required placeholder="First name" variant="filled" fullWidth size="small" onChange={(e) => setFirstName(e.target.value)} autoComplete="given-name"/>
          <TextField required placeholder="Last name" variant="filled" fullWidth size="small" onChange={(e) => setLastName(e.target.value)} autoComplete="family-name"/>
        </Stack>
        <TextField required fullWidth autoComplete="email" placeholder="e-mail" size="small" variant="filled" sx={{ mb: "1rem" }} onChange={(e) => setEmail(e.target.value)} />
        <Box>
          <Tooltip disableHoverListener open={isPwVisible} placement="top" title={<PasswordRequirements value={password} />} >
            <Box>
              <PasswordField required variant="filled" placeholder="password" autoComplete="new-password" size="small" onChange={(e) => setPassword(e.target.value)} onFocus={()=>setIsPwVisible(true)} onBlur={() => setIsPwVisible(false)}/>
            </Box>
          </Tooltip>
          <Typography variant="subtitle2" color="lightblue" onClick={()=> showLoginModal()} sx={{'&:hover':{cursor: 'pointer'} }}>Have an account? Sign In</Typography>
        </Box>
      </Stack>
    </>
  );
}

// -------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------
const PaymentScript = ({ setTokenPayClass, tokenPayClass }) => {
  useEffect(() => {
    if (!tokenPayClass) {
      const script = document.createElement('script');
      // const script = document.createElement("script");
      // // DEV
      const url = 'https://www.bridgepaynetsecuretest.com/Bridgepay.WebSecurity/TokenPay/js/tokenPay.js';
      
      // // PROD
      // const url = 'https://api.tpcgateway.com/WebSecurity/TokenPay/js/tokenPay.js';
      script.src = url
      script.async = true;
      script.onload = () => scriptLoaded();

      document.body.appendChild(script);
      return () => {
        // scriptLoaded();
        document.body.removeChild(script);
      }
    }
    
    
  }, [tokenPayClass]);

  function scriptLoaded() {
    
    if (window['TokenPay']) {
      try {
        // DEV
        const tokenpay = window.TokenPay('tokenpay56036api20231301011300303');
        // PROD Batch
        // const tokenpay = window.TokenPay('tokenpay1783api20234804074845160');

        // PROD no-batch
        // const tokenpay = window.TokenPay('tokenpay1803api20230721040747186');
        tokenpay.initialize({
          dataElement: '#card',
          errorElement: '#errorMessage',
          amountElement: '#amount',
          //if displaying all 4 fields then useStyles=false, disableZip=false, disableCvv=false
          //if displaying 3 out of 4 fields then useStyles=false, and set disableZip or disableCvv equal to true
          //if displaying 2 out of 4 fields then useStyles=true, disableZip=true, disableCvv=true
          useStyles: false,
          useACH: false,
          disableZip: true,
          disableCvv: false
        });
        setTokenPayClass(tokenpay);

      } catch (exception) {
      }


    }
  }



}
