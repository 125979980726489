import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { Helmet } from "react-helmet";

// Components
import { Box, Button, Card, CardMedia, Container, CssBaseline, Divider, Fade, Grid, Modal, Paper, Skeleton, Stack, Step, StepLabel, Stepper, Switch, TextField, Typography, ToggleButton, ToggleButtonGroup } from "@mui/material";
import { FeedBack } from "../../components/CustomStyledComponents";

// Icons
import LockOpenIcon from "@mui/icons-material/LockOpen";

// Styling
import { alpha, createTheme, styled, ThemeProvider } from "@mui/material/styles";

// Redux
import { setToken, redeemSubCode, purchaseContent } from "../../redux/slices/user-slice";

// Data
import { getVideoById } from "../../redux/slices/content-slice";

// Consts
import { CONTENT, BUY, RENT } from "../../services/constants/keys";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: { xs: "translate(-50%, -50%) scale(.75)", md: "translate(-50%, -50%)" },
  // width: 1200,
  bgcolor: alpha("#121212", 0.93),
  boxShadow: 5,
  p: 0,
  width: { xs: "100%", md: "auto" },
  
};

export default function ReedeemCodeModal({ handleUnlockClose, open, vidId }) {
  return (
    <Modal
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      open={open}
      onClose={handleUnlockClose}
      closeAfterTransition
      slotProps={{
        backdrop: { timeout: 300 },
      }}>
      <Fade in={open}>
        <Box sx={style}>
          <RedeemCode handleUnlockClose={handleUnlockClose} vidId={vidId} />
        </Box>
      </Fade>
    </Modal>
  );
}

const steps = ["Purchase Options", "Review"];

function getStepContent(step, video, setUserCode, userCode, setPaymentToken, handleNext) {
  switch (step) {
    case 0:
      return <PaymentForm setUserCode={setUserCode} userCode={userCode} video={video} handleNext={handleNext} setPaymentToken={setPaymentToken}/>;
    case 1:
      return <Review video={video} userCode={userCode}/>;
    default:
      throw new Error("Unknown step");
  }
}

const RedeemCode = ({ handleUnlockClose, vidId }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const selectedVideo = useSelector((state) => getVideoById(state, vidId));
  const [activeStep, setActiveStep] = useState(0);
  const [userCode, setUserCode] = useState("");
  const [feedBack, setFeedback] = useState({passed: null, comp: null});
  const [redeemDisabled, setRedeemDisabled] = useState(false);
  const [paymentToken, setPaymentToken] = useState(null);
  const [alignment, setAlignment] = useState(RENT);
  
  const finishClick = async () => {
    handleUnlockClose();
    navigate(`/watch/${vidId}`);
  }

  const redeemCode = async () => {
    try {
      setFeedback({passed: null, comp: null})
      setRedeemDisabled(true);
      let token = null;
      if (userCode.length > 0) {
        token = await dispatch(redeemSubCode(userCode)).unwrap();
      } else {
        const order = {
          content_id: vidId,
          payment_token: paymentToken,
          order_type: CONTENT,
          purchase_type: alignment
        }
        console.log(order)
        token = await dispatch(purchaseContent(order)).unwrap();
        
      }
      dispatch(setToken(token));
      handleNext();
    } catch (exception) {
      setRedeemDisabled(false);
      const { detail } = exception;
      console.log(exception)
      if (detail === "Not Acceptable") {
        setFeedback({passed: false, comp: <FeedBack passed={false} message={"Code unavailable!"} mt={1} />});
      } else {
        if (detail && detail.detail){
          const detail_2 = detail.detail;
          const { description } = detail_2;
          if (description) {
            setFeedback({passed: false, comp: <FeedBack passed={false} message={"Checkout failed. " + description} mt={1} />});
          } else {
            setFeedback({passed: false, comp: <FeedBack passed={false} message={"Checkout failed, please try again."} mt={1} />});
          }
        } else {
          setFeedback({passed: false, comp: <FeedBack passed={false} message={"Checkout failed, please try again."} mt={1} />});
        }
      }

    }
  };

  const handleNext = () => {
    setActiveStep(activeStep + 1);
  };

  const handleBack = () => {
    setFeedback({passed: null, comp: null})
    setActiveStep(activeStep - 1);
  };

  useEffect(()=> {
    // console.log('payment tk', paymentToken)
  },[paymentToken]);

  return (
    <React.Fragment>
      <Container disableGutters component="main" maxWidth="md" sx={{ pl: 0, height: "100%" }}>
        {/* <PaymentScript setToken={setPaymentToken} /> */}
        {/* <Helmet>
          <script type="text/javascript" src="https://www.bridgepaynetsecuretest.com/Bridgepay.WebSecurity/TokenPay/js/tokenPay.js" async="false" />
        </Helmet> */}
        <Paper variant="outlined" sx={{ p: 0 }}>
          <Box sx={{ background: `url(${selectedVideo.thumbnail_url})`, backgroundSize: "cover" }}>
            <Box
              sx={{
                background: "linear-gradient(132deg, rgba(255,255,255,.3) 0%, rgba(0,0,0,.3) 22%, rgba(0,0,0,0.7) 30%, rgba(0,0,0,0.7) 73%, rgba(0,0,0,.3) 82%, rgba(255,255,255,.3) 100%);",
                p: 1,
              }}>
              {/* <Box display="flex" justifyContent="center" gap={1} mt={2}>
                <Typography component="h5" variant="h6" align="center" mb={2} sx={{ fontSize: "1.4rem" }}>
                  Unlock
                </Typography>
                <LockOpenIcon color="warning" />
              </Box>
              <Typography component="h1" variant="h3" align="center" mb={2}>
                " {selectedVideo.title} "
              </Typography> */}
            </Box>
          </Box>
          <Divider />

          <Container maxWidth="sm">
            <Stepper activeStep={activeStep} sx={{ pt: 2, pb: { xs: 0, md: 0 } }}>
              {steps.map((label) => (
                <Step key={label}>
                  <StepLabel sx={{ fontSize: "1rem" }}>{label}</StepLabel>
                </Step>
              ))}
            </Stepper>
          </Container>

          {activeStep === steps.length ? (
            <Container maxWidth="md">
              <Box sx={{ display: "flex", justifyContent: "flex-end", flexDirection: "column", p: 1 }}>
                <ThemeProvider theme={createTheme(light)}>
                  <CssBaseline />
                  <Paper elevation={3} sx={{ p: 1 }}>
                    <Typography variant="h5" fontSize="1.25rem" gutterBottom>
                      Thank you for supporting the official release!
                    </Typography>
                  </Paper>
                </ThemeProvider>
                <Button variant="contained" onClick={finishClick} sx={{ mt: { xs: 0, md: 3 }, mt: 2,  borderRadius: "35px", padding: "8px 30px", maxWidth: "300px", alignSelf: "center"  }}>Watch Now!</Button>
              </Box>
            </Container>
          ) : (
            <Box>
              {activeStep === 0 && <PaymentForm setUserCode={setUserCode} userCode={userCode} video={selectedVideo} handleNext={handleNext} setPaymentToken={setPaymentToken} alignment={alignment} setAlignment={setAlignment} />}
              {activeStep === 1 && <Review video={selectedVideo} userCode={userCode} alignment={alignment} />}
              <Box sx={{ display: "flex", justifyContent: "flex-end", }}>
                {activeStep !== 0 && (<Button variant="contained" disabled={redeemDisabled} onClick={handleBack} sx={{alignSelf: "start", mr: 2}}>Back</Button>)}
                {/* {activeStep === 0 && <Button variant="contained" disabled={userCode && userCode.length > 0 ? false : true} onClick={handleNext} sx={{ mt: { xs: 0, md: 3 }, ml: 1 }}>Next</Button>} */}
                {activeStep === 1 && <Button variant="contained" disabled={redeemDisabled} onClick={redeemCode}>Unlock Film!</Button>}
              </Box>
              <Box sx={{float: "right", m: 1}}>
                {feedBack.passed !== null && feedBack.comp}
              </Box>
            </Box>
          )}
        </Paper>
      </Container>
    </React.Fragment>
  );
};

const light = {
  palette: {
    mode: "light",
  },
};

const AntSwitch = styled(Switch)(({ theme }) => ({
  width: 28,
  height: 16,
  padding: 0,
  display: "flex",
  "&:active": {
    "& .MuiSwitch-thumb": {
      width: 15,
    },
    "& .MuiSwitch-switchBase.Mui-checked": {
      transform: "translateX(9px)",
    },
  },
  "& .MuiSwitch-switchBase": {
    padding: 2,
    "&.Mui-checked": {
      transform: "translateX(12px)",
      color: "#fff",
      "& + .MuiSwitch-track": {
        opacity: 1,
        backgroundColor: theme.palette.mode === "dark" ? "#177ddc" : "#1890ff",
      },
    },
  },
  "& .MuiSwitch-thumb": {
    boxShadow: "0 2px 4px 0 rgb(0 35 11 / 20%)",
    width: 12,
    height: 12,
    borderRadius: 6,
    transition: theme.transitions.create(["width"], {
      duration: 200,
    }),
  },
  "& .MuiSwitch-track": {
    borderRadius: 16 / 2,
    opacity: 1,
    backgroundColor: theme.palette.mode === "dark" ? "rgba(255,255,255,.35)" : "rgba(0,0,0,.25)",
    boxSizing: "border-box",
  },
}));

function PaymentForm({ setUserCode, userCode, video, handleNext, setPaymentToken, alignment, setAlignment }) {
  const hasCode = userCode.length > 0 ? true : false;
  // const [paymentToken, setPaymentToken] = useState(null);
  const [pendingSubmit, setPendingSubmit] = useState(false);
  const [tokenPayClass, setTokenPayClass] = useState(null);
//   useEffect(() => {
//     setChildKey(prev => prev + 1);
//  });
  const createToken = (result) => {
    console.log('create token fired')
    console.log('TOKEN SUBMITTED', result);
    setPaymentToken(result.token);
    setPendingSubmit(false);
    handleNext();
  }
  const createTokenErr = (result) => {
    setPendingSubmit(false);
    // console.log('theres an error');
    console.log("error: " + result);
  }
  const submitClick = () => {
    // console.log('submitgot clicked');
    setPendingSubmit(true);
    tokenPayClass.createToken(createToken, createTokenErr);
  }

  return (
    <Box p={{ xs: 0, md: 1 }}>
      <Typography component="p" variant="body1" gutterBottom align="center">
        {/* If you have a Redeem Code you can instantly unlock this video! */}
        {/* Unlock for free if you have a code! */}
      </Typography>
      <ThemeProvider theme={createTheme(light)}>
        <CssBaseline />
        <Paper elevation={3} sx={{ p: 1 }}>
          <Box m={2}>
            <Stack direction="column" spacing={0} alignItems="center">
              <Card>
                <CardMedia component="img" height="200" image={video.thumbnail_url} />
              </Card>
              <Box>
                <PurchaseOptions video={video} alignment={alignment} setAlignment={setAlignment} />
              </Box>
            </Stack>
          </Box>
          {
          <>
            <Box px={1}>
              <Stack direction='row' justifyContent='space-between' mt={2}>
                <Typography variant="h6" gutterBottom>
                  Payment method
                </Typography>
              </Stack>
                <Grid container spacing={0} maxWidth="sm" component="form" id='paymentForm'>
                  <Grid item xs={12} md={12} id="card" />
                  <Grid item xs={12} md={12} id="amount" visibility='hidden' position='absolute'>{alignment === BUY ? video.price : video.rent_price * 100}</Grid>
                </Grid>
                <Typography id="errorMessage"/>
                {pendingSubmit ? <Skeleton variant="rectangular" width='100%' height={50}/> : <Button id='token-submit' type="submit" variant="contained" mb={1} onClick={submitClick} disabled={pendingSubmit || hasCode} fullWidth sx={{float: 'center'}}>Continue</Button>}
            </Box>
            <PaymentScript setToken={setPaymentToken} nextStep={handleNext} setPendingSubmit={setPendingSubmit} setTokenPayClass={setTokenPayClass} tokenPayClass={tokenPayClass}/>
          </>}
          <Box mt={1} px={1}>
            <Typography variant="h6" gutterBottom mb={3}>
              Redeem Code
            </Typography>
            <Stack direction='row' spacing={2}>
              <TextField required id="redeemCode" fullWidth variant="standard" value={userCode} placeholder="Have a Code? Enter it here" onChange={(e) => setUserCode(e.target.value)} />
              <Button variant="contained" disabled={userCode && userCode.length > 0 ? false : true} onClick={handleNext} >Next</Button>
            </Stack>
          </Box>
        </Paper>
      </ThemeProvider>
    </Box>
  );
}

function Review({ video, userCode, alignment }) {
  let price = video.price;
  if (alignment === RENT) {
    price = video.rent_price;
  }
  return (
    <Box sx={{ p: 4 }}>
      <Typography variant="h6" gutterBottom mb={2}>
        Item summary
      </Typography>
      <ThemeProvider theme={createTheme(light)}>
        <CssBaseline />
        <Paper elevation={3} sx={{ p: 2 }}>
          <Stack direction="row" spacing={2} alignItems="center">
            <Box key={video.title}>
              <Card>
                <CardMedia component="img" height="100" image={video.thumbnail_url} />
              </Card>
            </Box>
            <Box p={2}>
              <Typography variant="h6">{video.title}</Typography>
              {/* <Typography>{video.desc.substring(0, 30) + "..."}</Typography> */}
            </Box>
            <Box p={2}>
              <Typography variant="body2" sx={{ fontFamily: "Lora", fontSize: "1rem"}}>{userCode.length > 0 ? 'Via Code': '$' +price}</Typography>
            </Box>
            
          </Stack>
          {!userCode && <Box p={2}>
            <Typography variant="subtitle2" sx={{ fontFamily: "Lora", fontSize: "1rem", textDecoration: 'it'}}><i>if checkout fails, you may have to call your financial institution</i></Typography>
          </Box>}
        </Paper>
      </ThemeProvider>
    </Box>
  );
}

function PurchaseOptions({video, alignment, setAlignment}) {
  const handleChange = (event, newAlignment) => {
    if (newAlignment !== alignment && newAlignment !== null) {
      setAlignment(newAlignment);
    }
  };

  return (
    <ToggleButtonGroup
      color="primary"
      value={alignment}
      exclusive
      onChange={handleChange}
      aria-label="Platform"
    >
      <ToggleButton value={RENT} color="success" sx={{ height: '110px', width: '140px'}}>
        <Stack spacing={2}>
          {/* <CardMedia component="img" height="128" image={video.thumbnail_url} /> */}
          <Typography variant="body2" sx={{ fontFamily: "Lora", fontSize: "1rem"}} >Rent ${video.rent_price}</Typography>
          <Typography variant="subtitle1" sx={{ fontFamily: "Lora", fontSize: ".75rem", }} >Rent for 3 Days!</Typography>
        </Stack>
      </ToggleButton>
      <ToggleButton value={BUY} color="success" sx={{ height: '110px', width: '140px'}}>
        <Stack spacing={2}>
          {/* <CardMedia component="img" height="128" image={video.thumbnail_url}/> */}
          <Typography variant="body2" sx={{ fontFamily: "Lora", fontSize: "1rem"}} >Buy ${video.price}</Typography>
          <Typography variant="subtitle1" sx={{ fontFamily: "Lora", fontSize: ".75rem", }} >Own Forever!</Typography>
        </Stack>
      </ToggleButton>
    </ToggleButtonGroup>
  );
}

const PaymentScript = ({setToken, nextStep, setPendingSubmit, setTokenPayClass, tokenPayClass}) => {
  useEffect(() => {
    // const head = document.querySelector("head");
    const script = document.createElement("script");
    // DEV
    // const url = 'https://www.bridgepaynetsecuretest.com/Bridgepay.WebSecurity/TokenPay/js/tokenPay.js';

    // NOTE: PROD
    const url = 'https://api.tpcgateway.com/WebSecurity/TokenPay/js/tokenPay.js';

    script.src = url
    // head.appendChild(script);
    document.body.appendChild(script);
    var form = document.getElementById('paymentForm');
    
    var callback = () => {
      if (tokenPayClass === null) {
        // DEV
        // const tokenpay = window.TokenPay('tokenpay56036api20231301011300303');
        // PROD
        const tokenpay = window.TokenPay('tokenpay1783api20234804074845160');
        tokenpay.initialize({
          dataElement: '#card',
          errorElement: '#errorMessage',
          amountElement: '#amount',
          //if displaying all 4 fields then useStyles=false, disableZip=false, disableCvv=false
          //if displaying 3 out of 4 fields then useStyles=false, and set disableZip or disableCvv equal to true
          //if displaying 2 out of 4 fields then useStyles=true, disableZip=true, disableCvv=true
          useStyles: false,
          useACH: false,
          disableZip: true,
          disableCvv: false
        });

        setTokenPayClass(tokenpay);
      }
      
      // const submitEvent = (event) => {
      //   event.preventDefault();

      //   // TODO: assign tokenpay variable to a react variable, call submit function within JSX onClick event
      //   tokenpay.createToken(function (result) {
      //     var hiddenInput = document.createElement('input');
      //     hiddenInput.setAttribute('type', 'hidden');
      //     hiddenInput.setAttribute('name', 'token');
      //     hiddenInput.setAttribute('value', result.token);
      //     form.appendChild(hiddenInput);
      //     console.log('TOKEN SUBMITTED', result.token);
      //     setToken(result.token);
      //     setPendingSubmit(false);
      //     // FIX: listener not being removed, refactor this
      //     form.removeEventListener('submit', submitEvent);
      //     nextStep();
      //     // form.submit();
      //   }, function (result) {
      //       setPendingSubmit(false);
      //       console.log('theres an error');
      //       console.log("error: " + result);
      //   });
      // }

      // form.addEventListener('submit', submitEvent );
    }

    script.onload = callback;
    

    return () => {
      // var form = document.getElementById('paymentForm');
      delete window.TokenPay;
      
      // form.removeEventListener('submit', submitEvent);
      // head.removeChild(script);
      document.body.removeChild(script);
    };
  },[]);
}
