import React, { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { Helmet } from "react-helmet";

// Components
import { Autocomplete, Box, Button, Card, CardContent, CardMedia, CardActionArea, Container, Checkbox, CssBaseline, Divider, Fade, FormControlLabel, Grid, Modal, Paper, Skeleton, Stack, Step, StepLabel, Stepper, Switch, TextField, Typography, ToggleButton, ToggleButtonGroup, Slider, Input, useMediaQuery, useTheme } from "@mui/material";
import { FeedBack, TextFieldStyled } from "../components/CustomStyledComponents";
import Header from "../layout/Header";
import CustomModals from "../layout/Modal";


// import Header
import { imageHero } from "../services/constants/dummyData";

// Icons
import LockOpenIcon from "@mui/icons-material/LockOpen";
import VolunteerActivismIcon from '@mui/icons-material/VolunteerActivism';

// Styling
import { alpha, createTheme, styled, ThemeProvider } from "@mui/material/styles";

// Redux

import { purchaseContent } from "../redux/slices/user-slice";
import { orderGiftCodes, payForwardOrder } from "../redux/slices/shop-slice";

// Data
import { getVideoById, createScreeningForm, createMediaRequest } from "../redux/slices/content-slice";




const light = {
  palette: {
    mode: "light",
  },
};

export default function MediaRequestPage() {
  const dispatch = useDispatch();
  const { is_verified } = useSelector((state) => state.user);
  const [settingsState, setSettingsState] = useState(false);
  const [loginState, setLoginState] = useState(false);
  const [payload, setPayload] = useState();
  const [feedBack, setFeedback] = useState({passed: null, comp: null});
  const [submitDisabled, setSubmitDisabled] = useState(false);

  const handleSettingsOpen = () => setSettingsState(true);
  const handleSettingsClose = () => setSettingsState(false);
  const handleLoginOpen =() => setLoginState(true);
  const handleLoginClose = () => setLoginState(false);


  const submitForm = async () => {
    try {
      setSubmitDisabled(true);
      await dispatch(createMediaRequest(payload)).unwrap();
      setFeedback({passed: true, comp: <FeedBack passed={true} message={"Request Submitted!"} mt={1} />});
    } catch (exception) {
      setSubmitDisabled(false);
      setFeedback({passed: false, comp: <FeedBack passed={false} message={"Form failed, please try again or email info.itlproductions@proton.me"} mt={1} />});
    }
  }


  return (
    <Box sx={{ backgroundImage: `url(${imageHero})`, backgroundSize: "cover", backgroundRepeat: "no-repeat", backgroundPosition: "center", width: "100%", height: "100%", position: "fixed", overflowY: "scroll" }}>
      {is_verified ? <Header type="videos" handleOpen={handleSettingsOpen} /> : <Header type="landing" handleOpen={handleLoginOpen} />}
      {settingsState && <CustomModals type="settings" handleClose={handleSettingsClose} open={settingsState} />}
      {loginState && <CustomModals type="login" handleClose={handleLoginClose} open={loginState} />}
      <Container sx={{mt: 10 }} maxWidth="md" >
        <Box mb={2} display='flex' flexDirection='row' alignItems='center'><Typography variant="h3" textAlign="center">For Media Requests</Typography></Box>
        <ThemeProvider theme={createTheme(light)}>
          <MediaRequestForm setPayload={setPayload} />
        </ThemeProvider>
        <Button fullWidth variant="contained" sx={{ mt: 1 }} onClick={submitForm} disabled={submitDisabled}>Submit</Button>
        <Box sx={{float: "left", mr: 2}}>
          {feedBack.passed !== null && feedBack.comp}
        </Box>
      </Container>
    </Box>
  );
}


const MediaRequestForm = ({ setPayload }) => {
  const theme = useTheme();
  const bp = useMediaQuery(theme.breakpoints.up("md"));
  const [firstName, setFirstName] = useState(null);
  const [lastName, setLastName] = useState(null);
  const [email, setEmail] = useState(null);
  const [orgName, setOrgName] = useState(null);
  const [inquiry, setInquiry] = useState(null);

  const payload = {
    first_name: firstName,
    last_name: lastName,
    email: email,
    org_name: orgName,
    inquiry: inquiry,
  };
  useEffect(() => {
    console.log('payload', payload);
    setPayload(payload);
  }, [firstName, lastName, email, orgName, inquiry]);
  
  return (
    <>
    <Stack direction="column" bgcolor="white" mt={2} spacing={1} sx={{
      '& > :not(style)': { p: 1},
    }}>
      <Stack direction={bp ? "row" : "column"} spacing={2}>
        <TextField required fullWidth label="First Name" onChange={(e) => setFirstName(e.target.value)} />
        <TextField required fullWidth label="Last Name"  onChange={(e) => setLastName(e.target.value)} />
      </Stack>
      <TextField required fullWidth label="Email" onChange={(e) => setEmail(e.target.value)} />
      <TextField required fullWidth label="Media Organization" onChange={(e) => setOrgName(e.target.value)} />
      <TextField required fullWidth label="Inquiry" multiline rows={6} onChange={(e) => setInquiry(e.target.value)} />
      
    </Stack>
    </>
  );
}