import React from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import Redeem from "./ui/Redeem";
import IntoTheLight from "./ui/Landing/IntoTheLight";
import PasswordReset from "./ui/PasswordReset";
import About from "./ui/About";
import VideoPublic from "./ui/VideoPublic";
import PurchasePage from "./ui/Purchase";
import PurchasePage_orig from "./ui/Purchase_orig";
import OrderHistory from "./ui/OrderHistory";
import BookScreeningPage from "./ui/BookScreening";
import MediaRequestPage from "./ui/MediaRequest";
import PolicyPage from "./ui/Policies";
import Bio from "./ui/Bio";


export default function PublicRouter() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<IntoTheLight />}></Route>
        <Route path="/book-screening" element={<BookScreeningPage />}></Route>
        <Route path="/media-request" element={<MediaRequestPage />}></Route>
        <Route path="/redeem" element={<Redeem />}></Route>
        <Route path="/redeem/:code" element={<Redeem />}></Route>
        <Route path="/reset/:code" element={<PasswordReset />}></Route>
        <Route path="/about" element={<About />}></Route>
        <Route path="/policies" element={<PolicyPage />}></Route>
        {/* <Route path="/user/orders" element={<OrderHistory />}></Route> */}
        {/* <Route path="/pub/watch/:videoId" element={<VideoPublic />}></Route> */}
        {/* <Route path="/purchase" element={<PurchasePage />}></Route> */}
        <Route path="/purchase" element={<PurchasePage />}></Route>
        <Route path="/bio" element={<Bio />}></Route>
        <Route path="/bio/:bio" element={<Bio />}></Route>
        <Route path="/:promocode" element={<PurchasePage />}></Route>
        <Route path="*" element={<IntoTheLight />}></Route>
      </Routes>
    </BrowserRouter>
  );
}
