import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import _ from "underscore";

// redux
import { getCodeHistory, getOrderHistory } from "../redux/slices/shop-slice";

// Components
import { Box, Button, CardMedia, Container, Divider, Fade, Grid, Stack, Tab, Tabs, Typography, useMediaQuery, useTheme } from "@mui/material";
import { alpha } from '@mui/material/styles';
import { GoToTop } from "../components/CustomStyledComponents";
import ModalCheck from "../layout/Modal";

// Layout
import Header from "../layout/Header";
import Sidebar from "../layout/Sidebar";


// Assets
import { imageHero } from "../services/constants/dummyData";
import { CONTENT, GIFT_CODE, MERCHANDISE, SHOP_URL } from "../services/constants/keys";

// Colors
const alphaBackgroundColor50 = alpha('#121212', .5);
const alphaBackgroundColor70 = alpha('#121212', .7);

export default function OrderHistory() {
  // d.toLocaleDateString(undefined, {year: 'numeric', month: 'long', day: 'numeric'})
  const theme = useTheme();
  const bp = useMediaQuery(theme.breakpoints.up("md"));
  const dispatch = useDispatch();
  
  useEffect(() => {
    dispatch(getOrderHistory());
    dispatch(getCodeHistory());
  }, [])

  const [settingsState, setSettingsState] = useState(false);
  const handleSettingsOpen = () => setSettingsState(true);
  const handleSettingsClose = () => setSettingsState(false);

  return (
    <Box sx={{ backgroundImage: `url(${imageHero})`, backgroundSize: "cover", backgroundRepeat: "no-repeat", backgroundPosition: "center", backgroundAttachment: "fixed", minHeight: '100vh', width: '100%',  }}>
      {bp ? <Sidebar /> : null}
      <Header type="videos" handleOpen={handleSettingsOpen} />
      <Box sx={{ display: "flex", minHeight: "100vh", left: "60px", right: "0", width: { xs: "100%", md: "calc(100vw - 60px)" }, ml: { xs: "0", md: "60px" }, pt: { xs: "50px", md: "50px" },  }}>
        <Container maxWidth='lg' sx={{ mt: '3.5rem', display: 'flex', flexDirection: 'column', minHeight: 'inherit' }}>
          <History />
        </Container>
      </Box>
      <GoToTop />
      {settingsState && <ModalCheck type="settings" handleClose={handleSettingsClose} open={settingsState} />}
    </Box>
  );
}

const History = () => {
  const order_history =  useSelector(state => state.shop.order_history);
  // const order_history_sorted = order_history.sort((a, b) => b.id - a.id);
  const [value, setValue] = useState(0);

  // console.log('order history', order_history);

  const handleChange = (e, newValue) => {
    setValue(newValue);
  };
  // elems.sort((a, b) => a.id - b.id);
  

  return (
    <Grid container display='flex'>
      <Grid item xs='12' >
        <Box display='flex' flexDirection='row' justifyContent='space-between'>
          <Typography  variant="h1"  sx={{ fontSize: { xs: "2rem", lg: "8rem" }, alignSelf:'center' }}>
            Order History
          </Typography>
        </Box>
        <Divider flexItem sx={{ borderBottomWidth: 'medium', my: 2 }} />
      </Grid>
      <Box sx={{ width: "100%" }}>
        <Box sx={{ borderBottom: 1, borderColor: "divider", display: "flex", justifyContent: "center", pb: 1, bgcolor: alphaBackgroundColor70 }}>
          <Tabs value={value} onChange={handleChange} aria-label="order history tabs" variant="fullWidth" sx={{width: '100%' }}>
            <Tab label="Orders" sx={{ fontSize: "1.25rem" }} />
            {/* <Tab label="Codes" sx={{ fontSize: "1.25rem" }} /> */}
          </Tabs>
        </Box>
        <TabPanel value={value} index={0}>
          <Fade in>
            <Box>
              {order_history.length > 0 ?
                (order_history.map((item, idx) => <Grid item key={idx} xs='12'><HistoryItem item={item}/></Grid>)) :
                <Grid item xs='12'><EmptyHistory text='Your Order History is empty' /></Grid>
              }
            </Box>
          </Fade>
        </TabPanel>
        <TabPanel value={value} index={1}>
          <Fade in>
            <Box>
              <CodeHistory />
            </Box>
          </Fade>
        </TabPanel>
      </Box>
    </Grid>
  );
}

const EmptyHistory = ( {text} ) => {
  const navigate = useNavigate();
  return (
    <Stack bgcolor={alphaBackgroundColor50} alignItems='center' spacing={24} p={2} borderRadius={1}>
      <Typography variant="h3" textAlign='center' mt={6}>{text}, checkout our shop!</Typography>
      <Button size="large" href={SHOP_URL} target="_blank">Visit Shop</Button>
    </Stack>
  )
}

const HistoryItem = ({ item }) => {
  const {created_at, order_number, order_status, total_price, user_order, order_type} = item;
  // console.log('order_type', order_type, item)
  const item_obj = JSON.parse(user_order);
  const order_date = new Date(created_at+'Z');

  return (
    <>
      <Stack direction='row' bgcolor={alphaBackgroundColor70}>
        <Grid item xs={6}>
          <Stack direction='column' py={1} ml={{xs: 1, sm: 1, md:4}}>
            <Typography>Order# {order_number}</Typography>
            <Typography variant="subtitle1">{order_date.toLocaleDateString()}</Typography>
          </Stack>
        </Grid>
        <Grid item xs={6}>
          <Stack direction='column' py={1} alignItems='flex-end' mr={{xs: 1, sm: 1, md:4}}>
            <Box display='flex' flexDirection='row'>
              <Typography mr={1}>Total:</Typography>
              <Typography>${total_price}</Typography>
            </Box>
            <Box display='flex' flexDirection='row'>
              <Typography mr={1}>Status:</Typography>
              <Typography color='success'>{order_status}</Typography>
            </Box>
          </Stack>
        </Grid>
      </Stack>
      <Stack direction='column' bgcolor={alphaBackgroundColor70}>
        {item_obj.map((item, idx) => {
          switch(order_type) {
            case CONTENT:
              return <><ContentCard item={item} idx={idx} />{ item_obj.length - 1 > idx ? <Divider flexItem sx={{ mx:4 }} /> : null}</>
            case MERCHANDISE:
              return <><MerchandiseCard item={item} idx={idx} />{ item_obj.length - 1 > idx ? <Divider flexItem sx={{ mx:4 }} /> : null}</>
            case GIFT_CODE:
              return <><CodeCard item={item} idx={idx}/>{ item_obj.length - 1 > idx ? <Divider flexItem sx={{ mx:4 }} /> : null}</>
          }
          // 
        })}
      </Stack>
      <Divider flexItem sx={{ borderBottomWidth: 'medium', my: .5, mx:4 }} />
    </>
  );
}

const TabPanel = (props) => {
  const { children, value, index, ...other } = props;

  return (
    <div role="tabpanel" hidden={value !== index} {...other}>
      {value === index && <Box>{children}</Box>}
    </div>
  );
};

// NOT USED
const CodeHistory = () => {
  const redeemable_codes =  useSelector(state => state.shop.redeemable_codes);

  return (
    <>
      {redeemable_codes.length > 0 ?<Stack direction='column' bgcolor={alphaBackgroundColor70}>
        {redeemable_codes.map((item, idx) => {
          return (
            <>
            <Stack direction='column' mb={2}>
              <Stack direction='row'>
              <Grid item xs={12}>
                <Stack direction='column' py={1} mr={{xs: 1, sm: 1, md:4}} alignItems='flex-end'>
                  <Typography variant="subtitle1">February 22, 2023</Typography>
                </Stack>
              </Grid>
              </Stack>
              <Grid item xs={12} px={{xs: 1, sm: 1, md:4}}>
                <Typography>{`Code:`}</Typography>
                <Typography variant="subtitle1" noWrap>{`${item.code}`}</Typography>
              </Grid>
            </Stack>
            <Box key={idx}>
              <Grid container maxHeight='300px'>
                <Grid item xs={6} sm={6} md={3}>
                  <CardMedia component="img" height='300px' image={item.item_url} alt='code' />
                </Grid>
                <Grid item xs={6} sm={6} md={9} display='flex'>
                  <Stack direction='column' spacing={1} px={2}>
                  <Typography variant="body1" mb={2}>{item.title}</Typography>
                    <Typography variant="subtitle1" mt={1}>{`Status: ${item.status} `}</Typography>
                    <Typography variant="subtitle1">{`Price: $${item.price} `}</Typography>
                  </Stack>
                </Grid>
              </Grid>
            </Box>
            { redeemable_codes.length - 1 > idx ? <Divider flexItem sx={{ mx:4, my: 2 }} /> : null}
            </>
          );
        })}
      </Stack> :
      <EmptyHistory text="No redeemable codes" />
      }
    </>
  );
}

const MerchandiseCard = ({ item, idx }) => {
  const { name, item_url, price, quantity, filters } = item;
  const total = (parseFloat(price) * parseFloat(quantity)).toFixed(2)
  return (
    <Box key={idx}>
      <Grid container minHeight='256px'>
        <Grid item xs={6} sm={6} md={4}>
          <CardMedia component="img" height='256px' image={item_url} alt={name} />
        </Grid>
        <Grid item xs={6} sm={6} md={8}>
          <Stack direction='column' spacing={1} px={2}>
            <Typography variant="body1" mb={2}>{name}</Typography>
            <Typography variant="subtitle1">{`Quantity: ${quantity}`}</Typography>
            <Typography variant="subtitle1">{`Price: $${total} `}</Typography>
            {_.pairs(filters).map((i, idx) => <Typography key={idx} variant="subtitle1">{`${i[0]}: ${i[1]} `}</Typography>)}
          </Stack>
        </Grid>
      </Grid>
    </Box>
  );
}

const ContentCard = ({ item, idx }) => {
  const { title, item_url, price } = item;
  return (
    <Box key={idx}>
      <Grid container minHeight='256px'>
        <Grid item xs={6} sm={6} md={4}>
          <CardMedia component="img" height='256px' image={item_url} alt={title} />
        </Grid>
        <Grid item xs={6} sm={6} md={8}>
          <Stack direction='column' spacing={1} px={2}>
            <Typography variant="body1" mb={2}>{title}</Typography>
            <Typography variant="subtitle1">{`Price: $${price} `}</Typography>
          </Stack>
        </Grid>
      </Grid>
    </Box>
  );
}

const CodeCard = ({ item, idx }) => {
  const { title, item_url, codes } = item;
  const code_history =  useSelector(state => state.shop.code_history);
  const codeLength = codes.length;
  return (
    <Box key={idx}>
      <Stack alignItems='center'>
        <Typography variant="body1" mb={2}>{title}</Typography>
        <CardMedia component="img" height='300px' image={item_url} alt='code' />
        <Typography variant="body1">{codes.length} {codeLength === 1 ? "Code:" : "Codes:"}</Typography>
      </Stack>
      <Box m={2} >
        <Box display="flex" flexDirection='row' flexWrap='wrap' gap='.5rem .75rem' alignItems='center' justifyContent='center'>
          {codes.map(code => {
            let isDisabled = false;
            const found = _.find(code_history, item => item.code == code)
            if (found) {
              isDisabled = found.is_disabled;
            }
            return (<Box m={1} sx={{border: isDisabled ? `2px solid red`: `2px solid white`, borderRadius: 2, p:1}}><Typography variant="subtitle1"  color={isDisabled ? 'error.light' :'white'}  sx={{fontFamily: 'monospace', fontWeight: 300, textAlign:'center'}}>{`${code}`}</Typography></Box>)
          })}
        </Box>
      </Box>
    </Box>
  );
}